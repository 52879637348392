import React from "react"
import { Slot } from "@radix-ui/react-slot"
import { DateTime } from "luxon"
import { cn } from "~/components/ui/utils"
import {
  filterEventsForHour,
  slotHasEvents,
  type ProcessedEvent,
} from "~/components/weekly-calendar/events"
import { renderHour12Style } from "~/components/weekly-calendar/utils"
import { useLocalIntl } from "~/hooks/useLocalIntl"
import { useWeeklyCalendar } from "./context"
import { useWeeklyRoot } from "./WeeklyRoot"

export type SlotRenderProps = {
  hasEvents: boolean
  events: ProcessedEvent[]
  slotDate: DateTime
  isToday: boolean
  isWeekend: boolean
  isSameHour: boolean
  isWorkingHour: boolean
  className?: string
}

export type WeeklySlotsProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  children?: (props: SlotRenderProps) => React.ReactNode
}

const HOURS = Array.from({ length: 24 }, (_, i) => i)

export function WeeklySlots({ children, ...props }: WeeklySlotsProps) {
  const { events, daysInWeek, currentWeek } = useWeeklyCalendar()

  const { uses12Hour } = useLocalIntl()
  const { scrollContainerRef } = useWeeklyRoot()

  // Indicate the current time
  const [now, setNow] = React.useState(DateTime.local())

  React.useEffect(() => {
    const interval = setInterval(() => {
      const _now = DateTime.local()
      if (_now.hasSame(now, "minute")) return

      // Keep now up-to-date every minute
      setNow(_now)
    }, 10_000)

    return () => clearInterval(interval)
  }, [now, currentWeek.weekNumber])

  return (
    <>
      <div
        ref={scrollContainerRef}
        className="relative flex-1 w-full overflow-y-auto"
      >
        <div
          {...props}
          className={cn("wlc-header grid shrink-0", props.className)}
          style={{
            height: "calc(var(--wlc-slot-height) * 24)",
            gridTemplateRows: `repeat(24, var(--wlc-slot-height))`,
            gridTemplateColumns: `40px repeat(5, minmax(var(--wlc-slot-width), 1fr)) minmax(var(--wlc-slot-weekend_width), 0.6fr) minmax(var(--wlc-slot-weekend_width), 0.6fr)`,
          }}
        >
          {HOURS.map(function Comp(hour) {
            return (
              <div
                key={hour}
                className="relative h-[var(--wlc-slot-height)] flex items-start justify-start px-1"
                style={{
                  gridArea: `${hour + 1} / 1 / span 1 / span 1`,
                }}
              >
                <span
                  className={cn(
                    "block w-full py-0.5 px-0.5 leading-[1] text-sm",
                    hour === 0 && "hidden"
                  )}
                >
                  {uses12Hour ? (
                    <span className="text-xs lowercase">
                      {renderHour12Style(hour)}
                    </span>
                  ) : (
                    <>
                      <span className="">
                        {hour.toString().padStart(2, "0")}
                      </span>
                      <sup className="text-[10px] top-[-0.25em] text-muted-foreground">
                        00
                      </sup>
                    </>
                  )}
                </span>
              </div>
            )
          })}

          {daysInWeek.map(function Comp(day) {
            const isToday = day.hasSame(now, "day")
            const isWeekend = day.weekday > 5

            const dayEvents = events.get(day.toISODate()!) ?? []

            return (
              <React.Fragment key={day.toISODate()}>
                {HOURS.map(function Comp(hour) {
                  const slotDate = day.set({ hour, minute: 0 })

                  const isWorkingHour = hour >= 8 && hour < 18 && !isWeekend
                  const isSameHour = slotDate.hasSame(now, "hour")

                  const events = React.useMemo(
                    () => filterEventsForHour(dayEvents, slotDate),
                    [slotDate]
                  )

                  const hasEvents = React.useMemo(
                    () => slotHasEvents(slotDate, dayEvents),
                    [slotDate]
                  )

                  const Comp = children ? Slot : "div"

                  return (
                    <Comp
                      key={slotDate?.toISO()}
                      data-slot-data={slotDate?.toISODate()}
                      data-slot-time={slotDate?.toFormat("HH:mm")}
                      style={{
                        gridArea: `${hour + 1} / ${day.weekday + 1} / span 1 / span 1`,
                      }}
                    >
                      {children
                        ? children({
                            slotDate,
                            isToday,
                            isWeekend,
                            isSameHour,
                            isWorkingHour,
                            hasEvents,
                            events: events,
                            className: "relative h-[var(--wlc-slot-height)]",
                          })
                        : null}
                    </Comp>
                  )
                })}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}
