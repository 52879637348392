import { type Conversation } from "~/hooks/firestore/useConversations"

export interface GroupedConversationItem {
  title: string
  id: string
  isActive?: boolean
}

export interface GroupedConversation {
  title: string
  items: GroupedConversationItem[]
}

// A utility function to group and sort conversations by assistantId
export function groupConversationsByAssistant(
  conversations: Conversation[],
  activeConversationId: string | null
): GroupedConversation[] {
  const sortedConversations = [...conversations].sort(
    (a, b) => (b.updatedAt?.toMillis() ?? 0) - (a.updatedAt?.toMillis() ?? 0)
  )

  return sortedConversations.reduce<GroupedConversation[]>(
    (acc, conversation) => {
      const assistantId = conversation.assistantId
      const existingGroup = acc.find((group) => group.title === assistantId)
      const newItem: GroupedConversationItem = {
        title: conversation.title || "Untitled",
        id: conversation.id,
        isActive: conversation.id === activeConversationId,
      }

      if (existingGroup) {
        // Add to existing group
        existingGroup.items.push(newItem)
      } else {
        // Create a new group
        acc.push({
          title: assistantId,
          items: [newItem],
        })
      }

      return acc
    },
    []
  )
}
