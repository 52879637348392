import { useEffect, useMemo, useRef } from "react"
import * as Sentry from "@sentry/browser"
import { useQueries } from "@tanstack/react-query"
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { DateTime } from "luxon"
import { useAuth } from "~/context/AuthContext"
import {
  toWeekString,
  useSessions,
  useUpdateSession,
  type Session,
} from "~/hooks/firestore/useSessions"
import { Note, NoteStatus } from "~/pages/Notes/types"
import { db, functions } from "~/services/firebase"

type PreReadResponse = {
  status: string
  text: string
  metadata: unknown
}

const formatNote = (note: Note): string => {
  const transcription =
    note.status === NoteStatus.Edited
      ? note.editedTranscription
      : note.transcription

  const formattedDate = DateTime.fromMillis(
    note.createdAt.toMillis()
  ).toLocaleString(DateTime.DATETIME_MED)

  return `${note.title} \n${formattedDate} \n${transcription}`
}

// Utility function to fetch notes for a client
const fetchLastNotesByClientId = async ({
  clientId,
  numberOfNotes = 10,
  currentUserUid,
}: {
  clientId: string
  numberOfNotes?: number
  currentUserUid: string | undefined
}) => {
  if (!clientId) {
    return []
  }
  const notesRef = collection(db, `users/${currentUserUid}/notes`)
  const noteSnapshot = await getDocs(
    query(
      notesRef,
      where("clientId", "==", clientId),
      orderBy("createdAt", "desc"),
      limit(numberOfNotes)
    )
  )

  if (noteSnapshot.empty) {
    return []
  }

  return noteSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as Note[]
}

const createPreReadAsync = async (
  session: Session,
  notesData: Note[]
): Promise<string> => {
  if (!notesData || notesData.length === 0) {
    return "Looks like there are no previous notes for this client."
  }

  try {
    // Concatenate notes for the client
    const sessionHeader = `Client: ${session.clientName} 
      ${session.title ? "Upcoming session title: " + session.title : ""}
      ${session.description ? "Upcoming session description: " + session.description : ""}`

    const latestNotes = notesData.reduce((acc, note, index) => {
      const formattedNote = formatNote(note)
      return acc + (index > 0 ? " \n ========== \n " : "") + formattedNote
    }, "")

    const llmText = `${sessionHeader}  
      Notes from previous sessions: 
      ==========
      ${latestNotes}`

    const llmInstruction = `
    Write a very short (about 4 to 5 sentences or maximum 80 words) informative text for a therapist to read before an upcoming client session.
    Do NOT include time or date for the upcoming session, it is presented outside of this pre-read.
    Do NOT add a greeting or any personalization.
    Do NOT add an initial header or title, start directly with the content.
    Use the information provided in the list of notes, each separated by ==========.
    Give most attention to the most recent note that is provided first in the list.
    If homework is mentioned in the most recent note, include it under header "Homework".
    Avoid speculative content, do not suggest actions not in the original notes, and do not ask follow-up questions.
    Reply using markdown format, divide into paragraphs.
    Use short and concise sentences, or keywords where applicable. The reader is the therapist or psychiatrist who will have the session with the client.
    Use the same language as in the notes, and keep the tone neutral and professional.
  `

    const llmQuery = httpsCallable(functions, "llmDirectQuery")

    const preRead = await llmQuery({
      instruction: llmInstruction,
      text: llmText,
    })
    const preReadData = preRead.data as PreReadResponse

    if (preReadData.status === "ok") {
      return preReadData.text
    }
  } catch (error) {
    Sentry.captureException(error)
  }
  return "Could not create the pre-read"
}

export const usePreReadCreator = () => {
  const { currentUser } = useAuth()

  const sessionsQuery = useSessions({
    weekString: toWeekString(DateTime.local()),
  })
  const sessions = useMemo(() => sessionsQuery.data || [], [sessionsQuery.data])

  const updateSession = useUpdateSession({
    onSettled: (_, error) => {
      if (error) {
        console.error("Unable to update the session", error)
      }
    },
  })

  // Filter sessions for today
  const sessionsToday = useMemo(() => {
    if (!sessions) return []
    const today = DateTime.now().startOf("day")
    const eod = today.endOf("day")
    return sessions
      .filter((session) => session.start > today && session.start < eod)
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessions])

  // Filter remaining sessions for today
  const remainingSessions = useMemo(() => {
    const now = DateTime.now()
    return sessionsToday.filter((session) => session.start > now)
  }, [sessionsToday])

  // Fetch notes for all clientIds
  const clientIds = useMemo(
    () => Array.from(new Set(remainingSessions.map((s) => s.clientId))),
    [remainingSessions]
  )

  const clientNotesQueries = useQueries({
    queries: clientIds.map((clientId) => ({
      queryKey: ["LAST_NOTES", clientId],
      queryFn: () =>
        fetchLastNotesByClientId({
          clientId: clientId!,
          currentUserUid: currentUser?.uid,
        }),
      enabled: !!clientId && !!currentUser,
    })),
  })

  const isUpdatingRef = useRef(false)
  const preReadCreationInProgress = useRef(new Set<string>())

  useEffect(() => {
    const updateSessions = async () => {
      if (isUpdatingRef.current || !currentUser) return
      isUpdatingRef.current = true

      // Create a map of notes per client
      const notesByClient = Object.fromEntries(
        clientIds.map((id, idx) => [id, clientNotesQueries[idx].data ?? []])
      )

      const shouldCreatePreRead = (
        session: Session,
        preReadCreationInProgress: Set<string>
      ): boolean => {
        const hasProposal = !!session.proposalId
        const isAccepted = !!session.acceptedAt
        const proposalCheck = !hasProposal || (hasProposal && isAccepted)

        return (
          !!session.clientId &&
          !session.preRead &&
          proposalCheck &&
          !preReadCreationInProgress.has(session.id)
        )
      }

      const sessionsToProcess = remainingSessions.filter((session) =>
        shouldCreatePreRead(session, preReadCreationInProgress.current)
      )

      // Create a pre-read for each remaining session today (if not already created)
      for (const session of sessionsToProcess) {
        try {
          preReadCreationInProgress.current.add(session.id)
          const notesData = session.clientId
            ? (notesByClient[session.clientId] ?? [])
            : []

          const preRead = await createPreReadAsync(session, notesData)

          const updatedSession = { ...session, preRead }
          await updateSession.mutateAsync({
            sessionId: session.id,
            session: updatedSession,
          })
        } catch (error) {
          Sentry.captureException(error)
        }
      }
      isUpdatingRef.current = false
    }

    if (
      remainingSessions.length > 0 &&
      clientNotesQueries.every((q) => q.isSuccess) &&
      currentUser &&
      currentUser.uid &&
      clientIds.length > 0
    ) {
      void updateSessions()
    } else {
      clientNotesQueries.forEach((q) => {
        if (q.isError) {
          Sentry.captureException(q.error)
        }
      })
    }
  }, [
    sessions,
    currentUser,
    clientIds,
    remainingSessions,
    clientNotesQueries,
    updateSession,
  ])
}
