import React, { useState } from "react"
import { CopyIcon, Trash2Icon } from "lucide-react"
import { DateTime } from "luxon"
import type { ToRelativeOptions } from "luxon/src/datetime"
import { Button, ConfirmationModal, toast } from "~/components/ui"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip"
import {
  useWeeklyCalendar,
  WeeklyFooter,
  type ProcessedEvent,
} from "~/components/weekly-calendar"
import { useClientById } from "~/hooks/firestore/useClients"
import {
  useDeleteProposal,
  useProposalById,
} from "~/hooks/firestore/useProposals"
import { toDatetimeString } from "~/utils/time"
import { useCalendarStore } from "./store"
import { shareUrlFromProposalId } from "./utils"

function humanReadableTimeLeft(expiresAt: Date | null) {
  if (!expiresAt || !(expiresAt instanceof Date)) {
    return "Expired"
  }

  const date = DateTime.fromJSDate(expiresAt)
  if (date.diffNow("second").seconds < 0) {
    return "Expired"
  }

  if (date.diffNow("hours").hours < 48) {
    const unit: ToRelativeOptions["unit"] =
      date.diffNow("hours").hours < 1 ? ["minutes", "seconds"] : ["hours"]

    return `Expires ${date.toRelative({ unit, style: "narrow" })}`
  }

  return `Expires ${date.toRelative()}`
}

// filters events with same proposalId from event map
function filterEventsByProposalId(
  events: Map<string, ProcessedEvent[]>,
  proposalId: string
) {
  return Array.from(events.values())
    .flat()
    .filter((event) => {
      return event.raw.proposalId === proposalId
    })
}

const ShareLink = ({ shareUrl }: { shareUrl: string }) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    void navigator.clipboard.writeText(shareUrl).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }

  return (
    <div className="flex items-center gap-2">
      <strong>Link:</strong>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            onClick={handleCopy}
            className="p-0 h-auto hover:underline hover:bg-transparent hover:outline-none items-center gap-1"
            aria-label={copied ? "Link copied" : "Copy link to clipboard"}
          >
            {copied ? "Copied!" : shareUrl}
            {!copied && <CopyIcon className="w-4 h-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{copied ? "Copied!" : "Click to copy"}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

export function ProposalDetail() {
  const { events } = useWeeklyCalendar()

  const [shareUrl, setShareUrl] = React.useState("")

  const { selectedProposal, setSelectedProposal } = useCalendarStore(
    (state) => ({
      selectedProposal: state.selectedProposal,
      setSelectedProposal: state.setSelectedProposal,
    })
  )

  const deleteProposal = useDeleteProposal({
    onError: () => {
      toast.error("Failed to delete proposal")
    },
  })

  const clientId = selectedProposal?.raw?.clientId ?? ""
  const proposalId = selectedProposal?.raw?.proposalId ?? ""

  React.useEffect(() => {
    if (proposalId) {
      setShareUrl(shareUrlFromProposalId(proposalId))
    }
  }, [proposalId])

  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: !!clientId,
    },
  })

  const proposalEvents = React.useMemo(
    () => filterEventsByProposalId(events, proposalId),
    [events, proposalId]
  )

  const slots = proposalEvents.map((event) => event.start)
  const clientName = (client.data as { name?: string })?.name || "Client"

  const proposalQuery = useProposalById({ proposalId })

  if (!selectedProposal) {
    return null
  }

  const expiresAt = proposalQuery.data?.expiresAt
    ? proposalQuery.data.expiresAt.toDate()
    : null

  return (
    <WeeklyFooter
      title="Proposal details"
      open={!!selectedProposal}
      onOpenChange={(open) => {
        if (!open) {
          setSelectedProposal(null)
        }
      }}
      header={
        <div className="flex flex-row items-center justify-between gap-4 px-4 md:pl-0 md:pr-6 mb-6">
          <h2 className="text-lg font-bold leading-none tracking-tight font-platypi md:text-2xl">
            Proposal for {clientName}
          </h2>

          <div className="flex items-center gap-0">
            <ConfirmationModal
              title="Delete proposal?"
              description=""
              confirmButton="Delete"
              closeButton="Cancel"
              onConfirm={() => {
                deleteProposal.mutate({ proposalId })
              }}
            >
              <Button
                variant={"ghost"}
                disabled={deleteProposal.isPending}
                className="text-xs sm:text-sm text-red-500 hover:text-red-500"
              >
                <Trash2Icon className="size-4 text-red-500" />
                Delete
              </Button>
            </ConfirmationModal>
          </div>
        </div>
      }
    >
      <div className="space-y-2 md:space-y-4">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <strong>Status:</strong>
            <span>
              {expiresAt ? ` ${humanReadableTimeLeft(expiresAt)}` : ""}
            </span>
          </div>
          <ShareLink shareUrl={shareUrl} />
        </div>

        <ul className="grid grid-cols-1 gap-2">
          {slots.map((slotDate, i) => (
            <li
              key={i}
              className="flex items-center justify-between gap-4"
            >
              <Button
                variant="secondary"
                className="rounded-3xl flex-1"
              >
                {toDatetimeString(slotDate)}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </WeeklyFooter>
  )
}
