import * as React from "react"
import { useDeepCompareEffect } from "ahooks"
import { DateTime } from "luxon"
import { Link, useNavigate, useParams, useSearchParams } from "react-router"
import ClientDetailsModal from "~/components/ClientDetailsModal"
import ClientModal from "~/components/ClientModal"
import EditClientModal from "~/components/EditClientModal"
import { ScrollArea, toast } from "~/components/ui"
import { ConfirmationModal } from "~/components/ui/confirmation-modal"
import { useAuth } from "~/context/AuthContext"
import { useClientAttachments } from "~/hooks/firestore/useClientAttachments"
import { useDeleteClient } from "~/hooks/firestore/useClients"
import { useUpcomingSessions } from "~/hooks/firestore/useSessions"
import {
  useGetNoteById,
  useMarkNoteAsViewed,
  useSubscriptionNotesByClient,
  useUpdateNotesViewedWithUndo,
} from "~/hooks/useNotes"
import { AttachmentUpload } from "~/pages/Notes/AttachmentUpload"
import { ClientAttachments } from "~/pages/Notes/ClientAttachments"
import { clientFields } from "~/utils/noteUtils"
import { PROMPTS } from "~/utils/prompts"
import NoteHeader from "./NoteHeader"
import { NoteItem } from "./NoteItem"
import { Note, type Field } from "./types"

const formatDateString = (date: DateTime) => {
  // if today, show: Today at 10:00 AM
  if (date.hasSame(DateTime.local(), "day")) {
    return `Today at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
  }

  return date.toFormat("cccc, LLL yyyy")
}

export default function NoteDetails() {
  const { noteId } = useParams()
  const navigate = useNavigate()

  const { currentUser } = useAuth()
  const markNoteAsViewed = useMarkNoteAsViewed()

  const noteInfo = useGetNoteById({ noteId: noteId! })
  const noteData = noteInfo.data
  const noteHasClient = Boolean(noteData?.clientId ?? false)

  // Redirect to notes page if note does not exist
  useDeepCompareEffect(() => {
    if (noteInfo.error) {
      void navigate("/notes")
    }
  }, [noteInfo.error])

  // Side effect to mark note as viewed
  useDeepCompareEffect(() => {
    if (noteData && !noteData.viewed) {
      markNoteAsViewed.push({ noteId: noteData.id }).catch(console.error)
    }
  }, [noteData?.id])

  const clientNotes = useSubscriptionNotesByClient({
    clientId: noteData?.clientId ?? "",
    onSnapshot: (snapshot) => {
      const _notes = snapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as Note
      )

      for (const note of _notes) {
        if (!note.viewed) {
          // TODO : Verify handling of this promise
          markNoteAsViewed.push({ noteId: note.id }).catch(console.error)
        }
      }
    },
  })

  const notes = React.useMemo(() => {
    if (!noteInfo.data) return []

    // If note has no client, return only the note
    if (!noteHasClient) {
      return [noteInfo.data]
    }

    const _noteId = noteInfo.data?.id

    return [...(clientNotes.data ?? [])]
      .filter((note) => !note.deletedAt || !note.viewedWithUndo)
      .sort((a, b) => {
        if (a.id === _noteId) return -1
        if (b.id === _noteId) return 1
        return 0
      })
  }, [noteHasClient, noteInfo.data, clientNotes.data])

  const updateNotesViewedWithUndo = useUpdateNotesViewedWithUndo()
  React.useEffect(() => {
    if (!currentUser?.uid) return
    updateNotesViewedWithUndo.mutate({ currentUserId: currentUser.uid })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid])

  // Mutations
  const deleteClient = useDeleteClient({
    onSettled: (_, err) => {
      if (!err) {
        toast.info("Client deleted successfully")
        void navigate("/notes")
      } else {
        toast.error("Error deleting client")
      }
    },
  })

  // @todo: Move this into ClientModal in next refactor.
  const [showClientModal, setShowClientModal] = React.useState(false)
  const [showDeleteClientModal, setDeleteClientModal] = React.useState(false)
  const [showEditClientModal, setShowEditClientModal] = React.useState(false)
  const [showClientDetailsModal, setShowClientDetailsModal] =
    React.useState(false)

  const [availableFields, setAvailableFields] = React.useState<Field[]>(
    () => clientFields
  )

  const isAllDeleted = React.useMemo(
    () => notes.length > 0 && notes.every((item) => !!item.deletedAt),
    [notes]
  )

  React.useEffect(() => {
    if (isAllDeleted && noteData?.clientId) {
      setDeleteClientModal(true)
    }
  }, [isAllDeleted, noteData?.clientId])

  const onClientRemoved = () => {
    if (notes.length === 1) {
      setDeleteClientModal(true)
    }
  }

  const [searchParams] = useSearchParams()
  const isOpenEdit = searchParams.get("edit") === "1"

  // Attachments
  const attachments = useClientAttachments({
    clientId: noteData?.clientId || "",
    reactQuery: {
      enabled: !!noteData?.clientId,
    },
  })

  // Sessions
  const sessions = useUpcomingSessions({
    clientId: noteData?.clientId,
    limit: 1,
  })

  const upcomingSessions = React.useMemo(() => {
    if (!sessions.data) return null
    const now = DateTime.now()

    return sessions.data
      .filter((session) => session.start >= now)
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessions.data])

  const nextSession = upcomingSessions?.[0] ?? null

  return (
    <>
      <div className="pb-20 md:pb-0 bg-white h-full flex flex-col justify-between">
        <NoteHeader
          noteData={noteData!}
          setOpenClientDetailsModal={setShowClientDetailsModal}
          setOpenModal={setShowClientModal}
          attachmentCount={
            noteData?.clientId ? (attachments.data?.length ?? 0) : undefined
          }
          onToggleAttachmentPanel={() => {
            if (document.getElementById("attachments")) {
              document.getElementById("attachments")?.scrollIntoView({
                behavior: "smooth",
              })
            }
          }}
        />

        <ScrollArea className="relative z-10 flex-1 overflow-auto bg-primary-cream-300 pt-0 px-4 md:px-6">
          <h2 className="text-lg font-medium opacity-30 mt-4 ml-2 sm:ml-0 sm:text-center">
            {nextSession ? (
              <>
                <span className="text-base">Next session</span>{" "}
                <strong>{formatDateString(nextSession.start)}</strong>
              </>
            ) : null}

            {!nextSession && noteData?.clientId ? (
              <Link to="/calendar">No sessions booked</Link>
            ) : null}
          </h2>

          {notes.map((note, i) => (
            <NoteItem
              key={note.id}
              note={note}
              initialExpanded={i === 0 && !note?.markedAsDoneAt}
              initialOpenEdit={i === 0 && isOpenEdit && !note?.markedAsDoneAt}
              onClientRemoved={onClientRemoved}
            />
          ))}

          {noteData?.clientId ? (
            <footer
              id="attachments"
              className="py-4 space-y-4"
            >
              <ClientAttachments
                clientId={noteData.clientId}
                attachments={attachments.data ?? []}
              />
              <AttachmentUpload clientId={noteData.clientId} />
            </footer>
          ) : null}
        </ScrollArea>
      </div>

      <ConfirmationModal
        isOpen={showDeleteClientModal}
        onOpenChange={setDeleteClientModal}
        title="Delete Client?"
        description={PROMPTS.REMOVE_CLIENT}
        closeButton="No"
        confirmButton="Yes"
        onConfirm={() => {
          deleteClient.mutate({ clientId: noteData!.clientId, mode: "delete" })
        }}
      />

      <ClientModal
        noteId={noteData?.id || ""}
        isOpen={showClientModal}
        onOpenChange={setShowClientModal}
        refetch={() => {
          void noteInfo.refetch()
        }}
      />

      {noteData && (
        <>
          <ClientDetailsModal
            isOpen={showClientDetailsModal}
            setOpenClientDetailsModal={setShowClientDetailsModal}
            clientId={noteData?.clientId || ""}
            editButtonHandler={() => {
              setShowEditClientModal(true)
            }}
          />

          {noteData.clientId && noteId ? (
            <EditClientModal
              isOpen={showEditClientModal}
              setOpenModal={setShowEditClientModal}
              clientId={noteData.clientId}
              onSuccess={async () => {
                await noteInfo.refetch()
              }}
              addedFields={[]}
              setAvailableFields={setAvailableFields}
              availableFields={availableFields}
              onCloseAllModals={() => {
                setShowEditClientModal(false)
              }}
            />
          ) : null}
        </>
      )}
    </>
  )
}
