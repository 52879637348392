import React from "react"
import { Timestamp } from "@firebase/firestore"
import { Repeat2, Trash2Icon, TriangleAlert } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { useFormContext } from "react-hook-form"
import { Link, useNavigate } from "react-router"
import { CheckCircle } from "~/assets/icons"
import { Button, ConfirmationModal, toast } from "~/components/ui"
import { cn } from "~/components/ui/utils"
import { parseDate, WeeklyFooter } from "~/components/weekly-calendar"
import {
  useClientById,
  useLastNoteByClientId,
} from "~/hooks/firestore/useClients"
import {
  useDeleteSession,
  useSessionsByRepeatId,
  useUpdateSession,
  type Session,
} from "~/hooks/firestore/useSessions"
import { useNotesBySessionId } from "~/hooks/useNotes"
import { Note } from "~/pages/Notes/types"
import { BookingControls } from "./BookingControls"
import DeleteRepeatingSessionDialog from "./DeleteRepeatingSessionDialog"
import { useCalendarStore } from "./store"
import {
  INITIAL_VALUES,
  useCalendarFormValues,
  type FromSchemaValues,
} from "./utils"

interface ClientData {
  id?: string
  name?: string
  phone?: string
  email?: string

  [key: string]: string | undefined
}

export function BookingDetail() {
  const navigate = useNavigate()

  const { reset } = useFormContext<FromSchemaValues>()

  const { selectedEvent, setSelectedEvent, mode, setMode } = useCalendarStore(
    (state) => ({
      selectedEvent: state.selectedEvent,
      setSelectedEvent: state.setSelectedEvent,
      mode: state.selectedEventMode,
      setMode: state.setSelectedEventMode,
    })
  )

  const currentSession = selectedEvent?.raw ?? null
  const clientId = currentSession?.clientId || ""

  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: !!clientId,
    },
  })

  const lastNote = useLastNoteByClientId({
    clientId,
    reactQuery: {
      enabled: !!clientId,
    },
  })

  const sessionNotes = useNotesBySessionId({
    sessionId: currentSession?.id || "",
    reactQuery: {
      enabled: !!currentSession?.id,
    },
  })

  const repeatingSessions = useSessionsByRepeatId({
    repeatId: currentSession?.repeatId || "",
    reactQuery: {
      enabled: !!currentSession?.repeatId,
    },
  })

  const onClose = () => {
    reset({ ...INITIAL_VALUES })
    setSelectedEvent(null)
  }

  const updateSession = useUpdateSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Unable to update the calendar event")
      } else {
        onClose()
      }
    },
  })

  const deleteSession = useDeleteSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Unable to delete calendar event")
      } else {
        onClose()
      }
    },
  })

  const deleteSingleSession = async (sessionId: string) => {
    await deleteSession.mutateAsync({ sessionId })
  }
  const deleteFollowingSessions = async (sessionId: string) => {
    if (!currentSession) return

    // Delete all sessions later than the current one with the same repeat Id
    const sessions = (repeatingSessions.data ?? {}) as Session[]
    const sessionsToDelete = sessions.filter((session) => {
      return session.start > currentSession?.start
    })

    if (sessionsToDelete.length > 0) {
      await Promise.all(
        sessionsToDelete.map((session) =>
          deleteSession.mutateAsync({ sessionId: session.id })
        )
      )
    }

    // Delete the current session
    await deleteSession.mutateAsync({ sessionId })
  }
  const [
    showDeleteRepeatingSessionsDialog,
    setShowDeleteRepeatingSessionsDialog,
  ] = React.useState(false)

  // Reset "mode" when selection change
  React.useEffect(() => setMode("view"), [selectedEvent?.id, setMode])

  const formValues = useCalendarFormValues({
    slotDate: currentSession?.start ?? DateTime.now(),
  })

  if (!selectedEvent) {
    return null
  }

  const clientData = (client.data ?? {}) as ClientData
  const lastNoteData = (lastNote.data ?? {}) as Note
  const sessionNotesData = (sessionNotes.data ?? {}) as Note[]

  let start: DateTime
  let duration: Duration

  if (mode === "edit") {
    start = formValues.date!
    duration = Duration.fromObject({ minutes: formValues.duration ?? 60 })
  } else {
    start = currentSession?.start ?? DateTime.now()
    duration = currentSession?.duration ?? Duration.fromObject({ minutes: 60 })
  }

  const end = start.plus(duration)

  const navigateToRecorder = () => {
    const sessionStart = currentSession?.start.toJSDate()

    const state = {
      clientId,
      clientName: clientData.name,
      title: currentSession?.title,
      sessionId: currentSession?.id,
      sessionStart: sessionStart ? Timestamp.fromDate(sessionStart) : undefined,
    }
    setSelectedEvent(null)
    void navigate("/recorder", { state })
  }

  const handleEditClick = () => {
    const _start = currentSession?.start ?? DateTime.now()
    const _duration =
      currentSession?.duration ?? Duration.fromObject({ minutes: 60 })

    // Switch to edit mode
    setMode("edit")

    // Reset form values and state
    reset({
      date: _start.toISO(),
      start: _start.minute, // @todo: this should change to "minute" in the future
      duration: _duration.as("minutes"),
      title: currentSession?.title ?? "",
      description: currentSession?.description ?? "",
      clientId: currentSession?.clientId ?? "",
      clientName: currentSession?.clientName ?? "",
      personal: currentSession?.personal ?? false,
      slots: [],
    })
  }

  const handleSubmit = async (values: FromSchemaValues) => {
    if (!currentSession) return

    const start = parseDate(values.date ?? currentSession.start)!

    await updateSession.mutateAsync({
      sessionId: currentSession.id,
      session: {
        ...currentSession,
        start: start.set({ minute: values.start || 0 }),
        duration: Duration.fromObject({ minutes: values.duration || 60 }),
        title: values.title || "",
        description: values.description || "",
        clientId: values.clientId || undefined,
        clientName: values.clientName || undefined,
        personal: values.personal,
      },
      deleteEmptyClientFields: true,
    })
  }

  return (
    <WeeklyFooter
      open={!!selectedEvent}
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
      title="Event details"
      header={
        <div className="flex flex-row items-center justify-between gap-4 px-4 md:pl-0 md:pr-6 mb-6">
          <h2 className="text-lg font-bold leading-none tracking-tight font-platypi md:text-2xl md:mt-4">
            {currentSession?.title && currentSession.title}
            {!currentSession?.title && clientData.name && clientData.name}
            {!currentSession?.title && !clientData.name && "(No title)"}
          </h2>

          <div className="flex items-center gap-0 md:mt-4">
            <Button
              variant={"ghost"}
              className={cn(
                "text-xs sm:text-sm text-red-500 hover:text-red-500",
                mode === "edit" && "hidden"
              )}
              onClick={() => handleEditClick()}
            >
              Edit
            </Button>

            {currentSession?.repeatId ? (
              <>
                <DeleteRepeatingSessionDialog
                  isOpen={showDeleteRepeatingSessionsDialog}
                  onOpenChange={setShowDeleteRepeatingSessionsDialog}
                  onDeleteConfirm={async (option) => {
                    if (option === "current") {
                      await deleteSingleSession(currentSession.id)
                    } else {
                      await deleteFollowingSessions(currentSession.id)
                    }
                  }}
                  sessionType={currentSession.personal ? "event" : "session"}
                />
                <Button
                  variant={"ghost"}
                  className={cn(
                    "text-xs sm:text-sm text-red-500 hover:text-red-500",
                    mode === "edit" && "hidden"
                  )}
                  onClick={() => {
                    setShowDeleteRepeatingSessionsDialog(true)
                  }}
                >
                  <Trash2Icon className="size-4 text-red-500" />
                  Delete
                </Button>
              </>
            ) : (
              <ConfirmationModal
                title={
                  currentSession?.personal
                    ? "Delete event?"
                    : "Delete client session?"
                }
                description={
                  sessionNotesData.length > 0 &&
                  "Connected notes will not be deleted"
                }
                confirmButton="Delete"
                closeButton="Cancel"
                onConfirm={() => {
                  deleteSession.mutate({ sessionId: currentSession!.id })
                }}
              >
                <Button
                  variant={"ghost"}
                  className={cn(
                    "text-xs sm:text-sm text-red-500 hover:text-red-500",
                    mode === "edit" && "hidden"
                  )}
                >
                  <Trash2Icon className="size-4 text-red-500" />
                  Delete
                </Button>
              </ConfirmationModal>
            )}
          </div>
        </div>
      }
    >
      {mode === "edit" ? (
        <BookingControls
          start={start}
          onSubmit={handleSubmit}
          formAction="edit"
        />
      ) : (
        <>
          <div className="flex flex-col w-full gap-1 sm:gap-2">
            {clientData.name && (
              <h3 className="text-base">
                Client: <strong>{clientData.name}</strong>
              </h3>
            )}

            {!currentSession?.personal && !clientData.id && (
              <p className="text-sm text-muted-foreground flex items-center gap-1">
                <TriangleAlert className="size-4" />
                No client assigned to this client session
              </p>
            )}

            <p className="capitalize">
              {start.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} &middot;{" "}
              {start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
              {end.toLocaleString(DateTime.TIME_SIMPLE)}
            </p>

            {currentSession?.repeatId && (
              <p className="text-sm text-muted-foreground flex items-center gap-1">
                <Repeat2 className="size-4 flex-shrink-0" />
                This is a repeating{" "}
                {currentSession.personal ? "event" : "session"}.
              </p>
            )}

            <p className="text-sm text-muted-foreground">
              {currentSession?.description}
            </p>
          </div>

          {sessionNotesData.length > 0 ? (
            <div className="flex flex-col justify-start self-start">
              <Link
                to={`/notes/${sessionNotesData[0].id}`}
                className="underline underline-always"
                onClick={() => setSelectedEvent(null)}
              >
                View your note{sessionNotesData.length > 1 && "s"} for this
                session{" "}
                {sessionNotesData.length > 1 && `(${sessionNotesData.length})`}
              </Link>
            </div>
          ) : (
            lastNoteData?.id && (
              <div className="flex flex-col justify-start self-start">
                <Link
                  to={`/notes/${lastNoteData.id}`}
                  className="underline underline-always"
                  onClick={() => setSelectedEvent(null)}
                >
                  View client notes
                </Link>
              </div>
            )
          )}

          <div className="flex flex-row w-full gap-1 sm:gap-2 mt-4 mb-4">
            {sessionNotesData.length > 0 ? (
              <div className="flex flex-row justify-center items-center gap-2 flex-1 text-xs sm:text-sm">
                <span>
                  <CheckCircle className="size-5 text-situational-success" />
                </span>
                <p>Note added</p>
                <Button
                  className="flex-1 text-xs sm:text-sm"
                  onClick={navigateToRecorder}
                >
                  Add additional note
                </Button>
              </div>
            ) : (
              <Button
                className="flex-1 text-xs sm:text-sm"
                onClick={navigateToRecorder}
              >
                Record note {currentSession?.personal ? "" : "for session"}
              </Button>
            )}
          </div>
        </>
      )}
    </WeeklyFooter>
  )
}
