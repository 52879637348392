import { useMemo } from "react"
import { Outlet, useLocation } from "react-router"
import BannerMessage from "~/components/BannerMessage"
import Header from "~/components/Header"
import MainDrawer from "~/components/MainDrawer"
import TrialNoticeHeader from "~/components/TrialNoticeHeader"
import "./main.css"

export default function Main() {
  const location = useLocation()

  const showTopBar = useMemo(() => {
    return location.pathname === "/notes" || location.pathname === "/account"
  }, [location])

  const showBanner = useMemo(() => {
    return location.pathname === "/notes"
  }, [location])

  return (
    <div className="h-full w-full bg-soft_green-500">
      <div
        className={`main-content-bg main-content-shadow flex flex-col m-auto h-full`}
      >
        <MainDrawer />

        {showBanner && <BannerMessage />}

        <Header
          classes={`w-full bg-primary-cream-300 ${!showTopBar && "hidden"}`}
        >
          <TrialNoticeHeader classes="bg-primary-cream-300 py-1" />
        </Header>

        <div className="flex-1 overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
