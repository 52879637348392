import * as React from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Undo2 } from "lucide-react"
import { useForm } from "react-hook-form"
import AudioPlayer from "~/components/AudioPlayer"
import NoteActions from "~/components/NoteActions"
import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
} from "~/components/ui"
import { cn } from "~/components/ui/utils"
import { useTranscribedNote, useUndoDeleteNote } from "~/hooks/useNotes"
import { parseTranscribed } from "~/pages/Notes/EditNoteModal"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { NoteStatus, type Note } from "./types"

export type NoteItemProps = {
  note: Note
  initialExpanded?: boolean
  initialOpenEdit?: boolean
  onDeletedNote?: (noteId: string) => void
  onClientRemoved?: (noteId: string) => void
}

export function NoteItem({
  note,
  onDeletedNote,
  onClientRemoved,
  initialExpanded = false,
  initialOpenEdit = false,
}: NoteItemProps) {
  const queryClient = useQueryClient()

  const [isExpanded, setIsExpanded] = React.useState(initialExpanded)
  const [showRecording, setShowRecording] = React.useState(false)

  /* Actions */
  const undoDeleteNote = useUndoDeleteNote({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["NOTE", note.id],
      })
    },
  })

  const isImprovementRequested = note.status === NoteStatus.ImprovementRequested
  const transcribed = useTranscribedNote({
    note,
    reactQuery: {
      enabled: isImprovementRequested,
    },
  })

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const transcription = isImprovementRequested
    ? (transcribed.data ?? "")
    : parseTranscribed(note)

  const form = useForm({
    values: {
      transcription,
    },
  })

  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  const isNoteDone = !!note?.markedAsDoneAt
  const [isCollapsed, setIsCollapsed] = React.useState(isNoteDone)

  // Trigger collapse when note is marked as done
  React.useEffect(() => {
    if (isNoteDone) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  }, [isNoteDone])

  return (
    <section
      key={note.id}
      ref={contentRef}
      className={cn(
        "notes-detail-bg bg-white relative rounded-2xl mt-4",
        !isExpanded && "max-h-30 overflow-hidden cursor-ns-resize"
      )}
      role={isExpanded ? undefined : "button"}
      tabIndex={isExpanded ? 0 : undefined}
      data-expanded={isExpanded ? "true" : undefined}
    >
      {note.deletedAt ? (
        <div className="flex bg-medium-grey-350 justify-between font-archivo my-3 px-6 py-3 rounded-2xl bg-zinc-100/80 text-primary-black items-center">
          <span>Deleted</span>
          <Button
            type="button"
            variant="outline"
            className="bg-gray-100 border-0 px-5 py-3.5 rounded-lg text-base h-[52px]"
            isLoading={undoDeleteNote.isPending}
            onClick={() => undoDeleteNote.mutate({ noteId: note.id })}
          >
            <Undo2 className="size-4" />
            Undo
          </Button>
        </div>
      ) : (
        <Form
          ref={formRef}
          form={form}
        >
          <Collapsible
            className="group"
            open={!isCollapsed}
            onOpenChange={(isOpen) => {
              setIsCollapsed(!isOpen)
              // Expand the note if it's not collapsed
              if (isOpen) {
                setIsExpanded(true)
              }
            }}
          >
            <header className="sticky top-0 inset-x-0 z-[50] group w-full flex justify-between items-center px-4 py-1.5 bg-white hover:bg-neutral-50 rounded-t-2xl group-data-[state=closed]:rounded-b-2xl group-data-[state=open]:bg-neutral-50">
              <CollapsibleTrigger className="shrink-0 flex-1 bg-transparent p-0 appearance-none focus:outline-none text-left">
                <span className="w-full">
                  <span className="text-primary-black font-mono tracking-wide text-lg font-bold capitalize">
                    {formatTimestampAsNoteTitleDatePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                  <span className="hidden md:inline"> · </span>
                  <span className="text-muted-foreground font-mono tracking-wide text-lg font-medium hidden md:inline">
                    {formatTimestampAsNoteTitleTimePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                </span>
              </CollapsibleTrigger>

              <NoteActions
                note={note}
                initialOpenEdit={initialOpenEdit}
                setShowRecording={setShowRecording}
                onDeletedNote={onDeletedNote}
                onClientRemoved={onClientRemoved}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              />
            </header>

            <CollapsibleContent>
              <FormField
                control={form.control}
                name="transcription"
                rules={{
                  required: "Transcription is required",
                }}
                render={({ field }) => (
                  <FormItem
                    className="pb-4 px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      if (!isExpanded) {
                        setIsExpanded(true)
                      }
                    }}
                  >
                    <FormControl>
                      <RichEditor
                        {...field}
                        ref={(ref) => {
                          field.ref(ref)
                          editorRef.current = ref
                        }}
                        readOnly={true}
                        placeholder={"Edit your note here"}
                        onRequestSave={() => {
                          if (form.formState.isDirty) {
                            formRef.current?.requestSubmit?.()
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {showRecording && (
                <AudioPlayer
                  id={`audio-player-${note.id}`}
                  storageId={note?.storageRef as string}
                  onClose={setShowRecording}
                />
              )}
            </CollapsibleContent>
          </Collapsible>
        </Form>
      )}

      {!isExpanded && !isCollapsed ? (
        <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none" />
      ) : null}
    </section>
  )
}
