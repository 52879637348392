import React, { useEffect, useRef, useState } from "react"
import { captureException, captureMessage } from "@sentry/react"
import { useQueryClient } from "@tanstack/react-query"
import { Crisp } from "crisp-sdk-web"
import {
  collection,
  doc,
  onSnapshot,
  Timestamp,
  updateDoc,
  type Unsubscribe,
} from "firebase/firestore"
import { Circle, LoaderCircle, Pause, XIcon } from "lucide-react"
import { DateTime } from "luxon"
import { isMobile } from "react-device-detect"
import { useLocation, useNavigate } from "react-router"
import { useWakeLock } from "react-screen-wake-lock"
import { useAnalytics } from "use-analytics"
import { z } from "zod"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  BlockUI,
  Button,
  ConfirmationModal,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"
import { sentryDefaultTags } from "~/config/instrument"
import { useAuth } from "~/context/AuthContext"
import useAudioRecorder from "~/hooks/useAudioRecorder"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import { useNavigatorOnLine } from "~/hooks/useNavigatorOnline"
import { useDeleteNote } from "~/hooks/useNotes"
import useSoundDetection from "~/hooks/useSoundDetection"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserProfile } from "~/hooks/useUserProfile"
import { useUserStatistics } from "~/hooks/useUserStatistics"
import RecordingRetryUploadDialog, {
  RecordingRetryUploadState,
} from "~/pages/Notes/RecordingRetryUploadDialog"
import { NoteStatus } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { createNewNote } from "~/services/notes.service"
import { downloadLocalRecording } from "~/utils/downloadRecordings"
import { getColor } from "~/utils/getColors"
import { PROMPTS } from "~/utils/prompts"
import { RecordingSyncStatus } from "~/utils/recordings/protocol"
import { getSyncClient } from "~/utils/recordings/syncClient"
import LanguageSelector from "../../components/LanguageSelector"
import AudioVolumeVisualizer from "./AudioVolumeVisualizer"
import MicrophonePermission from "./MicrophonePermissionCheck"
import RecorderInspiration from "./RecorderInspiration"
import RecorderOnboarding from "./RecorderOnboarding"
import { RecordingUploadDialog, UploadingState } from "./RecordingUploadDialog"

// Create a Timestamp from an object with seconds and nanoseconds
const preprocessTimestamp = (arg: unknown) => {
  if (
    typeof arg === "object" &&
    arg !== null &&
    "seconds" in arg &&
    "nanoseconds" in arg &&
    typeof arg.seconds === "number" &&
    typeof arg.nanoseconds === "number"
  ) {
    return new Timestamp(arg.seconds, arg.nanoseconds)
  }
  return arg
}

const prevPageStateSchema = z.object({
  clientId: z.string().optional(),
  clientName: z.string().optional(),
  title: z.string().optional(),
  sessionId: z.string().optional(),
  sessionStart: z
    .preprocess(preprocessTimestamp, z.instanceof(Timestamp))
    .optional(),
})

const RecorderCard = ({ children, ...props }: React.PropsWithChildren) => {
  return (
    <div
      className="relative flex-col w-full max-w-[768px] md:rounded-2xl flex justify-center h-full shadow-extended"
      {...props}
    >
      <div className="flex h-full flex-col min-h-[527px] justify-center gap-4 py-10 px-6 fixed z-50 md:relative bottom-0 left-0 right-0 bg-soft_green-500 md:rounded-2xl">
        {children}
      </div>
    </div>
  )
}

function Recorder() {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  const {
    recording,
    paused,
    recordedChunks,
    error,
    time,
    frequencyData,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    recordingStorage,
    recordingComplete,
  } = useAudioRecorder()

  const { isSupported, request, release } = useWakeLock({
    // On iOS we will not get a wake lock if device is in low power mode, inform user. // TODO: Add link to Q&A
    onError: () =>
      isMobile &&
      toast.info(
        "Can not keep screen awake. You may loose your recording if the screen turns off.",
        { duration: 5000 }
      ),
  })

  const [microphoneGranted, setMicrophoneGranted] = useState(false)
  const [uploadingState, setUploadingState] = useState<UploadingState>({
    uploading: false,
    bytesTransferred: 0,
    progress: 0,
  })
  const [recordedTime, setRecordedTime] = useState<string>("00:00")
  const [userProfile, updateUserProfile] = useUserProfile()
  const [userLanguageCode, setUserLanguageCode] = useState<string>(
    userProfile?.dictationLanguage ?? "en"
  )
  const [recordingId, setRecordingId] = useState<string>()
  const [showCloseRecorderConfirmation, setShowCloseRecorderConfirmation] =
    useState(false)

  // Passed from previous page
  const [clientId, setClientId] = useState<string | undefined>(undefined)
  const [clientName, setClientName] = useState<string | undefined>(undefined)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [sessionId, setSessionId] = useState<string | undefined>(undefined)
  const [sessionStart, setSessionStart] = useState<Timestamp | undefined>(
    undefined
  )
  const [sessiontStartHHMM, setSessionStartHHMM] = useState<string | undefined>(
    undefined
  )
  const [retryUpload, setRetryUpload] = useState(false)
  const snapshotUnsubscribeRef = useRef<Unsubscribe | undefined>(undefined)

  // indicate that the user is done recording and make sure the recording
  // cannot be started again
  const [doneRecording, setDoneRecording] = useState(false)
  const [isStarting, setIsStarting] = useState(false)

  const [isUserDataReady, setIsUserDataReady] = useState(false)

  const [userJourney, updateUserJourney, isUserJourneyPending] =
    useUserJourney()
  const [userStatistics, , isStatisticsPending] = useUserStatistics()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  // Check if a client ID is passed from the previous page
  const { state } = useLocation()

  useNavigatorOnLine({
    onOnline: async () => {
      // Re-initate sync
      try {
        if (recordingId && uploadingState.uploading) {
          await getSyncClient().call({
            kind: "recording-sync-init-request",
            payload: { recordingId: recordingId },
          })
        }
      } catch (error) {
        if (error instanceof Error) {
          captureMessage(`Recorder failed re-initiating sync`, {
            level: "warning",
            tags: {
              ...sentryDefaultTags,
              recording_function: "upload_re-init_on_online",
              recording_id: recordingId,
              recording_error: error.message,
            },
          })
        }
      }
    },
  })

  // Cleanup snapshot listener
  useEffect(() => {
    return () => {
      if (snapshotUnsubscribeRef.current) {
        snapshotUnsubscribeRef.current()
      }
    }
  }, [snapshotUnsubscribeRef])

  // Check if user journey and statistics are ready
  useEffect(() => {
    if (
      !isStatisticsPending &&
      !isUserJourneyPending &&
      userJourney &&
      userStatistics
    ) {
      setIsUserDataReady(true)
    }
  }, [isStatisticsPending, isUserJourneyPending, userJourney, userStatistics])

  useEffect(() => {
    const parsedState = prevPageStateSchema.safeParse(state)
    if (parsedState.success) {
      setClientId(parsedState.data?.clientId)
      setClientName(parsedState.data?.clientName)
      setTitle(parsedState.data?.title)
      setSessionId(parsedState.data?.sessionId)
      setSessionStart(parsedState.data?.sessionStart)
      if (parsedState.data?.sessionStart) {
        const date = parsedState.data.sessionStart.toDate()
        const sessionStart = DateTime.fromJSDate(date)
        setSessionStartHHMM(sessionStart.toLocaleString(DateTime.TIME_SIMPLE))
      }
    }
  }, [state])

  //
  // Notify user if no sound is detected
  const notifySoundDetected = (message: string) => {
    toast.success(message, { duration: 5000 })
  }

  const notifyNoSoundDetected = (message: string) => {
    toast.warning(message, { duration: 5000 })
  }

  useBeforeunload(() => {
    if (recording || paused || (doneRecording && uploadingState.uploading)) {
      return "Recording is not fully saved and data may be lost."
    }
  })

  useSoundDetection(
    frequencyData,
    time,
    notifySoundDetected,
    notifyNoSoundDetected
  )

  const invalidateNote = React.useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["NOTE", recordingId],
      }),
    [queryClient, recordingId]
  )

  const deleteNote = useDeleteNote({
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error deleting note")
      } else {
        await invalidateNote()
      }
    },
  })

  const handleCreateWrittenNote = () => {
    handleCreateWrittenNoteAsync().catch((err) => {
      toast.error("Error creating written note")
      console.error(err)
    })
  }

  const handleCreateWrittenNoteAsync = async () => {
    if (!currentUser) {
      return
    }
    const noteRef = await createNewNote({
      currentUserId: currentUser.uid,
      noteStatus: NoteStatus.Edited,
      clientId,
      title,
      clientName,
      sessionId,
      sessionStart,
      userStatistics: userStatistics ?? undefined,
      dictationLanguage: userProfile?.dictationLanguage ?? "",
    })

    await updateDoc(noteRef, {
      editedTranscription: "",
    })

    await queryClient.invalidateQueries({
      queryKey: ["NOTE"],
    })

    void track("Note New_written")

    void navigate(`/notes/${noteRef.id}?edit=1`)
  }

  // Handle recording start and stop
  const handleStartRecording = async () => {
    if (isStarting) {
      return
    }
    setIsStarting(true)
    try {
      if (!currentUser) {
        return
      }
      if (isSupported) {
        await request()
      }

      // Generate a new recording ID
      const docRef = doc(collection(db, "recordings"))
      const newRecordingId = docRef.id
      setRecordingId(newRecordingId)

      await startRecording(currentUser.uid, newRecordingId)

      const noteRef = await createNewNote({
        currentUserId: currentUser.uid,
        noteId: newRecordingId,
        noteStatus: NoteStatus.Recording,
        userStatistics: userStatistics ?? undefined,
        dictationLanguage: userProfile?.dictationLanguage ?? "",
        duration: 0,
        clientId,
        title,
        clientName,
        sessionId,
        sessionStart,
      })

      await queryClient.invalidateQueries({
        queryKey: ["NOTE"],
      })

      // Start subscription to note doc
      snapshotUnsubscribeRef.current = onSnapshot(noteRef, (doc) => {
        const data = doc.data()
        if (data) {
          const noteStatus = data.status
          if (noteStatus === NoteStatus.Processing) {
            // Upload is complete
            setUploadingState({
              uploading: false,
              bytesTransferred: 0,
              progress: 0,
            })
            void navigate("/notes")
          }
        }
      })
    } catch (error) {
      captureException(error, {
        user: { id: currentUser?.uid },
        tags: {
          ...sentryDefaultTags,
          recording_function: "handleStartRecording",
        },
      })
      toast.error("Error starting recording")
    } finally {
      setIsStarting(false)
    }
  }

  const handleStopRecording = () => {
    handleStopRecordingAsync().catch((err) => {
      toast.error("Error stopping recording")
      console.error(err)
    })
  }

  const handleStopRecordingAsync = async () => {
    if (!currentUser) {
      return
    }
    if (isSupported) {
      await release()
    }
    stopRecording()

    setDoneRecording(true)
  }

  const handleLanguageSelect = (
    _newLanguage: string,
    newLanguageCode: string
  ) => {
    setUserLanguageCode(newLanguageCode)
    updateUserProfile({ dictationLanguage: newLanguageCode })
  }

  useEffect(() => {
    // This is used when the audio recorder signals "recordingComplete" which means both
    // that the audio recorder has finished AND the storage data writer was closed
    const handleRecordingComplete = async () => {
      if (!currentUser) {
        return
      }

      if (recordingId === undefined || recordingStorage === null) {
        throw new Error("Recording ID not initialized.")
      }

      setUploadingState({
        uploading: true,
        bytesTransferred: 0,
        progress: 0,
        background: recordingStorage.usesBackgroundOperations,
      })

      try {
        if (!isUserJourneyPending) {
          if (
            userJourney?.firstNoteRecorded &&
            !userJourney?.secondNoteRecorded
          ) {
            await updateUserJourney({ secondNoteRecorded: true })
          } else {
            if (!userJourney?.firstNoteRecorded) {
              await updateUserJourney({ firstNoteRecorded: true })
            }
          }
        }
      } catch {
        console.error("Error updating user journey state")
      }

      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${recordingId}`)

      void track("Note New_recorded", {
        language: userProfile?.dictationLanguage ?? "auto",
      })

      let recordingStatus: RecordingSyncStatus | undefined = undefined

      try {
        recordingStatus = await recordingStorage.status()
      } catch (err: unknown) {
        if (err instanceof Error) {
          captureMessage(`Recorder failed retrieving recording status`, {
            level: "warning",
            tags: {
              ...sentryDefaultTags,
              recording_function: "recorder_stop",
              recording_id: recordingId,
              recording_error: err.message,
            },
          })
        }
      }

      if (recordingStatus === undefined) {
        // failed to upload/write recording to storage

        toast.error(
          "Failed to upload the note. Please contact support for further info."
        )

        // Update note document with upload complete
        await updateDoc(noteRef, {
          status: NoteStatus.Error,
        })

        setUploadingState({
          uploading: false,
          error: "Error uploading recording",
          bytesTransferred: 0,
          progress: 0,
          background: false,
        })

        void navigate("/notes")
        return
      }

      if (!recordingStorage.usesBackgroundOperations) {
        await updateDoc(noteRef, {
          status: NoteStatus.Uploading,
        })

        setUploadingState({
          uploading: true,
          bytesTransferred: recordingStatus.bytesSynced,
          bytesTotal: recordingStatus.totalBytes,
          progress: 100,
          background: false,
        })

        // Update note document recording data
        await updateDoc(noteRef, {
          storageRef: recordingStatus.remotePath,
          recording: {
            recordingId,
            storagePath: recordingStatus.remotePath,
            bytesUploaded: recordingStatus.bytesSynced,
            totalBytes: recordingStatus.totalBytes,
          },
        })

        // Delete the local file
        await recordingStorage?.delete()

        void navigate("/notes")
      }
    }

    if (recordingComplete) {
      void handleRecordingComplete()
    }
    // Disable eslint for this since the only dependency that matters is when
    // the recording completes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingComplete])

  useEffect(() => {
    // Uploading - check upload status on interval
    if (uploadingState.uploading && recordingStorage !== null) {
      const timer = setTimeout(() => {
        recordingStorage
          .status()
          .then((status: RecordingSyncStatus) => {
            setUploadingState({
              uploading: true,
              bytesTransferred: status.bytesSynced,
              bytesTotal: status.totalBytes,
              syncState: status.state,
              progress: status.totalBytes
                ? Math.round((status.bytesSynced / status.totalBytes) * 100)
                : 0,
              background: recordingStorage.usesBackgroundOperations,
            })
          })
          .catch(() => {
            setUploadingState({
              uploading: false,
              bytesTransferred: 0,
              error: "Error uploading recording",
              progress: 0,
              background: recordingStorage.usesBackgroundOperations,
            })
          })
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [uploadingState, recordingStorage])

  useEffect(() => {
    const recordedSeconds = Math.floor(time / 1000)

    // Calculated number of recorded minutes
    const recordedMinutes = Math.floor(recordedSeconds / 60)

    // Calculated number of recorded seconds
    const recordedSecondsRemainder = recordedSeconds % 60

    setRecordedTime(
      `${recordedMinutes.toString().padStart(2, "0")}:${recordedSecondsRemainder
        .toString()
        .padStart(2, "0")}`
    )
  }, [time])

  const closeAndHideChat = () => {
    if (Crisp.chat.isChatOpened()) {
      Crisp.chat.close()
    }
    if (Crisp.chat.isVisible()) {
      Crisp.chat.hide()
    }
  }

  useEffect(() => {
    // Set a timeout to close the chat after 2 seconds
    const timeout = setTimeout(() => {
      closeAndHideChat()
    }, 2000)

    // Close and hide chat on component mount
    closeAndHideChat()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const triggerCrispChat = () => {
    if (!Crisp.chat.isVisible()) {
      Crisp.chat.show()
    }
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open()
    }
  }

  const handleDownloadRecording = async () => {
    try {
      if (recordingId) {
        await downloadLocalRecording({ id: recordingId })
      } else {
        throw new Error("No recording ID")
      }
    } catch (error) {
      captureException(error, {
        user: { id: currentUser?.uid },
        tags: {
          ...sentryDefaultTags,
          recording_function: "handleDownloadRecording",
          recording_id: recordingId,
        },
      })
      toast.error("Could not download the recording, contact support")
      void track("Recorder Error_downloading_local_recording")
    }
  }

  const handleRetryUpload = () => {
    setRetryUpload(true)
    void track("Recorder Retry_upload_initiated")
  }

  const handleUploadDialogClosed = () => {
    void navigate("/notes")
  }

  const handleRecorderClose = () => {
    if (
      recording ||
      paused ||
      (doneRecording && !uploadingState.uploading && !uploadingState.background)
    ) {
      setShowCloseRecorderConfirmation(true)
    } else if (doneRecording) {
      void navigate(-1)
    } else {
      deleteNoteAndNavigateBack()
    }
  }

  const deleteNoteAndNavigateBack = () => {
    if (recordingId) {
      deleteNote.mutate({ noteId: recordingId })
    }
    // Todo: Pass the previous page to the recorder so we can navigate back to it.
    // Below will fail if the user navigates directly to the recorder.
    void navigate(-1)
  }

  const lessThanThreeNotes = (userStatistics?.totalSavedNotes ?? 0) < 3

  const showRecorderInspiration =
    lessThanThreeNotes &&
    !userJourney?.firstNoteCompleted &&
    (recording || recordedChunks.length !== 0) &&
    !isStatisticsPending

  const showWelcomeMessage =
    lessThanThreeNotes &&
    !userJourney?.firstNoteRecorded &&
    !recording &&
    recordedChunks.length === 0

  if (!isUserDataReady) {
    return <LoadingDataCard onClose={handleRecorderClose} />
  }

  if (!microphoneGranted) {
    return (
      <MicrophoneCheckCard
        onClose={handleRecorderClose}
        showWelcomeMessage={showWelcomeMessage}
        onPermissionGranted={() => setMicrophoneGranted(true)}
        onTalkToSupport={triggerCrispChat}
        onCreateWrittenNote={handleCreateWrittenNote}
      />
    )
  }

  return (
    <>
      {retryUpload && (
        <RecordingRetryUploadDialog
          recordingId={recordingId}
          onDismissed={(status) => {
            setRetryUpload(false)
            if (status === RecordingRetryUploadState.SUCCESS) {
              void navigate("/notes")
            }
          }}
        />
      )}

      {uploadingState.uploading && (
        <RecordingUploadDialog
          uploadingState={uploadingState}
          onDownloadRecording={handleDownloadRecording}
          onRetryUpload={handleRetryUpload}
          onClosed={handleUploadDialogClosed}
        />
      )}

      <ConfirmationModal
        isDestructive
        isOpen={showCloseRecorderConfirmation}
        onOpenChange={setShowCloseRecorderConfirmation}
        title="Recording in progress"
        description="You have an ongoing recording. If you end now, the recording will be lost."
        closeButton="Stay"
        confirmButton="End and discard"
        onConfirm={deleteNoteAndNavigateBack}
      />

      <div className="min-h-screen flex items-center justify-center bg-primary-cream-300">
        <RecorderCard>
          <Button
            size="icon"
            variant="ghost"
            onClick={handleRecorderClose}
            className={`absolute top-4 right-4 ${showWelcomeMessage || showRecorderInspiration ? "invisible" : "visible"}`}
          >
            <XIcon
              className="size-8"
              strokeWidth={1.35}
            />
          </Button>

          {
            <div
              className={`flex flex-col items-center justify-center ${(showWelcomeMessage || showRecorderInspiration) && "invisible"}`}
            >
              <h5 className="text-center text-2xl font-['Platypi'] text-black pb-2">
                {clientName ? `${clientName} ${sessiontStartHHMM}` : "New note"}
              </h5>

              <LanguageSelector
                disabled={recording}
                selectedLanguageCode={userLanguageCode}
                onLanguageSelect={handleLanguageSelect}
              />
            </div>
          }

          <div className="flex flex-col items-center justify-center min-w-full flex-grow pt-4 px-2">
            {showRecorderInspiration && (
              <RecorderInspiration languageCode={userLanguageCode} />
            )}
            {
              /* SHOW WELCOME MESSAGE OR AUDIO VISUALIZER */
              showWelcomeMessage ? (
                <RecorderOnboarding />
              ) : (
                <div className="mt-1">
                  <AudioVolumeVisualizer
                    frequencyData={frequencyData}
                    barColor={
                      recording && !paused
                        ? getColor("bright_coral", 500)
                        : getColor("transparent_gray")
                    }
                  />
                </div>
              )
            }
          </div>

          <div className="flex flex-col items-center justify-center">
            <div
              className={`pt-2 pb-8 mt-5 ${(showWelcomeMessage || showRecorderInspiration) && "hidden"}`}
            >
              <p className="text-black text-4xl font-medium font-mono tracking-widest">
                {recordedTime}
              </p>
            </div>
            <div className="flex flex-row justify-between min-w-full">
              <div className="flex-1"></div>
              {/* This div acts as a spacer */}
              <Button
                onClick={
                  paused
                    ? resumeRecording
                    : recording
                      ? pauseRecording
                      : handleStartRecording
                }
                disabled={doneRecording || isStarting}
                className="w-[104px] h-[104px] rounded-[36px] bg-bright_coral-500 hover:bg-bright_coral-600 text-white shadow-short-flat active:shadow-none transition-shadow transition-colors duration-300"
              >
                {paused ? (
                  <span className="text-[1.2rem]">Resume</span>
                ) : recording ? (
                  <Pause
                    className="w-7 h-7"
                    fill="currentColor"
                  />
                ) : doneRecording ? (
                  <LoaderCircle className="w-7 h-7 animate-spin" />
                ) : (
                  <Circle
                    className="w-7 h-7"
                    fill="currentColor"
                  />
                )}
              </Button>
              <div className="flex-1 justify-center flex">
                <button
                  onClick={handleStopRecording}
                  className={`text-primary-black text-[22px] mr-3 ${recording ? "visible" : "invisible"}`}
                >
                  Done
                </button>
              </div>
            </div>

            <div className="flex justify-center min-w-full mt-3 md:justify-end md:absolute md:bottom-4 md:right-4">
              <button
                onClick={handleCreateWrittenNote}
                className={`text-primary-black text-md md:mr-3 ${recording || time > 0 || doneRecording || showWelcomeMessage ? "invisible" : "visible"}`}
              >
                Create a new written note instead?
              </button>
              <button
                onClick={triggerCrispChat}
                className={`text-primary-black text-md md:mr-3 ${showWelcomeMessage ? "visible" : "hidden"}`}
              >
                Need help?
              </button>
            </div>
          </div>
        </RecorderCard>
      </div>

      <RecordAlertDialog
        error={error}
        triggerCrispChat={triggerCrispChat}
      />
    </>
  )
}

interface MicrophoneCheckCardProps {
  onClose: () => void
  showWelcomeMessage?: boolean
  onPermissionGranted: () => void
  onTalkToSupport: () => void
  onPermissionChange?: (permission: PermissionState | "unknown") => void
  onCreateWrittenNote?: () => void
}

const MicrophoneCheckCard: React.FC<MicrophoneCheckCardProps> = ({
  onClose,
  showWelcomeMessage,
  onPermissionGranted,
  onTalkToSupport,
  onPermissionChange,
  onCreateWrittenNote,
}) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-cream-300">
      <RecorderCard>
        <Button
          size="icon"
          variant="ghost"
          onClick={onClose}
          className="absolute top-4 right-4"
        >
          <XIcon
            className="size-8"
            strokeWidth={1.35}
          />
        </Button>

        <MicrophonePermission
          isIntroduction={showWelcomeMessage}
          onPermissionGranted={onPermissionGranted}
          onTalkToSupport={onTalkToSupport}
          onPermissionChange={onPermissionChange}
        />

        <div className="flex justify-center md:justify-end min-w-full mt-3 md:absolute md:bottom-4 md:right-4">
          <button
            onClick={onCreateWrittenNote}
            className={`text-primary-black text-md md:mr-3`}
          >
            Create a new written note instead?
          </button>
        </div>
      </RecorderCard>
    </div>
  )
}

interface LoadingDataCardProps {
  onClose: () => void
}

const LoadingDataCard: React.FC<LoadingDataCardProps> = ({
  onClose,
}: LoadingDataCardProps) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-cream-300">
      <RecorderCard>
        <Button
          size="icon"
          variant="ghost"
          onClick={onClose}
          className="absolute top-4 right-4"
        >
          <XIcon
            className="size-8"
            strokeWidth={1.35}
          />
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost">
              <BlockUI isLoading />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Loading.. Refresh the page if it takes too long.
          </TooltipContent>
        </Tooltip>
      </RecorderCard>
    </div>
  )
}

function RecordAlertDialog({
  error,
  triggerCrispChat,
}: {
  error: string | null
  triggerCrispChat: () => void
}) {
  const navigate = useNavigate()

  return (
    <AlertDialog
      aria-labelledby="close-modal-title"
      open={error !== null}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          void navigate("/")
        }
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          {
            // Handle not allowed error separately
            error?.match(/NotAllowed/) ? (
              <>
                <AlertDialogTitle>
                  You have not allowed microphone access
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {isMobile ? (
                    PROMPTS.MIC_ACCESS_MESSAGE
                  ) : (
                    <>
                      Click on the microphone or settings icon in the address
                      bar and select <strong>Allow</strong>. You might also need
                      to enable microphone access in your system settings.
                    </>
                  )}
                </AlertDialogDescription>
              </>
            ) : (
              <>
                <AlertDialogTitle id="close-modal-title">
                  Error recording audio
                </AlertDialogTitle>
                <AlertDialogDescription>{error}</AlertDialogDescription>
              </>
            )
          }
          <button
            onClick={triggerCrispChat}
            className={"text-primary-black text-md mt-2"}
          >
            Need help?
          </button>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogAction
            onClick={(e) => {
              e.preventDefault()
              window.location.reload()
            }}
          >
            Okay
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default Recorder
