import * as Sentry from "@sentry/browser"
import { httpsCallable } from "firebase/functions"
import { type Content, type Part } from "firebase/vertexai"
import { type Message } from "~/hooks/firestore/useConversations"
import { functions } from "~/services/firebase"

export const createTitleAsync = async (
  messages: Message[]
): Promise<string> => {
  if (messages.length === 0) {
    return "Not enoguh data to create a title"
  }

  try {
    // Concatenate the messages into a single string
    const messagesString = messages.reduce((acc, message, index) => {
      return (
        acc +
        (index > 0 ? " \n ========== \n " : "") +
        message.role +
        ": " +
        message.parts
          .map((part) => {
            return part.type === "text" ? part.text : ""
          })
          .join(" ")
      )
    }, "")

    const llmInstruction = `
    Create a one line title for the provided conversation history. Use plain text, no markdown or HTML. Keep it short, no more than five words.
  `

    const llmQuery = httpsCallable(functions, "llmDirectQuery")

    const title = await llmQuery({
      instruction: llmInstruction,
      text: messagesString,
    })
    type LLMResponse = {
      status: string
      text: string
      metadata: unknown
    }
    const titleData = title.data as LLMResponse

    if (titleData.status !== "ok") {
      return "Untitled"
    }
    return titleData.text
  } catch (error) {
    Sentry.captureException(error)
  }
  return "Could not create the title"
}

export const convertMessagesToHistory = (messages: Message[]) => {
  return messages.map((message) => {
    return {
      role: message.role,
      parts: message.parts.map((part) => ({
        text: part.type === "text" ? part.text : "",
      })) as Part[],
    } as Content
  })
}
