import { useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore"
import { useAnalytics } from "use-analytics"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { db } from "~/services/firebase"

export type Assistant = {
  id: string
  createdAt: Timestamp
  name: string
  role: string
  systemPrompt: string
  version: string
  memory: string[]
}

/**
 * Fetches the system assistant by its ID.
 * @param assistantId - The ID of the assistant to fetch.
 * @returns A Promise that resolves to a DocumentSnapshot containing the assistant's data.
 */
async function getSystemAssistantById(assistantId: string) {
  const assistantRef = doc(db, `assistants/${assistantId}`)
  const assistantSnapshot = await getDoc(assistantRef)

  if (!assistantSnapshot.exists()) {
    throw new Error("Assistant not found")
  }

  return assistantSnapshot
}

/* Queries */
export function useAssistantById({
  assistantId,
  reactQuery,
}: QueryParameter<Assistant> & {
  assistantId: string
}) {
  const { currentUser } = useAuth()

  const assistantPath = `users/${currentUser?.uid}/assistants/${assistantId}`
  const assistantRef = useMemo(() => doc(db, assistantPath), [assistantPath])

  return useQuery({
    ...reactQuery,
    queryKey: ["ASSISTANTS", assistantId],
    queryFn: async () => {
      try {
        const assistantSnapshot = await getDoc(assistantRef)
        if (!assistantSnapshot.exists()) {
          // If the assistant is not found, get from the system
          const systemAssistant = await getSystemAssistantById(assistantId)

          // Create local assistant from system assistant
          const assistant: Assistant = {
            ...(systemAssistant.data() as Assistant),
            id: assistantId,
            createdAt: Timestamp.fromDate(new Date()),
          }

          // Save the assistant to the user's collection
          await setDoc(assistantRef, assistant)

          return assistant as Assistant
        }
        const assistant = assistantSnapshot.data() as Assistant
        return assistant
      } catch (error) {
        console.log("error:", error)
        throw error
      }
    },
  })
}

/* Mutations */
export function useUpdateAssistant(
  options?: MutationOptions<{
    assistantId: string
    assistant: Assistant
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_ASSISTANT"],
    mutationFn: async ({ assistantId, assistant }) => {
      const assistantRef = doc(
        db,
        `users/${currentUser?.uid}/assistants/${assistantId}`
      )
      await updateDoc(assistantRef, {
        ...assistant,
      })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)
      void track("Assistant Updated")
    },
  })
}

export function useDeleteAssistant(
  options?: MutationOptions<{
    assistantId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ assistantId }) => {
      const assistantRef = doc(
        db,
        `users/${currentUser?.uid}/assistants/${assistantId}`
      )
      await deleteDoc(assistantRef)
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Assistant Deleted")
        void queryClient.invalidateQueries({ queryKey: ["ASSISTANTS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
