import React, { useEffect, useState } from "react"
import { ChevronLeft } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { useFormContext } from "react-hook-form"
import { BaseControl } from "~/components/base/base-control"
import { InlineCheckbox } from "~/components/base/inline-checkbox"
import ClientModal from "~/components/ClientModal"
import {
  Button,
  ConfirmationModal,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  SubmitButton,
  toast,
  ToggleGroup,
  ToggleGroupItem,
} from "~/components/ui"
import { cn } from "~/components/ui/utils"
import { renderMinute } from "~/components/weekly-calendar"
import { useLocalIntl } from "~/hooks/useLocalIntl"
import { useUserFeatureFlags } from "~/hooks/useUserFeatureFlags"
import { useCalendarStore } from "./store"
import { createRepeatOptions, type RepeatOption } from "./utils"

const startOptions = [0, 15, 30]
const durationOptions = [30, 45, 60]
const repeatTimesOption = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25]
const repeatTimesOptionDefault = 10

// Extend duration options to include 5-minutes increments up to 2-hours
// and 30-minutes increments up to 8-hours
const extendedDurationOptions = [
  ...Array.from({ length: 24 }, (_, i) => (i + 1) * 5),
  ...Array.from({ length: 12 }, (_, i) => 120 + (i + 1) * 30),
]

enum Step {
  Setup = 0,
  Client = 2,
}

export function BookingControls({ start, onSubmit, formAction = "create" }) {
  const { uses12Hour } = useLocalIntl()
  const { control, getValues, setValue, setFocus, handleSubmit, formState } =
    useFormContext()

  const useProposeTimes = useUserFeatureFlags()["useProposeTimes"]

  const [step, setStep] = React.useState<Step>(Step.Setup)
  const [showClientModal, setShowClientModal] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [repeatOptionSet, setRepeatOptionSet] = useState(false)
  const [repeatOptionText, setRepeatOptionText] = useState("")
  const [repeatValue, setRepeatValue] = useState(0)
  const [repeatTimes, setRepeatTimes] = useState(repeatTimesOptionDefault)

  const [repeatOptions, setRepeatOptions] = useState<RepeatOption[]>([])

  const startISO = start?.toISO()
  React.useEffect(() => setStep(Step.Setup), [startISO])

  const { selectMode, setSelectMode, selectedSlot } = useCalendarStore(
    (state) => ({
      selectMode: state.selectMode,
      setSelectMode: state.setSelectMode,
      selectedSlot: state.selectedSlot,
    })
  )

  const startProposeMode = () => {
    setSelectMode("propose")

    if (selectedSlot) {
      setValue("slots", [selectedSlot?.toISO()])
    }
  }

  useEffect(() => {
    if (!start) return
    const weekDay = start?.toLocaleString({
      weekday: "long",
    })
    const options = createRepeatOptions(weekDay)
    setRepeatOptions(options)
  }, [start])

  const handleConfirmRemoveClient = () => {
    // Update the form with pending changes
    setValue("personal", true)
    setValue("clientId", "")
    setValue("clientName", "")

    // Close dialog
    setShowConfirmDialog(false)

    // Focus on title input
    setTimeout(() => setFocus("title"), 0)
  }

  // Note: Use "getValues" is fine here, because the component is re-rendered
  // when "step" changes.
  const minutes = Number(getValues("start") ?? 0)
  const duration = Number(getValues("duration") ?? 0)

  useEffect(() => {
    setRepeatOptionText(
      ` at ${start.set({ minute: minutes }).toLocaleString(DateTime.TIME_SIMPLE)}`
    )
  }, [start, minutes])

  useEffect(() => {
    setValue("repeatCount", repeatTimes)
  }, [setValue, repeatTimes])

  // Toast errors
  React.useEffect(() => {
    if (!formState.isValid && Object.keys(formState.errors).length > 0) {
      toast.warning("Please fill in all required fields.")
    }
  }, [formState.isValid, formState.errors])

  const showRepeatComponents = formAction === "create"

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmDialog}
        onOpenChange={setShowConfirmDialog}
        title="Remove Client"
        confirmButton="Remove"
        description="Are you sure you want to remove the client for this appointment?"
        onConfirm={handleConfirmRemoveClient}
      />
      <div
        className={cn("space-y-2", step === Step.Setup ? "block" : "hidden")}
      >
        <div className="flex items-center justify-between w-full">
          {!repeatOptionSet && (
            <p className="text-base font-medium capitalize">
              {start.toLocaleString({
                weekday: "long",
                day: "numeric",
                month: "long",
              })}
            </p>
          )}

          {/* Repeat Option Dropdown */}
          {showRepeatComponents && (
            <FormField
              name="repeatFrequency"
              control={control}
              render={({ field }) => (
                <FormItem className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
                  <Select
                    onValueChange={(value) => {
                      if (value === "Does not repeat") {
                        setRepeatOptionSet(false)
                      } else {
                        setRepeatOptionSet(true)
                      }
                      setValue(
                        "repeatInterval",
                        repeatOptions.find((option) => option.text === value)
                          ?.interval
                      )
                      setValue(
                        "repeatFrequency",
                        repeatOptions.find((option) => option.text === value)
                          ?.frequency
                      )
                      const key = repeatOptions.find(
                        (option) => option.text === value
                      )?.value
                      setRepeatValue(key ?? 0)
                    }}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={cn(
                          "w-fit h-11 font-medium text-sm md:text-base border-0 rounded-3xl gap-1.5 bg-transparent text-secondary data-[placeholder]:text-secondary data-[placeholder]:bg-transparent"
                        )}
                      >
                        {repeatOptionSet ? (
                          <p>
                            {repeatOptions?.at(repeatValue ?? 0)?.text}
                            {repeatOptionText}
                          </p>
                        ) : (
                          "Does not repeat"
                        )}
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {repeatOptions.map((option) => (
                        <SelectItem
                          key={option.value}
                          value={option.text}
                        >
                          {repeatOptionSet ? (
                            <p>{option.text}</p>
                          ) : (
                            <p>{option.text}</p>
                          )}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {/* Repeat Count Dropdown */}
          {repeatOptionSet && (
            <FormField
              name="repeatCount"
              control={control}
              render={({ field }) => (
                <FormItem className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
                  <Select
                    onValueChange={(value) => {
                      field.onChange(Number(value))
                      setRepeatTimes(Number(value))
                    }}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={cn(
                          "w-fit h-11 font-medium text-sm md:text-base border-0 rounded-3xl gap-1.5 bg-transparent text-secondary data-[placeholder]:text-secondary data-[placeholder]:bg-transparent"
                        )}
                      >
                        <p>{field.value} times</p>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {repeatTimesOption.map((option) => (
                        <SelectItem
                          key={option}
                          value={option.toString()}
                        >
                          {option} times
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>

        <FormField
          name="start"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
              <FormLabel className="shrink-0 text-base font-normal sm:w-[80px]">
                Start
              </FormLabel>
              <div className="flex-1 flex items-center gap-2">
                <FormControl>
                  <ToggleGroup
                    size="lg"
                    type="single"
                    value={`${field.value}`}
                    onValueChange={(value) => {
                      if (value) {
                        field.onChange(Number(value))
                      }
                    }}
                  >
                    {startOptions.map((minute) => (
                      <ToggleGroupItem
                        key={minute}
                        value={`${minute}`}
                        className="px-4"
                      >
                        {renderMinute(start.set({ minute }), { uses12Hour })}
                      </ToggleGroupItem>
                    ))}
                  </ToggleGroup>
                </FormControl>

                <Separator
                  orientation="vertical"
                  className="w-[1.5px] h-8 bg-[#8C867C]"
                />

                <Select
                  value={
                    startOptions.includes(Number(field.value))
                      ? ""
                      : `${field.value}`
                  }
                  onValueChange={(value) => {
                    field.onChange(Number(value))
                  }}
                >
                  <SelectTrigger
                    className={cn(
                      "w-fit h-11 font-medium text-sm md:text-base border-0 rounded-3xl gap-1.5 bg-secondary text-secondary-foreground data-[placeholder]:text-secondary data-[placeholder]:bg-transparent"
                    )}
                  >
                    <SelectValue placeholder="other" />
                  </SelectTrigger>

                  <SelectContent>
                    {Array.from({ length: 12 }, (_, i) => i * 5).map(
                      (minute) => (
                        <SelectItem
                          key={minute}
                          value={minute.toString()}
                        >
                          {renderMinute(start.set({ minute }), { uses12Hour })}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="duration"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
              <FormLabel className="shrink-0 text-base font-normal sm:w-[80px]">
                Duration
              </FormLabel>
              <div className="flex-1 flex items-center gap-2">
                <FormControl>
                  <ToggleGroup
                    size="lg"
                    type="single"
                    value={`${field.value}`}
                    onValueChange={(value) => {
                      if (value) {
                        field.onChange(Number(value))
                      }
                    }}
                  >
                    {durationOptions.map((duration) => (
                      <ToggleGroupItem
                        key={duration}
                        value={`${duration}`}
                        className="px-4 min-w-[66px]"
                      >
                        {duration}
                      </ToggleGroupItem>
                    ))}
                  </ToggleGroup>
                </FormControl>

                <Separator
                  orientation="vertical"
                  className="w-[1.5px] h-8 bg-[#8C867C]"
                />

                <Select
                  value={
                    durationOptions.includes(Number(field.value))
                      ? ""
                      : `${field.value}`
                  }
                  onValueChange={(value) => {
                    field.onChange(Number(value))
                  }}
                >
                  <SelectTrigger
                    className={cn(
                      "w-fit h-11 font-medium text-sm md:text-base border-0 rounded-3xl gap-1.5 bg-secondary text-secondary-foreground data-[placeholder]:text-secondary data-[placeholder]:bg-transparent"
                    )}
                  >
                    <SelectValue placeholder="other" />
                  </SelectTrigger>

                  <SelectContent>
                    {extendedDurationOptions.map((duration) => (
                      <SelectItem
                        key={duration}
                        value={duration.toString()}
                      >
                        {duration > 60
                          ? Duration.fromDurationLike({
                              minutes: duration,
                            }).toFormat("h'h' mm'")
                          : Duration.fromDurationLike({
                              minutes: duration,
                            }).toFormat("m'm'")}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-row-reverse items-center justify-between mt-6 pt-4 gap-4">
          <Button
            variant={"secondary"}
            className="rounded-3xl w-[164px]"
            onClick={() => setStep(Step.Client)}
          >
            Next
          </Button>

          {useProposeTimes && (
            <Button
              variant={"outline"}
              className={cn(
                "rounded-3xl w-[164px] border-black hover:border-none bg-cream-300 text-black",
                formAction === "edit" && "hidden"
              )}
              disabled={selectMode === "propose"}
              onClick={() => startProposeMode()}
            >
              Propose times
            </Button>
          )}
        </div>
      </div>

      <div
        className={cn("space-y-2", step === Step.Client ? "block" : "hidden")}
      >
        <div className="flex items-center gap-2 sm:gap-4">
          <FormField
            name="personal"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InlineCheckbox
                    {...field}
                    label={"Personal"}
                    checked={field.value}
                    onCheckedChange={(value) => {
                      // If checking "Personal" and a client is set, show confirmation
                      if (value && getValues("clientId")) {
                        // Ask for confirmation to remove client
                        setShowConfirmDialog(true)
                      } else {
                        // Regular behavior for unchecking or when no client
                        field.onChange(value)
                        if (value) {
                          setValue("clientId", "")
                          setValue("clientName", "")
                        } else {
                          // Open select client modal
                          setShowClientModal(true)
                        }
                        // Focus on title input
                        setTimeout(() => setFocus("title"), 0)
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="clientId"
            control={control}
            render={({ field }) => (
              <FormItem>
                <ClientModal
                  sessionId={"__sessionId__"}
                  isOpen={showClientModal}
                  onOpenChange={setShowClientModal}
                  onClientSet={(clientId, client) => {
                    field.onChange(clientId)
                    setValue("clientName", client?.name ?? "")
                    setValue("title", client?.name ?? "")
                    setValue("personal", false)

                    // Focus on title input
                    setTimeout(() => setFocus("title"), 0)
                  }}
                />

                <Button
                  size="sm"
                  variant="secondary"
                  className="px-5"
                  onClick={(event) => {
                    event.stopPropagation()
                    setShowClientModal(true)
                  }}
                >
                  <p className="text-sm font-medium leading-none overflow-hidden text-ellipsis">
                    {field.value ? "Change client" : "Set client"}
                  </p>
                </Button>
              </FormItem>
            )}
          />

          {getValues("clientId") && (
            <>
              <Separator
                orientation="vertical"
                className="w-[1.5px] h-8 bg-[#8C867C]"
              />

              <div className="flex flex-col gap-1">
                <p className="text-sm font-medium leading-none">Client</p>
                <p className="text-sm leading-none max-w-[120px] sm:max-w-[450px] overflow-hidden text-ellipsis">
                  {getValues("clientName")}
                </p>
              </div>
            </>
          )}
        </div>

        <BaseControl
          name="title"
          label="Title"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Add title"
              className="border-none"
            />
          )}
        />

        <BaseControl
          name="description"
          label="Description"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Add description"
              className="border-none"
            />
          )}
        />

        <div className="flex flex-row w-full justify-between pt-4">
          <Button
            variant="ghost"
            className="rounded-3xl pl-0"
            onClick={() => setStep(Step.Setup)}
          >
            <ChevronLeft className="size-4" />
            Back
          </Button>

          <div className="flex flex-row item-center justify-center gap-2 sm:gap-4">
            {repeatOptionSet ? (
              <p className="flex flex-col h-full">
                <span className="font-bold capitalize w-full text-xs truncate text-start">
                  {repeatOptions?.at(repeatValue ?? 0)?.text}
                  {repeatOptionText}
                </span>
                <span className="capitalize w-full text-sm truncate text-start">
                  Starting {start?.toLocaleString(DateTime.DATE_MED)}
                </span>
                <span className="w-full text-sm truncate text-start">
                  Repeats {getValues("repeatCount")}
                  {" times"}
                </span>
              </p>
            ) : (
              <p className="flex flex-col h-full">
                <span className="font-bold capitalize w-full text-xs truncate text-start">
                  {start?.toLocaleString({
                    month: "short",
                    day: "2-digit",
                    weekday: "short",
                  })}
                </span>
                <span className="capitalize w-full text-sm truncate text-start">
                  {start
                    ?.set({ minutes })
                    ?.toLocaleString(DateTime.TIME_SIMPLE)}{" "}
                  {start
                    ?.set({ minutes })
                    ?.plus(Duration.fromObject({ minutes: duration }))
                    .toLocaleString(DateTime.TIME_SIMPLE)}
                </span>
              </p>
            )}
            <SubmitButton
              variant={"secondary"}
              className="rounded-3xl w-[130px] sm:w-[164px] self-center"
              onClick={handleSubmit(onSubmit)}
              isSubmitting={formState.isSubmitting}
            >
              {formAction === "create" ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  )
}
