import * as React from "react"
import type { StorageReference } from "@firebase/storage"
import { Trash2Icon } from "lucide-react"
import JPGIcon from "~/assets/icons/jpg.svg?react"
import PDFIcon from "~/assets/icons/pdf.svg?react"
import { Button, ConfirmationModal, toast } from "~/components/ui"
import { useDeleteAttachment } from "~/hooks/firestore/useClientAttachments"
import {
  AttachmentPreviewModal,
  ImagePreviewLightbox,
} from "~/pages/Notes/AttachmentPreviewModal"

const truncateFileName = (fileName: string) => {
  if (fileName?.length < 24) return fileName
  return `${fileName.slice(0, 12)}...${fileName.slice(-10)}`
}

export function ClientAttachments({ clientId, attachments }) {
  const [previewAttachment, setPreviewAttachment] =
    React.useState<StorageReference | null>(null)

  const [attachmentLightbox, setAttachmentLightbox] =
    React.useState<StorageReference | null>(null)

  const deleteAttachment = useDeleteAttachment({
    onSettled: (_, error) => {
      if (error) {
        toast.error(error?.message ?? "Failed to delete attachment")
      } else {
        toast.success("Attachment deleted successfully")
      }
    },
  })

  if (!attachments?.length) {
    return null
  }

  return (
    <>
      <ul className="flex flex-wrap gap-4 items-center">
        {attachments.map(({ ref: attachment, originalName, id }) => (
          <li
            key={id}
            className="shrink-0 lg:min-w-[250px] max-w-[250px] bg-white rounded-md flex items-center justify-between gap-1.5 p-1 leading-[1]"
          >
            <div className="pl-1.5 pr-0.5">
              {(originalName ?? attachment.name ?? "").match(/\.(pdf)$/) ? (
                <PDFIcon className="size-5 inline-block" />
              ) : (
                <JPGIcon className="size-5 inline-block" />
              )}
            </div>

            <p
              className="flex-1 cursor-pointer hover:underline truncate"
              onClick={(e) => {
                e.preventDefault()
                const name = originalName ?? attachment.name ?? ""

                if (name && name.match(/\.(pdf)$/)) {
                  setPreviewAttachment(attachment)
                } else {
                  setAttachmentLightbox(attachment)
                }
              }}
            >
              <span className="text-sm font-normal">
                {truncateFileName(originalName) ||
                  truncateFileName(attachment.name) ||
                  "attachment.pdf"}
              </span>
            </p>

            <ConfirmationModal
              onConfirm={() => {
                deleteAttachment.mutate({
                  clientId,
                  attachmentId: attachment.name,
                })
              }}
            >
              <Button
                size="icon"
                variant="ghost"
                className="shrink-0 p-1 size-8 rounded-md text-muted-foreground hover:text-primary hover:bg-primary/10"
                disabled={deleteAttachment.isPending}
              >
                <Trash2Icon className="size-4" />
              </Button>
            </ConfirmationModal>
          </li>
        ))}
      </ul>

      {attachmentLightbox ? (
        <ImagePreviewLightbox
          attachment={attachmentLightbox}
          onClose={() => setAttachmentLightbox(null)}
        />
      ) : null}

      {previewAttachment ? (
        <AttachmentPreviewModal
          open={!!previewAttachment}
          onOpenChange={(open) => {
            if (!open) {
              setPreviewAttachment(null)
            }
          }}
          attachment={previewAttachment}
          originalFileName={
            attachments?.find((p) => p.id === previewAttachment.fullPath)
              ?.originalName
          }
        />
      ) : null}
    </>
  )
}
