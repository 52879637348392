import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Timestamp } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { useAnalytics } from "use-analytics"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { functions } from "~/services/firebase"

export type CreateProposalRequest = {
  isoStartDateTimes: string[]
  isoDuration: string
  isoExpiresAt: string
  clientId: string
}

export type StoredProposal = {
  id: string
  timeSlots: any[]
  isoDuration: string
  userId: string
  createdAt?: Timestamp
  expiresAt?: Timestamp
  deleteAfter?: Timestamp
  rejectedAt?: Timestamp
  acceptedSessionId?: string
}

const asTimestamp = (obj: any) => {
  if (typeof obj !== "object" && !obj?._seconds) return undefined
  return new Timestamp(obj._seconds, obj?._nanoseconds || 0)
}

export function useCreateProposal(
  options?: MutationOptions<CreateProposalRequest>
) {
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async (data) => {
      const createProposal = httpsCallable(functions, "createProposal")

      return await createProposal(data).then((result) => result.data)
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)

      if (!error) {
        void track("Proposals Created")
      }
    },
  })
}

export function useDeleteProposal(
  options?: MutationOptions<{
    proposalId: string
  }>
) {
  const queryClient = useQueryClient()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ proposalId }) => {
      const deleteProposal = httpsCallable(functions, "deleteProposal")

      await deleteProposal({ proposalId })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)

      void queryClient.invalidateQueries({ queryKey: ["SESSIONS"] })
      void queryClient.invalidateQueries({ queryKey: ["PROPOSALS"] })

      if (!error) {
        void track("Proposals Deleted")
      }
    },
  })
}

export function useProposalById({
  proposalId,
  reactQuery,
}: QueryParameter<StoredProposal> & {
  proposalId: string
}) {
  return useQuery({
    ...reactQuery,
    queryKey: ["PROPOSALS", proposalId],
    enabled: !!proposalId,
    queryFn: async () => {
      const getProposalById = httpsCallable(functions, "getProposalById")

      const response = await getProposalById({ proposalId }).then(
        (result) => (result.data as any)?.proposal ?? null
      )

      return {
        ...response,
        createdAt: asTimestamp(response?.createdAt),
        expiresAt: asTimestamp(response?.expiresAt),
        deleteAfter: asTimestamp(response?.deleteAfter),
        rejectedAt: asTimestamp(response?.rejectedAt),
      } as StoredProposal
    },
  })
}
