import * as React from "react"
import { HouseIcon } from "lucide-react"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"
import { cn } from "~/components/ui/utils"

const HomeButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>((props, ref) => {
  const navigate = useNavigate()
  const { track } = useAnalytics()

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          ref={ref}
          size="icon"
          variant="ghost"
          {...props}
          className={cn(
            "hover:bg-[#ADDEBA] hover:bg-opacity-60",
            props.className
          )}
          onClick={(e) => {
            props.onClick?.(e)

            void navigate("/home")
            void track("MainDrawer Navigate_to_home")
          }}
        >
          <HouseIcon className="size-6" />
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right">Home</TooltipContent>
    </Tooltip>
  )
})
HomeButton.displayName = "HomeButton"

export { HomeButton }
