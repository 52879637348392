import React from "react"
import * as Sentry from "@sentry/browser"
import { Duration } from "luxon"
import { useFormContext } from "react-hook-form"
import { toast } from "~/components/ui"
import { WeeklyFooter } from "~/components/weekly-calendar"
import { sentryDefaultTags } from "~/config/instrument"
import { useAuth } from "~/context/AuthContext"
import { useAddSession, type Session } from "~/hooks/firestore/useSessions"
import { BookingControls } from "./BookingControls"
import { useCalendarStore } from "./store"
import { INITIAL_VALUES, type FromSchemaValues } from "./utils"

export function NewBooking() {
  const { reset } = useFormContext()
  const { currentUser } = useAuth()

  const { selectMode, selectedSlot, setSelectedSlot } = useCalendarStore(
    (state) => ({
      selectMode: state.selectMode,
      selectedSlot: state.selectedSlot,
      setSelectedSlot: state.setSelectedSlot,
    })
  )

  const close = () => {
    reset({ ...INITIAL_VALUES }) // reset the entries form when closed
    setSelectedSlot(null)
  }

  const addSession = useAddSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Could not save the calendar event. Please try again.")
      } else {
        close()
      }
    },
  })

  const handleSubmit = async (values: FromSchemaValues) => {
    if (!selectedSlot) return

    if (values.repeatFrequency || values.repeatInterval) {
      if (!values.repeatCount || values.repeatInterval === undefined) {
        toast.error(
          "Could not create repeating sessions. Please try again or contact support."
        )
        Sentry.captureMessage(
          `Could not create repeating session. Missing repeatCount or repeatInterval`,
          {
            level: "info",
            user: { id: currentUser?.uid },
            tags: { ...sentryDefaultTags },
            extra: {
              repeatCount: values.repeatCount,
              repeatInterval: values.repeatInterval,
              repeatFrequency: values.repeatFrequency,
              selectedSlot: selectedSlot.toISO(),
            },
          }
        )
        return
      }

      const repeatID = crypto.randomUUID()

      // Create repeating sessions
      for (let i = 0; i < values.repeatCount; i++) {
        const start = selectedSlot.plus({ weeks: i * values.repeatInterval })
        const session: Session = {
          id: crypto.randomUUID(),
          start: start.set({ minute: values.start || 0 }),
          duration: Duration.fromObject({ minutes: values.duration || 60 }),
          title: values.title || "",
          description: values.description || "",
          clientId: values.clientId || undefined,
          clientName: values.clientName || undefined,
          personal: values.personal,
          repeatId: repeatID,
          repeatFrequency: values.repeatFrequency,
          repeatInterval: values.repeatInterval,
          repeatCount: values.repeatCount,
        }
        await addSession.mutateAsync({ session })
      }
    } else {
      const session: Session = {
        id: crypto.randomUUID(),
        start: selectedSlot.set({ minute: values.start || 0 }),
        duration: Duration.fromObject({ minutes: values.duration || 60 }),
        title: values.title || "",
        description: values.description || "",
        clientId: values.clientId || undefined,
        clientName: values.clientName || undefined,
        personal: values.personal,
      }

      await addSession.mutateAsync({ session })
    }
  }

  if (!selectedSlot || selectMode !== "event") {
    return null
  }

  return (
    <WeeklyFooter
      title=""
      open={!!selectedSlot}
      onOpenChange={(open) => {
        if (!open) {
          close()
        }
      }}
    >
      <BookingControls
        start={selectedSlot}
        onSubmit={handleSubmit}
      />
    </WeeklyFooter>
  )
}
