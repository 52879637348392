import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import {
  DialogHeader,
  DialogTitle,
  Drawer,
  DrawerContent,
  DrawerHandle,
  DrawerHeader,
  DrawerTitle,
  useMediaQuery,
} from "~/components/ui"

export type WeeklyFooterProps = {
  title?: React.ReactNode
  header?: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function WeeklyFooter({
  title,
  header,
  children,
  open,
  onOpenChange,
}: React.PropsWithChildren<WeeklyFooterProps>) {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  if (isDesktop) {
    return (
      <DialogPrimitive.Root
        modal={false}
        open={open}
        onOpenChange={onOpenChange}
      >
        <>
          <DialogPrimitive.Content
            aria-describedby=""
            className="relative min-h-[370px] pt-3 pb-4 overflow-auto"
            onPointerDownOutside={(e) => {
              e.preventDefault()
            }}
            onInteractOutside={(e) => {
              e.preventDefault()
            }}
          >
            <div className="relative w-[600px] mx-auto focus:outline-0 px-4">
              <DialogPrimitive.Close className="absolute right-0 top-0 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-1 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                <X className="size-6" />
                <span className="sr-only">Close</span>
              </DialogPrimitive.Close>

              {header ? (
                <>
                  <DialogTitle className="sr-only">{title}</DialogTitle>
                  {header}
                </>
              ) : null}

              {!header ? (
                <DialogHeader className="mb-6">
                  <DialogTitle className="font-platypi md:text-2xl">
                    {title}
                  </DialogTitle>
                </DialogHeader>
              ) : null}

              {children}
            </div>
          </DialogPrimitive.Content>
        </>
      </DialogPrimitive.Root>
    )
  }

  return (
    <Drawer
      handleOnly
      open={open}
      modal={false}
      onOpenChange={onOpenChange}
      disablePreventScroll={false}
      shouldScaleBackground={false}
    >
      <DrawerContent
        indicator={false}
        aria-describedby=""
        className="bg-primary-cream-300 border-0 min-h-[60%] z-[40]"
      >
        <DrawerHandle className="mx-auto mt-4 mb-2 !h-2 !w-[100px] rounded-full !bg-[#eedecd] !opacity-1" />

        {header ? (
          <>
            <DrawerTitle className="sr-only">{title}</DrawerTitle>
            {header}
          </>
        ) : null}

        {!header ? (
          <DrawerHeader className="text-left">
            <DrawerTitle className="font-platypi text-2xl">{title}</DrawerTitle>
          </DrawerHeader>
        ) : null}

        <div className="px-4">{children}</div>
      </DrawerContent>
    </Drawer>
  )
}
