import React, { useCallback, useEffect, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Textarea,
} from "../ui"

const MAX_HEIGHT_PX = 200

export const ChatInputFormSchema = z.object({
  message: z.string().max(800, {
    message: "Your message is too long.",
  }),
})

export interface ChatInputFormProps {
  onSubmit: (data: z.infer<typeof ChatInputFormSchema>) => Promise<void>
  placeholder?: string
}

export const ChatInputForm = ({
  onSubmit,
  placeholder = "Send a message",
}: ChatInputFormProps) => {
  const form = useForm<z.infer<typeof ChatInputFormSchema>>({
    resolver: zodResolver(ChatInputFormSchema),
    defaultValues: { message: "" }, // Keep defaultValues
  })

  const [textareaNode, setTextareaNode] = useState<HTMLTextAreaElement | null>(
    null
  )
  const [isFocused, setIsFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //Use reset with a callback
  const resetForm = useCallback(() => {
    form.reset()

    if (textareaNode) {
      textareaNode.style.height = "40px"
      textareaNode.style.overflowY = "hidden"
      textareaNode.value = "" // Force the value change on DOM
    }
  }, [form, textareaNode])

  // Handle keydown events on the textarea
  // Submits the form when Enter is pressed without Shift
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      // Check for Enter key press without the Shift key to avoid newline insertion
      if (e.key === "Enter" && !e.shiftKey && !isLoading) {
        e.preventDefault()

        const message = form.getValues("message")
        if (!message) return

        resetForm()
        setIsLoading(true)
        void onSubmit({ message }).finally(() => setIsLoading(false))
      }
    },
    [form, isLoading, onSubmit, resetForm]
  )

  // Update textarea styling and form value dynamically as the user types
  // Let the text area grow to MAX_HEIGHT_PX and enable scrolling if larger
  const handleInput = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const target = e.target as HTMLTextAreaElement

      // Update the form state with the current value of the textarea
      form.setValue("message", target.value)

      // Reset the height to allow dynamic recalculation of scrollHeight
      target.style.height = "auto"

      // Compute the new height, capping it at MAX_HEIGHT_PX
      const newHeight = Math.min(target.scrollHeight, MAX_HEIGHT_PX)
      target.style.height = newHeight + "px"

      // Adjust vertical overflow: enable scrolling if content exceeds max height
      target.style.overflowY =
        target.scrollHeight > MAX_HEIGHT_PX ? "auto" : "hidden"
    },
    [form]
  )

  // Automatically focus the textarea when it becomes available and hasn't been focused already.
  // This ensures that the user can start typing immediately without having to click into the input.
  useEffect(() => {
    if (textareaNode && !isFocused) {
      // Set focus on the textarea element.
      textareaNode.focus()
      // Update state to indicate that the textarea has been focused.
      setIsFocused(true)
    }
  }, [textareaNode, isFocused])

  // Focus the textarea when the container is clicked, unless the click was on an interactive element.
  const handleContainerClick = useCallback(
    (e: React.MouseEvent) => {
      const tag = (e.target as HTMLElement).tagName.toLowerCase()
      if (["textarea", "input", "button"].includes(tag)) return
      textareaNode?.focus()
    },
    [textareaNode]
  )

  const handleSubmit = (data: z.infer<typeof ChatInputFormSchema>) => {
    resetForm()
    setIsLoading(true)
    return onSubmit(data).finally(() => setIsLoading(false))
  }

  return (
    <Form
      id="modal-form"
      form={form}
      className="flex flex-col w-[90%] space-y bg-white p-2 rounded-lg shadow-md hover:cursor-text mx-auto border border-gray-100"
      onClick={handleContainerClick}
    >
      <form
        className="flex flex-col w-full space-y-2"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  rows={1}
                  placeholder={placeholder}
                  className="resize-none h-0 min-h-[40px] border-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                  style={{
                    maxHeight: MAX_HEIGHT_PX + "px",
                    overflowY: "hidden",
                  }}
                  onInput={handleInput}
                  onKeyDown={handleKeyDown}
                  {...field}
                  ref={(node) => {
                    field.ref(node)
                    setTextareaNode(node)
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="self-end mt-1"
          disabled={isLoading}
        >
          Send
        </Button>
      </form>
    </Form>
  )
}
