import { forwardRef, useEffect, useMemo, useReducer, useState } from "react"
import { Timestamp } from "@firebase/firestore"
import * as Sentry from "@sentry/browser"
import { useQueries } from "@tanstack/react-query"
import { Crisp } from "crisp-sdk-web"
import { MenuIcon } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import { LLMChatModal } from "~/components/llmChat/LLMChatModal"
import MainDrawer from "~/components/MainDrawer"
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useMediaQuery,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import {
  toWeekString,
  useSessions,
  useUpdateSession,
  type Session,
} from "~/hooks/firestore/useSessions"
import { fetchNotesBySessionId } from "~/hooks/useNotes"
import HomeHeader from "./HomeHeader"
import HomeNavButtons from "./HomeNavButtons"
import { NoteReminderCard } from "./NoteReminderCard"
import { PrepSessionCard } from "./PrepSessionCard"

const TALL_SCREEN = "(min-height: 850px)"

const MenuButton = forwardRef<HTMLButtonElement>((props, ref) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          ref={ref}
          size="icon"
          variant="ghost"
          className="hover:bg-[#ADDEBA] hover:bg-opacity-60 text-white hover:text-white"
          {...props}
        >
          <MenuIcon className="size-8" />
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right">Menu</TooltipContent>
    </Tooltip>
  )
})
MenuButton.displayName = "MenuButton"

export function Home() {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { track } = useAnalytics()

  const [, forceRerender] = useReducer((x) => x + 1, 0)
  const isTallScreen = useMediaQuery(TALL_SCREEN)

  const sessionsQuery = useSessions({
    weekString: toWeekString(DateTime.local()),
  })

  const updateSession = useUpdateSession({
    onSettled: (_, error) => {
      if (error) {
        // Log error quietly
        Sentry.captureException(error)
      }
    },
  })

  const sessions = useMemo(() => sessionsQuery.data || [], [sessionsQuery.data])
  const [showTalkToJoy, setShowTalkToJoy] = useState(false)

  // Capture unhandled promise rejections
  useEffect(() => {
    const handler = (event: PromiseRejectionEvent) => {
      Sentry.captureException(event.reason)
    }
    window.addEventListener("unhandledrejection", handler)
    return () => window.removeEventListener("unhandledrejection", handler)
  }, [])

  // Filter sessions for today using useMemo
  const sessionsToday = useMemo(() => {
    if (!sessions) {
      return []
    }
    const today = DateTime.now().startOf("day")
    const eod = today.endOf("day")
    return sessions
      .filter((session) => {
        const hasProposal = !!session.proposalId
        const isAccepted = !!session.acceptedAt
        const proposalCheck = !hasProposal || (hasProposal && isAccepted)
        return session.start > today && session.start < eod && proposalCheck
      })
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessions])

  // Fetch notes for all sessions today
  const noteQueries = useQueries({
    queries: sessionsToday.map((session) => ({
      queryKey: ["notes", session.id],
      queryFn: () =>
        fetchNotesBySessionId({
          sessionId: session.id,
          userId: currentUser?.uid,
        }),
      enabled: !!session.id,
    })),
  })

  // Combine sessions today with their notes
  const sessionsWithNotes = useMemo(() => {
    return sessionsToday.map((session, index) => {
      const noteQuery = noteQueries[index]

      if (noteQuery.isSuccess && noteQuery.data?.length > 0) {
        return {
          ...session,
          noteIds: noteQuery.data.map((note) => note.id),
        }
      }

      return session
    })
  }, [sessionsToday, noteQueries])

  // Filter remaining sessions for today, including any ongoing session
  const remainingSessions = useMemo(() => {
    if (!sessionsToday) {
      return []
    }
    const now = DateTime.now()
    return sessionsToday
      .filter((session) => {
        return now < session.start.plus(session.duration)
      })
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessionsToday])

  // Get active session reminders
  const activeSessionReminders = useMemo(() => {
    if (!sessionsWithNotes) {
      return []
    }
    const now = DateTime.now()
    return sessionsWithNotes
      .filter((session) => {
        // Show reminder if session is 75% complete, has no notes, and has not been dismissed
        const partialDuration = Duration.fromMillis(
          session.duration.as("milliseconds") * 0.75
        )
        return (
          now > session.start.plus(partialDuration) &&
          (session.noteIds?.length ?? 0) === 0 &&
          !session.recordingDismissed &&
          session.clientId
        )
      })
      .sort((a, b) => b.start.toMillis() - a.start.toMillis())
      .slice(0, isTallScreen ? 3 : 1)
  }, [sessionsWithNotes, isTallScreen])

  const handleNoteReminderDismissed = async (session: Session) => {
    try {
      void track("Home Dismiss_recording_reminder")
      const updatedSession = { ...session, recordingDismissed: true }
      await updateSession.mutateAsync({
        sessionId: session.id,
        session: updatedSession,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleRecordNoteForSession = (session: Session) => {
    void track(`Home Record_from_reminder`)
    const sessionStart = session?.start.toJSDate()
    const state = {
      clientId: session?.clientId,
      clientName: session?.clientName,
      title: session?.title,
      sessionId: session?.id,
      sessionStart: sessionStart ? Timestamp.fromDate(sessionStart) : undefined,
    }
    void navigate("/recorder", { state })
  }

  // Update every minute to reflect the current time
  useEffect(() => {
    const interval = setInterval(forceRerender, 60000)
    return () => clearInterval(interval)
  }, [sessionsQuery])

  // Capture query errors
  useEffect(() => {
    if (sessionsQuery.error) {
      Sentry.captureException(sessionsQuery.error)
    }
  }, [sessionsQuery.error])

  useEffect(() => {
    const closeAndHideChat = () => {
      try {
        if (Crisp.chat.isChatOpened()) {
          Crisp.chat.close()
        }
        if (Crisp.chat.isVisible()) {
          Crisp.chat.hide()
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // Set a timeout to close the chat after 2 seconds
    const timeout = setTimeout(() => {
      closeAndHideChat()
    }, 2000)

    // Close and hide chat on component mount
    closeAndHideChat()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleTalkToJoy = () => {
    void track("Home Open_chat_with_assistants")
    setShowTalkToJoy(true)
  }

  const minutesToNextSession =
    remainingSessions.length > 0
      ? Math.round(remainingSessions[0].start.diffNow("minutes").minutes)
      : 0

  const isSessionOngoing =
    remainingSessions.length > 0 && minutesToNextSession < 0

  return (
    <section className="h-full overflow-hidden bg-cover bg-center bg-no-repeat bg-img-forest">
      <LLMChatModal
        isOpen={showTalkToJoy}
        onOpenChange={setShowTalkToJoy}
      />

      <div className="container px-3 h-full overflow-auto grid grid-cols-1 xl:grid-cols-2 mx-auto no-scrollbar">
        <div className="h-full w-full flex flex-col justify-between">
          <div className="w-full flex flex-col sm:mt-4">
            <div className="py-4 md:pt-0">
              <MainDrawer>
                <MenuButton />
              </MainDrawer>
            </div>

            <HomeHeader
              remainingSessions={remainingSessions}
              minutesToNextSession={minutesToNextSession}
              isSessionOngoing={isSessionOngoing}
              sessionsToday={sessionsToday}
            />
          </div>

          <div className="space-y-3 mb-4 sm:mb-8 md:mb-12">
            {remainingSessions.length > 0 && (
              <PrepSessionCard session={remainingSessions[0]} />
            )}

            {activeSessionReminders.map((session) => (
              <NoteReminderCard
                key={session.id}
                session={session}
                onSessionDismissed={() => handleNoteReminderDismissed(session)}
                onRecordNote={() => handleRecordNoteForSession(session)}
              />
            ))}

            <HomeNavButtons />

            <Button
              variant="ghost"
              className={`overflow-hidden w-full sm:w-[412px] bg-slate-200 rounded-[1rem] shadow-box-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2
                  justify-start items-center text-slate-400`}
              onClick={handleTalkToJoy}
            >
              <div
                className={
                  "w-14 h-14 ml-[-24px] mb-2 bg-[url(/img/milo-thumb.png)] bg-cover bg-center transition-all duration-150 ease-in-out"
                }
              />
              Chat with your assistant
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
