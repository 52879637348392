import { useEffect, useRef, useState } from "react"
import { captureMessage } from "@sentry/browser"
import { sentryDefaultTags } from "~/config/instrument"
import { storage } from "~/services/firebase"
import { PersistentRecording } from "~/utils/recordings/protocol"
import { SyncLocalRemoteRecording } from "~/utils/recordings/sync"
import useTimer from "./useTimer"

const RECORDING_CHUNK_LENGTH_MS = (import.meta.env
  .VITE_JOY_RECORDING_CHUNK_LENGTH ?? 30000) as number

interface UseAudioRecorderResult {
  mediaRecorder: MediaRecorder | null
  mimeType: string | undefined
  recording: boolean
  paused: boolean
  recordingComplete: boolean
  recordedChunks: Blob[]
  error: string | null
  time: number
  frequencyData: number[]
  startRecording: (userId: string, recordingId: string) => Promise<void>
  pauseRecording: () => void
  resumeRecording: () => void
  stopRecording: () => void
  clearRecording: () => void
  saveRecordingToLocalDevice: (fileName: string) => void
  recordingStorage: PersistentRecording | null
}

const useAudioRecorder = (): UseAudioRecorderResult => {
  const [mimeType, setMimeType] = useState<string | undefined>(undefined)
  const [recording, setRecording] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [recordingComplete, setRecordingComplete] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [frequencyData, setFrequencyData] = useState<number[]>([])

  const frequencyDataRef = useRef<number[]>([])
  const intervalIdRef = useRef<number | null>(null)

  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioContextRef = useRef<AudioContext | null>(null)
  const recordedChunksRef = useRef<Blob[]>([])
  const recordingStorageRef = useRef<PersistentRecording | null>(null)

  const { time, resetTimer, startTimer, stopTimer } = useTimer()

  const FREQUENCY_UPDATE_INTERVAL = 100
  const DATA_ARRAY_SIZE = 1024
  const MAX_FREQUENCY = 20 // Used to create dummy data

  const initializeAudioAnalysis = (stream: MediaStream) => {
    try {
      audioContextRef.current = new AudioContext()
      if (!audioContextRef.current) {
        throw new Error("AudioContext not supported!")
      }
      const analyser = audioContextRef.current.createAnalyser()
      const source = audioContextRef.current.createMediaStreamSource(stream)

      if (!analyser || !source) {
        throw new Error("Could not create analyser or source")
      }

      source.connect(analyser)

      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      intervalIdRef.current = window.setInterval(
        () => updateFrequencyData(analyser, dataArray),
        FREQUENCY_UPDATE_INTERVAL
      )
    } catch (error) {
      console.debug("Error initializing audio context: " + error)
      const dataArray = new Uint8Array(DATA_ARRAY_SIZE)
      intervalIdRef.current = window.setInterval(
        () => updateFrequencyDataWithFallback(dataArray),
        FREQUENCY_UPDATE_INTERVAL
      )
    }
  }

  const updateFrequencyData = (
    analyser: AnalyserNode,
    dataArray: Uint8Array
  ) => {
    analyser.getByteFrequencyData(dataArray)
    frequencyDataRef.current = Array.from(dataArray)
    setFrequencyData(frequencyDataRef.current)
  }

  const updateFrequencyDataWithFallback = (dataArray: Uint8Array) => {
    for (let i = 0; i < dataArray.length; i++) {
      const fadeFactor = 4 * Math.sin((Math.PI * i) / dataArray.length)
      dataArray[i] =
        50 + Math.floor(Math.random() * MAX_FREQUENCY * (1 - fadeFactor))
    }
    frequencyDataRef.current = Array.from(dataArray)
    setFrequencyData(frequencyDataRef.current)
  }

  // Get the first supported media type of provived types.
  const getSupportedMediaType = (mediaTypes: string[]) => {
    if (MediaRecorder.isTypeSupported === undefined) {
      // iPhone 7 safari does not support MediaRecorder.isTypeSupported but does support "audio/mpeg"
      console.error(
        "MediaRecorder.isTypeSupported is undefined. Returning audio/mpeg as supported type."
      )
      return "audio/mpeg"
    }
    const supportedTypes = mediaTypes.filter(MediaRecorder.isTypeSupported)
    return supportedTypes.length === 0 ? undefined : supportedTypes[0]
  }

  // Initialize the MediaRecorder used for Audio-only in our case.
  async function initAudioRecorder(userId: string, recordingId: string) {
    try {
      const mimeType = getSupportedMediaType([
        "audio/webm",
        "audio/ogg",
        "audio/mp4",
        "audio/mpeg",
        "audio/wav",
      ])

      if (mimeType === undefined) {
        setError("No supported media type available for recording.")
        captureMessage(`Recorder failed to close recording`, {
          level: "error",
          tags: {
            ...sentryDefaultTags,
            recording_function: "initAudioRecorder",
            recording_id: recordingId,
            recording_error: "No supported media type",
          },
        })
        return
      }

      setMimeType(mimeType)

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: mimeType,
      })

      // Create the storage for the recording
      recordingStorageRef.current = new SyncLocalRemoteRecording(
        storage,
        userId,
        recordingId,
        mimeType
      )

      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        // ignore empty data events, they don't need to be stored
        if (event.data.size == 0) {
          return
        }

        // don't wait on the write here, it will occur in-order of the
        // streamed audio chunks
        void recordingStorageRef?.current
          ?.write(event.data, {
            audioBitsPerSecond: mediaRecorderRef.current?.audioBitsPerSecond,
          })
          .catch((err: unknown) => {
            if (err instanceof Error) {
              console.warn(`Recording failed to write`, err)

              captureMessage(`Recorder failed to write recording`, {
                level: "warning",
                tags: {
                  ...sentryDefaultTags,
                  recording_function: "recorder_ondataavailable",
                  recording_id: recordingId,
                  recording_error: err.message,
                  recording_state: mediaRecorderRef.current?.state,
                },
              })
            }
          })

        recordedChunksRef.current.push(event.data)
      }

      mediaRecorderRef.current.onerror = (event: Event) => {
        setError(`MediaRecorder Error: ${(event as ErrorEvent).error}`)
      }

      mediaRecorderRef.current.onstop = (_event: Event) => {
        void recordingStorageRef?.current
          ?.close()
          .then(() => {
            setRecordingComplete(true)
          })
          .catch((err: unknown) => {
            if (err instanceof Error) {
              console.warn(`Recording failed to close`, err)

              captureMessage(`Recorder failed to close recording`, {
                level: "warning",
                tags: {
                  ...sentryDefaultTags,
                  recording_function: "recorder_ondataavailable",
                  recording_id: recordingId,
                  recording_error: err.message,
                  recording_state: mediaRecorderRef.current?.state,
                },
              })
            }
          })
      }

      mediaRecorderRef.current.onpause = () => {
        setPaused(true)
        stopTimer()
      }

      mediaRecorderRef.current.onresume = () => {
        setPaused(false)
        startTimer()
      }

      initializeAudioAnalysis(stream)
    } catch (err) {
      setError(`Error initializing MediaRecorder: ${err}`)
    }
  }

  // Initialize MediaRecorder on mount.
  useEffect(() => {
    // Error handling for unsupported browsers.
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError("Media Devices or User Media not supported in your browser.")
      return
    }

    // Clean up on unmount.
    return () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        stopRecorderAndTracks(mediaRecorderRef.current!)
      }

      if (audioContextRef.current) {
        void audioContextRef.current.close()
      }
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [])

  const stopRecorderAndTracks = (recorder: MediaRecorder) => {
    // NOTE: Always stop the mediarecorder first before stopping
    // all the tracks, otherwise two onstop events are fired in
    // webkit based browsers.
    // https://w3c.github.io/mediacapture-main/#source-stopped
    // This is possibly a bug in webkit or at least different
    // behaviour compared to chromium-based browsers.
    recorder.stop()

    recorder.stream.getTracks().forEach((track) => {
      track.stop()
      track.enabled = false
    })
  }

  // Handler functions for recording controls.
  const startRecording = async (userId: string, recordingId: string) => {
    await initAudioRecorder(userId, recordingId)
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "inactive"
    ) {
      recordedChunksRef.current = []
      mediaRecorderRef.current.start(RECORDING_CHUNK_LENGTH_MS)
      setRecording(true)
      resetTimer()
      startTimer()
    }
  }

  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause()
    }
  }

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume()
    }
  }

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      stopRecorderAndTracks(mediaRecorderRef.current!)
      setRecording(false)
      stopTimer()
    }
  }

  const clearRecording = () => {
    // Clear the recorded data chunks.
    recordedChunksRef.current = []
  }

  /**
   * Downloads the recorded audio as a file with the provided name.
   * If no name is provided, the file will be named 'recording'.
   *
   * @param fileName - The name of the file to download.
   * @return {void}
   */
  const saveRecordingToLocalDevice = (fileName: string = "recording") => {
    if (!recordedChunksRef.current) {
      console.error("No recorded chunks available for download.")
      return
    }
    const mediaMimeType = mediaRecorderRef.current?.mimeType ?? mimeType
    const contentType = mediaMimeType?.trim() ?? "unknownMimeType"
    const audioBlob: Blob = new Blob(recordedChunksRef.current, {
      type: contentType,
    })

    const link = document.createElement("a")
    link.href = URL.createObjectURL(audioBlob)
    link.download = fileName
    link.addEventListener(
      "click",
      () => {
        // Remove the link after a fixed delay.
        // Without this timeout, Chrome will not download the file.
        setTimeout(() => {
          URL.revokeObjectURL(link.href)
        }, 5000)
      },
      { once: true }
    )
    link.dispatchEvent(new MouseEvent("click"))
  }

  // Return the state and handlers.
  return {
    mimeType,
    mediaRecorder: mediaRecorderRef.current,
    recording,
    paused,
    recordingComplete,
    recordedChunks: recordedChunksRef.current,
    error,
    time,
    frequencyData,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    clearRecording,
    saveRecordingToLocalDevice,
    recordingStorage: recordingStorageRef.current,
  }
}

export default useAudioRecorder
