import typography from "@tailwindcss/typography";


/** @type {import("tailwindcss").Config} */
export const content = ["./src/**/*.{js,ts,jsx,tsx}"]
export const darkMode = "false"
export const theme = {
  extend: {
    spacing: {
      "safe-bottom": "env(safe-area-inset-bottom)",
    },
    backdropBlur: {
      "md-plus": "14px",
    },
    lineHeight: {
      "5-plus": "1.375rem",
    },
    borderRadius: {
      "2xl-plus": "1.125rem",
      "lg-plus": "0.625rem",
      // shadcn
      lg: `var(--radius)`,
      md: `calc(var(--radius) - 3px)`,
      sm: "calc(var(--radius) - 6px)",
    },
    fontFamily: {
      platypi: ["Platypi", "sans-serif"],
      sans: ["Inter", "SF Pro", "sans-serif"],
      deco: ["SF Pro", "sans-serif"],
      mono: ["Archivo", "monospace"],
      archivo: ["Archivo"],
      sourceSerifPro: ["Source Serif Pro", "serif"],
    },
    width: {
      "fill-available": "-webkit-fill-available",
    },
    screens: {
      x425: { min: "425px" },
    },
    colors: {
      "primary-black": "#0f0f0f",
      "situational-error": "#ff0d56",
      "situational-success": "#37ba73",
      transparent_gray: "#f0f0f0a0",
      light_gray: "#f0f0f0",
      "ocean-blue": "#0b6bcb",
      fiord: "#555e68",
      "medium-grey": {
        300: "#837675",
        350: "#eeefee",
        400: "#eaeaea",
        500: "#7d7e7f",
        600: "#f3f4f6",
      },
      "primary-cream": {
        300: "#fff3df",
        500: "#e8dbc8",
      },
      "primary-soft-green": {
        500: "#9fe0b7",
      },
      "purple-haze": {
        400: "#523280",
        500: "#573085",
      },
      "normal-black": {
        300: "#1b1b1b",
        400: "#111928",
      },
      cream: {
        500: "#f0f0f0",
      },
      bright_coral: {
        400: "#ff5754",
        500: "#ff665c",
        600: "#b24740",
      },
      bright_lilac: {
        500: "#e6adff",
      },
      soft_green: {
        500: "#addeba",
        600: "#799b82",
      },
      // shadcn
      border: "hsl(var(--border))",
      input: "hsl(var(--input))",
      ring: "hsl(var(--ring))",
      background: "hsl(var(--background))",
      foreground: "hsl(var(--foreground))",
      primary: {
        DEFAULT: "hsl(var(--primary))",
        foreground: "hsl(var(--primary-foreground))",
      },
      secondary: {
        DEFAULT: "hsl(var(--secondary))",
        foreground: "hsl(var(--secondary-foreground))",
      },
      destructive: {
        DEFAULT: "hsl(var(--destructive))",
        foreground: "hsl(var(--destructive-foreground))",
      },
      muted: {
        DEFAULT: "hsl(var(--muted))",
        foreground: "hsl(var(--muted-foreground))",
      },
      accent: {
        DEFAULT: "hsl(var(--accent))",
        foreground: "hsl(var(--accent-foreground))",
      },
      popover: {
        DEFAULT: "hsl(var(--popover))",
        foreground: "hsl(var(--popover-foreground))",
      },
      card: {
        DEFAULT: "hsl(var(--card))",
        foreground: "hsl(var(--card-foreground))",
      },
      sidebar: {
        DEFAULT: "hsl(var(--sidebar-background))",
        foreground: "hsl(var(--sidebar-foreground))",
        primary: "hsl(var(--sidebar-primary))",
        "primary-foreground": "hsl(var(--sidebar-primary-foreground))",
        accent: "hsl(var(--sidebar-accent))",
        "accent-foreground": "hsl(var(--sidebar-accent-foreground))",
        border: "hsl(var(--sidebar-border))",
        ring: "hsl(var(--sidebar-ring))",
      },
    },
    height: {
      3: "0.25rem",
    },
    utilities: {
      ".text-align-last-center": {
        "text-align-last": "center",
      },
    },
    keyframes: {
      "accordion-down": {
        from: { height: 0 },
        to: { height: "var(--radix-accordion-content-height)" },
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: 0 },
      },
      "collapsible-down": {
        from: { height: 0 },
        to: { height: "var(--radix-collapsible-content-height)" },
      },
      "collapsible-up": {
        from: { height: "var(--radix-collapsible-content-height)" },
        to: { height: 0 },
      },
      "caret-blink": {
        "0%,70%,100%": { opacity: "1" },
        "20%,50%": { opacity: "0" },
      },
      tada: {
        "0%": {
          transform: "scale3d(1, 1, 1)",
        },
        "10%, 20%": {
          transform: "scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)",
        },
        "30%, 50%, 70%, 90%": {
          transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
        },
        "40%, 60%, 80%": {
          transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)",
        },
        "100%": {
          transform: "scale3d(1, 1, 1)",
        },
      },
    },
    animation: {
      "caret-blink": "caret-blink 1.25s ease-out infinite",
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      "collapsible-down": "collapsible-down 0.2s ease-out",
      "collapsible-up": "collapsible-up 0.2s ease-out",
      tada: "tada 1s ease-in-out 0.25s 1",
    },
    backgroundImage: {
      "notes-landing": "url('assets/images/notes_landing_background.jpg')",
      "notes-landing-dark":
        "url('https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831')",
    },
    boxShadow: {
      extended:
        "349px 438px 157px 0px color(display-p3 0 0 0 / 0), 223px 280px 143px 0px color(display-p3 0 0 0 / 0.01), 126px 158px 121px 0px color(display-p3 0 0 0 / 0.05), 56px 70px 90px 0px color(display-p3 0 0 0 / 0.09), 14px 18px 49px 0px color(display-p3 0 0 0 / 0.1)",
      "short-flat":
        "0px 2px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px color(display-p3 0 0 0 / 0.1)",
    },
  },
}

export const plugins = [typography]
