import * as React from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import {
  CircleCheck,
  CircleEllipsis,
  CopyIcon,
  NotebookPen,
  PencilIcon,
  PlayIcon,
  Trash2Icon,
  UserXIcon,
  WandSparklesIcon,
} from "lucide-react"
import { useFormContext } from "react-hook-form"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import { CheckCircle } from "~/assets/icons"
import { cn } from "~/components/ui/utils"
import { useRemoveClientFromNote } from "~/hooks/firestore/useClients"
import {
  useCopyNoteToClipboard,
  useDeleteNote,
  useMarkNoteAsDone,
  useMarkNoteAsUndone,
  useRequestImprovedTranscript,
} from "~/hooks/useNotes"
import { EditNoteModal } from "~/pages/Notes/EditNoteModal"
import { NoteStatus, Note as NoteType } from "~/pages/Notes/types"
import { PROMPTS } from "~/utils/prompts"
import { TEMPLATE_START_DATE } from "../config"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  toast,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
  type ButtonProps,
} from "./ui"
import { ConfirmationModal } from "./ui/confirmation-modal"

const getImprovementLabel = (status: NoteStatus): string => {
  switch (status) {
    case NoteStatus.ImprovementRequested:
      return "Improvement requested"
    case NoteStatus.Improved:
      return "Note improved"
    default:
      return "Request improvement"
  }
}

const ButtonWithTooltip = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "asChild"> & {
    tooltip: React.ReactNode
  }
>(({ children, tooltip, ...props }, ref) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          ref={ref}
          type="button"
          size="icon"
          variant="ghost"
          {...props}
        >
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{tooltip}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
})
ButtonWithTooltip.displayName = "ButtonWithTooltip"

interface NoteActionsProps {
  note: NoteType
  initialOpenEdit?: boolean
  setShowRecording: (show: boolean) => void
  onDeletedNote?: (nodeId: string) => void
  onClientRemoved?: (nodeId: string) => void
  isCollapsed?: boolean
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>
}

const NoteActions = ({
  note,
  initialOpenEdit,
  onDeletedNote,
  onClientRemoved,
  setShowRecording,
  isCollapsed,
  setIsCollapsed,
}: NoteActionsProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const form = useFormContext()
  const { track } = useAnalytics()

  const [isEditOpen, setIsEditOpen] = React.useState(initialOpenEdit)

  // Actions
  const invalidateNote = React.useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["NOTE"] })
    await queryClient.invalidateQueries({ queryKey: ["NOTES"] })
  }, [queryClient])

  const markNoteAsDone = useMarkNoteAsDone({
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error marking note as done, please try again!")
      } else {
        void track("Note Marked_as_done")
        void invalidateNote()
      }
    },
  })

  const markNoteAsUndone = useMarkNoteAsUndone({
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error marking note as not done, please try again!")
      } else {
        void track("Note Marked_as_undone")
        void invalidateNote()
      }
    },
  })

  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const deleteNote = useDeleteNote({
    onSettled: async (_, error, variables) => {
      if (error) {
        toast.error("Error deleting note, please try again!")
      } else {
        onDeletedNote?.(variables.noteId)
        await invalidateNote()
      }
    },
  })

  const copyNoteToClipboard = useCopyNoteToClipboard({
    onCopy: () => {
      toast.success("Text copied to clipboard", { id: "copy" })
    },
  })

  const [showRemoveFromClientModal, setShowRemoveFromClientModal] =
    React.useState(false)
  const removeClientFromNote = useRemoveClientFromNote({
    onSettled: (_, e, vars) => {
      if (e) {
        toast.error(e.message)
      } else {
        toast.info("Note removed from client")
        onClientRemoved?.(vars.note.id)
      }
    },
  })

  const requestImprovedTranscript = useRequestImprovedTranscript({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Error requesting improvement, please try again!")
      } else {
        toast.success("Request sent")
        void invalidateNote()
      }
    },
  })

  const handleShowRecording = () => {
    void track("Note Play_recording")
    setIsCollapsed?.(false)
    setShowRecording(true)

    // Scroll to the player
    const refId = `audio-player-${note.id}`
    setTimeout(() => {
      const element = document.getElementById(refId)
      console.log(element)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }, 250)
  }

  const handleSetFormat = () => {
    void track("Note Select_transform")
    void navigate(`/notes/${note.id}/template-selection`)
  }

  const setFormatEnabled = note.serverTimestamp?.toDate() > TEMPLATE_START_DATE

  const actionItems = React.useMemo(
    () =>
      [
        {
          label: "Edit",
          icon: <PencilIcon className="size-4" />,
          onClick: () => setIsEditOpen(true),
          enabled: isCollapsed,
        },
        {
          label: "Copy text",
          icon: <CopyIcon className="size-4" />,
          onClick: () => {
            void copyNoteToClipboard.copy({
              noteId: note.id,
              text: form.getValues("transcription"),
            })
          },
          enabled: isCollapsed,
        },
        {
          label: "Play recording",
          icon: <PlayIcon className="size-4" />,
          onClick: handleShowRecording,
          enabled: note.storageRef,
        },
        {
          label: getImprovementLabel(note.status),
          icon: <WandSparklesIcon className="size-4" />,
          onClick: () => {
            requestImprovedTranscript.mutate({
              note,
              transcription: form.getValues("transcription"),
            })
          },
          disabled:
            note.status === NoteStatus.Edited ||
            note.status === NoteStatus.Improved ||
            note.status === NoteStatus.ImprovementRequested,
        },
        {
          label: "Set Format",
          icon: <NotebookPen className="size-4" />,
          onClick: handleSetFormat,
          disabled:
            ![NoteStatus.Transcribed, NoteStatus.Edited].includes(
              note.status
            ) || !note.storageRef,
          enabled: setFormatEnabled,
        },
        {
          label: "Remove from client",
          icon: <UserXIcon className="size-4" />,
          onClick: () => setShowRemoveFromClientModal(true),
          enabled: Boolean(note.clientId),
          separator: true,
          isDestruction: true,
        },
        {
          label: "Delete note",
          icon: <Trash2Icon className="size-4" />,
          onClick: () => setShowDeleteModal(true),
          enabled: isCollapsed,
          separator: true,
          isDestruction: true,
        },
      ].filter((item) => item.enabled ?? true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note, isCollapsed, form.getValues]
  )

  if (note?.markedAsDoneAt) {
    return (
      <div className="flex items-center w-fit">
        <ButtonWithTooltip
          tooltip={"Not done yet"}
          disabled={markNoteAsDone.isPending}
          className="hover:bg-transparent"
          onClick={() => markNoteAsUndone.mutate({ noteId: note.id })}
        >
          <CheckCircle className="size-5 text-situational-success" />
          <span className="sr-only">Done</span>
        </ButtonWithTooltip>
      </div>
    )
  }

  return (
    <>
      <div className="w-fit flex items-center gap-0.5 overflow-hidden">
        <ButtonWithTooltip
          title={"Mark as done"}
          tooltip={"Mark as done"}
          disabled={markNoteAsDone.isPending || !!note?.markedAsDoneAt}
          className="text-situational-success"
          onClick={() => markNoteAsDone.mutate({ noteId: note.id })}
        >
          <CircleCheck size={18} />
          <span className="sr-only">Mark as done</span>
        </ButtonWithTooltip>

        <AnimatePresence>
          {!isCollapsed && (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: "auto" }}
              exit={{ width: 0 }}
              transition={{ duration: 0.165 }}
              className="overflow-hidden flex items-center gap-0.5"
            >
              <ButtonWithTooltip
                title="Edit"
                tooltip={"Edit"}
                className="w-fit px-2"
                onClick={(e) => {
                  e.preventDefault()
                  setIsEditOpen(true)
                }}
              >
                <PencilIcon size={16} />
                <span>Edit</span>
              </ButtonWithTooltip>

              <ButtonWithTooltip
                title="Copy text"
                tooltip={"Copy text"}
                onClick={(e) => {
                  e.preventDefault()
                  void copyNoteToClipboard.copy({
                    noteId: note.id,
                    text: form.getValues("transcription"),
                  })
                }}
              >
                <CopyIcon size={16} />
              </ButtonWithTooltip>

              <ButtonWithTooltip
                title="Delete"
                tooltip={"Delete"}
                disabled={deleteNote.isPending}
                onClick={(e) => {
                  e.preventDefault()
                  setShowDeleteModal(true)
                }}
              >
                <Trash2Icon
                  size={18}
                  className="text-red-500"
                />
              </ButtonWithTooltip>
            </motion.div>
          )}
        </AnimatePresence>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ButtonWithTooltip tooltip={"More actions"}>
              <CircleEllipsis size={18} />
              <span className="sr-only">More</span>
            </ButtonWithTooltip>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            {actionItems.map((item, index) => (
              <React.Fragment key={index}>
                {item?.separator ? <DropdownMenuSeparator /> : null}

                <DropdownMenuItem
                  key={index}
                  disabled={item.disabled}
                  onSelect={() => item?.onClick?.()}
                  className={cn(
                    "gap-2.5",
                    item?.isDestruction &&
                      "text-situational-error focus:bg-primary/10 focus:text-situational-error"
                  )}
                >
                  {item.icon}
                  <span>{item.label}</span>
                </DropdownMenuItem>
              </React.Fragment>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <EditNoteModal
        note={note}
        isOpen={isEditOpen}
        onOpenChange={setIsEditOpen}
      />

      <ConfirmationModal
        isDestructive
        isOpen={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        title="Delete note?"
        description="Are you sure you want to delete this note?"
        closeButton="Cancel"
        confirmButton="Delete"
        onConfirm={() => deleteNote.mutate({ noteId: note.id })}
      />

      <ConfirmationModal
        isDestructive
        isOpen={showRemoveFromClientModal}
        onOpenChange={setShowRemoveFromClientModal}
        title="Remove from client?"
        description={PROMPTS.REMOVE_CLIENT_FROM_NOTE}
        closeButton="Cancel"
        confirmButton="Remove"
        onConfirm={() => removeClientFromNote.mutate({ note })}
      />
    </>
  )
}

export default NoteActions
