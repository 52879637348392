import * as React from "react"
import { ChevronLeft, PaperclipIcon } from "lucide-react"
import { Link } from "react-router"
import { useAnalytics } from "use-analytics"
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"

interface NoteHeaderProps {
  noteData?: {
    clientId?: string
    clientName?: string
    title?: string
  }
  setOpenClientDetailsModal: (value: boolean) => void
  setOpenModal: (value: boolean) => void
  attachmentCount?: number
  onToggleAttachmentPanel?: () => void
}

function NoteHeader({
  noteData,
  setOpenClientDetailsModal,
  setOpenModal,
  attachmentCount,
  onToggleAttachmentPanel,
  children,
}: React.PropsWithChildren<NoteHeaderProps>) {
  const { track } = useAnalytics()

  const handleOpenClientDetailsModal = () => {
    setOpenClientDetailsModal(true)
    void track("Note Client_details_shown_from_note_header")
  }

  const handleOpenClientModal = () => {
    setOpenModal(true)
    void track("Note Client_modal_opened_from_note_header")
  }

  return (
    <header className="fixed z-40 md:relative bottom-0 left-0 right-0 notes-title-bg w-full min-h-20 p-6 flex flex-col gap-4">
      {children}

      <div className="w-full flex justify-between items-center">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/notes"
              className="shrink-0"
            >
              <ChevronLeft
                size={28}
                className="text-black"
              />
              <span className="sr-only">Back</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Back</TooltipContent>
        </Tooltip>

        {noteData?.clientId ? (
          <button
            className="flex items-end gap-1 text-2xl font-bold text-black font-platypi cursor-pointer capitalize truncate max-w-[80%] bg-transparent border-none"
            onClick={handleOpenClientDetailsModal}
          >
            {attachmentCount !== undefined && attachmentCount > 0 ? (
              <span
                className="block px-1.5 py-0.5 rounded-sm text-lg leading-[1]"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onToggleAttachmentPanel?.()
                }}
              >
                <PaperclipIcon className="size-5" />
              </span>
            ) : null}

            {noteData?.clientName ?? noteData?.title}
          </button>
        ) : (
          <Button
            variant="secondary"
            className="py-3 px-5 rounded-lg"
            onClick={handleOpenClientModal}
          >
            Set Client
          </Button>
        )}
      </div>
    </header>
  )
}

export default NoteHeader
