import * as React from "react"
import { DateTime } from "luxon"
import { useControlledState } from "~/components/ui/internal"
import { indexEventsByDate, type ProcessedEvent } from "./events"

export type WeeklyCalendarContextType<T> = {
  events: Map<string, ProcessedEvent<T>[]>
  currentWeek: DateTime
  setCurrentWeek: React.Dispatch<React.SetStateAction<DateTime>>
  daysInWeek: DateTime[]
  nextWeek: () => void
  prevWeek: () => void
  resetWeek: () => void
}

const WeeklyCalendarContext =
  React.createContext<WeeklyCalendarContextType<any> | null>(null)

export function useWeeklyCalendar<T = any>() {
  const context = React.useContext(
    WeeklyCalendarContext
  ) as WeeklyCalendarContextType<T> | null

  if (!context) {
    throw new Error(
      "useWeeklyCalendar must be used within a WeeklyCalendarContext"
    )
  }

  return context
}

export const CalendarProvider: React.FC<
  React.PropsWithChildren<{
    week?: DateTime
    onWeekChange?: (week: DateTime) => void
    events: ProcessedEvent[]
  }>
> = ({ week, onWeekChange, events, children }) => {
  const _currentWeek = React.useMemo(() => DateTime.local().startOf("week"), [])

  const [currentWeek, setCurrentWeek] = useControlledState(
    week,
    _currentWeek,
    onWeekChange
  ) as [DateTime, React.Dispatch<React.SetStateAction<DateTime>>]

  const indexedEvents = React.useMemo(
    () => indexEventsByDate(events ?? []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(events)]
  )

  const daysInWeek = React.useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => currentWeek.plus({ days: i }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek.toString()])

  const prevWeek = React.useCallback(() => {
    setCurrentWeek((prevWeek) => prevWeek.minus({ weeks: 1 }))
  }, [setCurrentWeek])

  const nextWeek = React.useCallback(() => {
    setCurrentWeek((prevWeek) => prevWeek.plus({ weeks: 1 }))
  }, [setCurrentWeek])

  const resetWeek = React.useCallback(() => {
    setCurrentWeek(_currentWeek)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_currentWeek?.toString()])

  const value = React.useMemo(
    () => ({
      events: indexedEvents,
      currentWeek,
      setCurrentWeek,
      daysInWeek,
      nextWeek,
      prevWeek,
      resetWeek,
    }),
    [
      indexedEvents,
      currentWeek,
      setCurrentWeek,
      daysInWeek,
      nextWeek,
      prevWeek,
      resetWeek,
    ]
  )

  return (
    <WeeklyCalendarContext.Provider value={value}>
      {children}
    </WeeklyCalendarContext.Provider>
  )
}
