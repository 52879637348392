import React, { useState } from "react"
import { Button } from "~/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog"
import { Label } from "~/components/ui/label"
import { RadioGroup, RadioGroupItem } from "~/components/ui/radio-group"
import { cn } from "~/components/ui/utils"

export type DeleteOption = "current" | "following"

interface DeleteRepeatingSessionDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onDeleteConfirm: (option: DeleteOption) => Promise<void>
  sessionType?: string
}

export function DeleteRepeatingSessionDialog({
  isOpen,
  onOpenChange,
  onDeleteConfirm,
  sessionType = "session",
}: DeleteRepeatingSessionDialogProps) {
  const [selectedOption, setSelectedOption] = useState<DeleteOption>("current")
  const [isDeleting, setIsDeleting] = useState(false)

  const handleConfirm = async () => {
    setIsDeleting(true)
    try {
      await onDeleteConfirm(selectedOption)
      onOpenChange(false)
    } catch (error) {
      console.error("Error deleting session:", error)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Delete repeating {sessionType}</DialogTitle>
          <DialogDescription className="sr-only">
            Are you sure you want to delete this repeating {sessionType}?
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <RadioGroup
            value={selectedOption}
            onValueChange={(value) => setSelectedOption(value as DeleteOption)}
            className="space-y-4"
          >
            <div className="flex items-start space-x-3">
              <RadioGroupItem
                value="current"
                id="current"
              />
              <div className="grid gap-1.5">
                <Label
                  htmlFor="current"
                  className={cn(
                    "text-sm font-medium leading-none",
                    selectedOption === "current" && "text-primary"
                  )}
                >
                  Delete this {sessionType} only
                </Label>
                <p className="text-sm text-muted-foreground">
                  Only delete the current occurrence of this repeating{" "}
                  {sessionType}.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-3">
              <RadioGroupItem
                value="following"
                id="following"
              />
              <div className="grid gap-1.5">
                <Label
                  htmlFor="following"
                  className={cn(
                    "text-sm font-medium leading-none",
                    selectedOption === "following" && "text-primary"
                  )}
                >
                  Delete this and following {sessionType}s
                </Label>
                <p className="text-sm text-muted-foreground">
                  Delete this occurrence and all future occurrences of this
                  repeating {sessionType}.
                </p>
              </div>
            </div>
          </RadioGroup>
        </div>

        <DialogFooter className="flex sm:justify-between">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={handleConfirm}
            disabled={isDeleting}
            className={cn(
              "w-full sm:w-auto",
              isDeleting && "opacity-50 cursor-not-allowed"
            )}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteRepeatingSessionDialog
