import type { DateTime } from "luxon"
import { createWithEqualityFn as create } from "zustand/traditional"
import { shallow } from "zustand/vanilla/shallow"
import type { ProcessedEvent } from "~/components/weekly-calendar"
import { type Session } from "~/hooks/firestore/useSessions"

type SelectMode = "event" | "propose"

export type CalendarStore = {
  selectMode: SelectMode
  setSelectMode: (mode: SelectMode) => void

  selectedSlot: DateTime | null
  setSelectedSlot: (proposalSlots: DateTime | null) => void

  selectedEvent: ProcessedEvent<Session> | null
  setSelectedEvent: (event: ProcessedEvent<Session> | null) => void
  selectedEventMode: "view" | "edit"
  setSelectedEventMode: (mode: "view" | "edit") => void

  selectedProposal: ProcessedEvent<Session> | null
  setSelectedProposal: (proposal: ProcessedEvent<Session> | null) => void
}

export const useCalendarStore = create<CalendarStore>()(
  (set) => ({
    selectMode: "event",
    setSelectMode: (mode) => set({ selectMode: mode }),

    selectedSlot: null,
    setSelectedSlot: (proposalSlots) =>
      set({
        selectMode: "event",
        selectedSlot: proposalSlots,
        selectedEvent: null,
        selectedProposal: null,
        selectedEventMode: "view",
      }),

    selectedEvent: null,
    selectedEventMode: "view",
    setSelectedEvent: (event) =>
      set({
        selectMode: "event",
        selectedEvent: event,
        selectedSlot: null,
        selectedProposal: null,
        selectedEventMode: "view",
      }),
    setSelectedEventMode: (mode) => set({ selectedEventMode: mode }),

    selectedProposal: null,
    setSelectedProposal: (proposal) =>
      set({
        selectMode: "event",
        selectedProposal: proposal,
        selectedSlot: null,
        selectedEvent: null,
      }),
  }),
  shallow
)
