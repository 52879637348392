import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { SubmitButton, toast } from "~/components/ui"
import {
  SUPPORTED_MIME_TYPES,
  useUploadAttachment,
} from "~/hooks/firestore/useClientAttachments"

function FileTrigger({ onSelect, children }) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <>
      <Slot
        onClick={() => {
          if (inputRef.current?.value) {
            inputRef.current.value = ""
          }
          inputRef.current?.click()
        }}
      >
        {children}
      </Slot>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        accept={Array.from(SUPPORTED_MIME_TYPES).join(",")}
        onChange={(e) => onSelect?.(e.target.files)}
        capture={false}
        multiple={false}
      />
    </>
  )
}

export const AttachmentUpload = ({ clientId }) => {
  const { mutate, isPending } = useUploadAttachment({
    onMutate: () => {
      toast.loading("Upload attachment...", {
        id: "uploading-attachment",
      })
    },
    onSettled: (_, error) => {
      if (error) {
        toast.error(error?.message ?? "Failed to upload attachment", {
          id: "uploading-attachment",
        })
      } else {
        toast.success("Attachment uploaded successfully", {
          id: "uploading-attachment",
        })
      }
    },
  })

  return (
    <div className="w-full text-center">
      <FileTrigger
        onSelect={(files) => {
          if (files && files.length) {
            mutate({ clientId, file: files[0] })
          }
        }}
      >
        <SubmitButton
          size="lg"
          variant="outline"
          className="w-full md:w-auto min-w-[150px] bg-transparent border-secondary hover:text-secondary-foreground hover:bg-secondary"
          isSubmitting={isPending}
        >
          Upload Attachment
        </SubmitButton>
      </FileTrigger>
    </div>
  )
}
