// Sentry initialization should be imported first!
import "./config/instrument"
import React from "react"
import googleAnalytics from "@analytics/google-analytics"
import googleTagManager from "@analytics/google-tag-manager"
import Analytics from "analytics"
import ReactDOM from "react-dom/client"
import { AnalyticsProvider } from "use-analytics"
import App from "./App"
import { setupPdfjs } from "./lib/pdfjs"
import "./index.css"
import "./i18n"
import reportWebVitals from "./reportWebVitals"

// Initialize analytics with GTM
const analytics = Analytics({
  app: "joy-notes",
  plugins: [
    googleAnalytics({
      measurementIds: [import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID],
    }),
    googleTagManager({
      containerId: "GTM-T83JV6DD",
    }),
  ],
})

// Setup PDF.js
setupPdfjs()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <App />
    </AnalyticsProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
