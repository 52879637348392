import { useState } from "react"
import { FirebaseError } from "@firebase/util"
import * as Sentry from "@sentry/browser"
import type { MultiFactorError, MultiFactorResolver, User } from "firebase/auth"
import { doc, increment, serverTimestamp, updateDoc } from "firebase/firestore"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router"
import BoxContainer from "~/components/BoxContainer"
import { LoginForm } from "~/components/LoginForm"
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  toast,
} from "~/components/ui"
import { sentryDefaultTags } from "~/config/instrument"
import { useAuth } from "~/context/AuthContext"
import { useRecaptcha } from "~/hooks/useRecaptcha"
import {
  getMFAResolverAndVerificationId,
  getUserFriendlyAuthError,
  resendVerificationCode,
  signin,
} from "~/lib/auth"
import { db } from "~/services/firebase"
import { CodeLogin } from "./CodeLogin"

export default function Login() {
  const recaptcha = useRecaptcha("login-recaptcha-container")

  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>()
  const [verificationId, setVerificationId] = useState<string>()
  const [resolver, setResolver] = useState<MultiFactorResolver>()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const emailParam = params.get("email")
  const email = emailParam ?? ""
  const { t } = useTranslation()

  const updateUserStatistics = async (user: User) => {
    // Update user statistics
    if (user.emailVerified) {
      const dataRef = doc(db, `users/${user.uid}`)
      await updateDoc(dataRef, {
        "statistics.totalSignIns": increment(1),
        "statistics.lastSignIn": serverTimestamp(),
      })

      // include feature list
      const enableServiceWorker =
        import.meta.env.VITE_JOY_SERVICE_WORKER_DISABLE !== "true"
      const hasServiceWorker =
        "serviceWorker" in navigator && enableServiceWorker

      Sentry.captureMessage(`User signed in`, {
        level: "info",
        user: { id: user.uid },
        tags: { ...sentryDefaultTags, featuresServiceWorker: hasServiceWorker },
      })
    } else {
      throw Error("User email not verified")
    }
  }

  const handleSubmit = async (data: { email: string; password: string }) => {
    // Clear any previous errors
    setError(undefined)

    try {
      const userCredential = await signin(data.email, data.password)
      try {
        await updateUserStatistics(userCredential.user)
      } catch (error) {
        console.error("Error updating sign-in statistics: ", error)
      }

      // User signed in, navigate to home
      void navigate("/")
    } catch (error) {
      if (
        (error as MultiFactorError)?.code === "auth/multi-factor-auth-required"
      ) {
        try {
          const response = error as MultiFactorError
          // Check if MFA is required, if so handPossibleMFAAsync will set the
          // verificationId and resolver to show the code login component
          await startMFAVerification(response)
        } catch (error) {
          if (error instanceof FirebaseError) {
            handleFirebaseError(error)
          } else {
            setError(
              "Uknown MFA error. Please try again or contact support if problem persists."
            )
            console.error("Login error: ", error)
          }
        }
      } else {
        if (error instanceof FirebaseError) {
          handleFirebaseError(error)
        } else {
          setError(
            "Uknown authentication error. Please try again or contact support if problem persists."
          )
          console.error("Login error: ", error)
        }
      }
    }
  }

  const handleResendCode = async () => {
    if (!recaptcha || !resolver) {
      toast.error("Could not resend verification code")
      return
    }
    try {
      const result = await resendVerificationCode(recaptcha, resolver)
      if (result) {
        setVerificationId(result)
      } else {
        throw new Error("Failed to resend verification code")
      }
    } catch (error) {
      toast.error("Failed to resend verification code")
      console.error("Resend code error: ", error)
    }
  }

  const handleFirebaseError = (error: FirebaseError) => {
    const message = getUserFriendlyAuthError(
      error,
      "Unknown authentication error. Please try again."
    )
    setError(message)
  }

  const startMFAVerification = async (error: MultiFactorError) => {
    if (recaptcha) {
      const verificationResult = await getMFAResolverAndVerificationId(
        error,
        recaptcha,
        0
      )
      if (!verificationResult) {
        throw new Error("Failed to verify multi-factor authentication")
      }
      const { verificationId, resolver } = verificationResult

      setVerificationId(verificationId)
      setResolver(resolver)
    } else {
      console.error("MFA required but no recaptcha found")
      throw error
    }
  }

  return (
    <>
      <BoxContainer>
        {!verificationId && !resolver && (
          <>
            <div className="flex flex-col gap-4 items-center mb-8">
              <h1 className="text-5xl font-platypi">
                {t("loginPage.main.heading")}
              </h1>
              <p className="text-center my-2">
                {t("loginPage.main.description")}
              </p>
            </div>

            {error !== undefined ? (
              <Alert variant="destructive">
                <AlertTitle className="text-[#c41c1c]">
                  {t("loginPage.sign.in.failed")}
                </AlertTitle>
                <AlertDescription>
                  <p className="text-[#c41c1c]">{error.toString()}</p>
                  <p className="text-[#c41c1c]">
                    {t("loginPage.sign.in.again", {
                      support_email: "support@joy.day",
                    })}
                  </p>
                </AlertDescription>
              </Alert>
            ) : null}

            <LoginForm
              defaultEmail={email}
              handleSubmit={handleSubmit}
            />

            <div className="flex flex-col gap-4 items-center">
              <Button
                asChild
                variant="link"
                className="text-foreground underline hover:text-primary"
              >
                <Link to="/reset-password">
                  {t("loginPage.password.forgot")}?
                </Link>
              </Button>

              <hr className="w-4/5 my-4 mx-auto" />
              <h2 className="text-lg font-bold">{t("loginPage.notMember")}</h2>

              <Button
                asChild
                variant="link"
                className="text-foreground underline hover:text-primary"
              >
                <Link to="/sign-up">{t("loginPage.sign.up.now")}</Link>
              </Button>
            </div>
          </>
        )}

        {verificationId && resolver && (
          <CodeLogin
            verificationId={verificationId}
            resolver={resolver}
            successfulLogin={async () => {
              try {
                await updateUserStatistics(currentUser!)
              } finally {
                void navigate("/")
              }
            }}
            handleCancel={() => {
              setVerificationId(undefined)
              setResolver(undefined)
            }}
            handleResendCode={handleResendCode}
          />
        )}
      </BoxContainer>

      <div id="login-recaptcha-container" />
    </>
  )
}
