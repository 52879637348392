import * as React from "react"

type UseLocalIntlReturn = {
  uses12Hour: boolean
}

export function useLocalIntl(): UseLocalIntlReturn {
  const uses12Hour = React.useRef(
    () =>
      new Intl.DateTimeFormat(undefined, {
        hour: "numeric",
      }).resolvedOptions().hour12
  )

  return {
    uses12Hour: !uses12Hour.current,
  }
}
