import * as React from "react"
import { useDeferredValue } from "react"
import { useFormContext } from "react-hook-form"
import { parseDate, type ProcessedEvent } from "~/components/weekly-calendar"
import type { Session } from "~/hooks/firestore/useSessions"
import { asOptional, z } from "~/lib/zod"

export const MAX_PROPOSAL_SLOTS = 3

export const formSchema = z.object({
  date: z.string().datetime({ offset: true }).nullable(),
  start: z.number().min(0).max(59),
  duration: z.number().min(0).max(480),
  title: asOptional(z.string()),
  description: asOptional(z.string()),
  clientId: asOptional(z.string()),
  clientName: asOptional(z.string()),
  personal: z.boolean(),
  slots: z.array(z.string()),
  repeatFrequency: asOptional(z.string()),
  repeatInterval: asOptional(z.number()),
  repeatCount: asOptional(z.number()),
})

export type FromSchemaValues = z.infer<typeof formSchema>

export const INITIAL_VALUES: FromSchemaValues = {
  date: null,
  start: 0,
  duration: 60,
  title: "",
  description: "",
  clientId: "",
  personal: true,
  slots: [],
}

export const toCalendarEvents = (sessions: Session[]) => {
  return (sessions ?? []).map((session) => {
    return {
      id: session.id,
      title: session.title,
      start: session.start,
      end: session.end,
      raw: session,
    } as ProcessedEvent<Session>
  })
}

type FormState = Pick<
  FromSchemaValues,
  | "date"
  | "title"
  | "start"
  | "duration"
  | "repeatFrequency"
  | "repeatInterval"
  | "repeatCount"
>
const pickFormState = (value: FromSchemaValues): FormState => ({
  date: value.date,
  title: value.title,
  start: value.start,
  duration: value.duration,
  repeatFrequency: value.repeatFrequency,
  repeatInterval: value.repeatInterval,
  repeatCount: value.repeatCount,
})

export const useCalendarFormValues = ({ slotDate }) => {
  const { watch, getValues } = useFormContext<FromSchemaValues>()

  const [formState, setFormState] = React.useState<FormState>(() =>
    pickFormState(getValues())
  )

  // Subscribe to form state. Call watch() directly in useEffect will cause
  // re-render parents. This is a workaround to prevent re-rendering.
  React.useEffect(() => {
    const subscription = watch((value) =>
      setFormState(pickFormState(value as FromSchemaValues))
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const date = React.useDeferredValue(parseDate(formState?.date ?? slotDate!))

  const rawTitle = React.useDeferredValue(formState?.title ?? "")
  const title = typeof rawTitle === "string" ? rawTitle : String(rawTitle)

  const minutes = React.useDeferredValue(Number(formState?.start ?? 0))
  const duration = React.useDeferredValue(Number(formState?.duration ?? 60))

  return {
    date,
    title,
    minutes,
    duration,
  }
}

export const useCalendarFormSlotsValue = () => {
  const { watch, getValues } = useFormContext<FromSchemaValues>()

  const [slots, setSlots] = React.useState<string[]>(
    () => getValues().slots ?? []
  )

  React.useEffect(() => {
    const subscription = watch((value) =>
      setSlots((value as FromSchemaValues)?.slots ?? [])
    )

    return () => subscription.unsubscribe()
  }, [watch])

  return useDeferredValue(slots)
}

export function shareUrlFromProposalId(proposalId: string) {
  return `https://meet.joy.day/?proposalId=${proposalId}`
}

export type RepeatOption = {
  value: number
  frequency: string
  interval: number
  text: string
}

// Create repeat options based on the provided weekday (e.g., "Monday")
// Follow rrule format for repeat options
export const createRepeatOptions = (weekDay: string) => {
  const repeatOptions: RepeatOption[] = [
    { value: 0, frequency: "", interval: 0, text: "Does not repeat" },
    { value: 1, frequency: "weekly", interval: 1, text: `Every ${weekDay}` },
    {
      value: 2,
      frequency: "weekly",
      interval: 2,
      text: `Every other ${weekDay}`,
    },
    {
      value: 3,
      frequency: "weekly",
      interval: 3,
      text: `Every 3rd ${weekDay}`,
    },
    {
      value: 4,
      frequency: "weekly",
      interval: 4,
      text: `Every 4th ${weekDay}`,
    },
  ]
  return repeatOptions
}
