import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cn } from "~/components/ui/utils"
import { parseDate, ProcessedEvent } from "./events"
import { useWeeklyRoot } from "./WeeklyRoot"

const MIN_SLOT_HEIGHT = 10

export type EventSlotProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  asChild?: boolean
  event: ProcessedEvent
}

const toDuration = (event: ProcessedEvent) =>
  parseDate(event.end).diff(parseDate(event.start), "minutes")

export const EventSlot = React.forwardRef<
  HTMLDivElement,
  EventSlotProps & {
    children?:
      | ((props: {
          height: number
          offsetTop: number
          isOvernight: boolean
        }) => React.ReactNode)
      | React.ReactElement
  }
>(({ event, asChild, children, ...props }, ref) => {
  const { slotHeight } = useWeeklyRoot()

  const isOvernight = !parseDate(event.start).hasSame(
    parseDate(event.end),
    "day"
  )

  // Calculate the top offset based on the start time of the slot
  // The start time is converted to the number of minutes above the hour
  const minutesAboveHour = parseDate(event.start).minute
  const offsetTop = (minutesAboveHour / 60) * slotHeight - 1

  let height: number
  if (isOvernight) {
    const endOfDay = parseDate(event.start).endOf("day")
    const durationUntilEndOfDay = endOfDay.diff(
      parseDate(event.start),
      "minutes"
    )
    height = Math.ceil(
      Math.max(
        (durationUntilEndOfDay.as("minutes") / 60) * (slotHeight + 1) - 1,
        MIN_SLOT_HEIGHT
      )
    )
  } else {
    const durationAsMinutes = toDuration(event)
    height = Math.ceil(
      Math.max(
        (durationAsMinutes.as("minutes") / 60) * (slotHeight + 1) - 2,
        MIN_SLOT_HEIGHT
      )
    )
  }

  const Comp = asChild ? Slot : "div"

  return (
    <Comp
      ref={ref}
      {...props}
      data-height={height || 0}
      data-offset-top={offsetTop || 0}
      className={cn(
        "absolute z-10 w-[85%] flex items-start justify-start p-0.5",
        props.className
      )}
      style={{
        ...props.style,
        top: `${offsetTop}px`,
        height: `${height}px`,
      }}
    >
      {typeof children === "function"
        ? children({ height, offsetTop, isOvernight })
        : children}
    </Comp>
  )
})
EventSlot.displayName = "EventSlot"
