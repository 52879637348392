import {
  RecordingMessage,
  RecordingRPCResponse,
  RecordingSyncRequests,
  RecordingSyncResponses,
} from "~/utils/recordings/protocol"
import { isoTimestamp } from "~/utils/timestamps"

export class RecordingSyncClientError extends Error {
  constructor(reason?: string) {
    super(reason)
  }
}

interface PendingResponseMetadata {
  rpcId: string
  responseKind: string
  promiseResolve: (value: unknown | PromiseLike<unknown>) => unknown
  promiseReject: (reason?: unknown) => void
}

export class RecordingSyncClient {
  private static _instance: RecordingSyncClient

  private pendingResponses: {
    [key: string]: PendingResponseMetadata
  }

  private constructor() {
    if (RecordingSyncClient._instance) {
      throw new Error("Use getSyncClient to get the SyncClient instance")
    }
    this.pendingResponses = {}

    navigator.serviceWorker.addEventListener(
      "message",
      this.rpcResponseHandler.bind(this)
    )
  }

  static getSyncClient(): RecordingSyncClient {
    RecordingSyncClient._instance =
      RecordingSyncClient._instance || new RecordingSyncClient()
    return RecordingSyncClient._instance
  }

  private async getServiceWorker(): Promise<ServiceWorker> {
    if (!("serviceWorker" in navigator)) {
      return Promise.reject(
        new RecordingSyncClientError(
          "Browser does not support service workers."
        )
      )
    }

    const allRegisteredWorkers =
      await navigator.serviceWorker.getRegistrations()

    if (allRegisteredWorkers.length == 0) {
      return Promise.reject(
        new RecordingSyncClientError(
          "Could not find any registered service workers"
        )
      )
    }

    const swReg = await navigator.serviceWorker.ready

    if (!swReg.active) {
      return Promise.reject(
        new RecordingSyncClientError("Could not find an active service worker.")
      )
    }

    return swReg.active
  }

  async isActive(): Promise<boolean> {
    try {
      await this.getServiceWorker()
      return true
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.debug(`Failed to retreive service worker`, err)
      }
      return false
    }
  }

  async start(): Promise<void> {
    return this.send({ kind: "start" })
  }

  async stop(): Promise<void> {
    return this.send({ kind: "stop" })
  }

  async send(message: RecordingMessage): Promise<void> {
    // fire and forget a message
    const sw = await this.getServiceWorker()
    sw.postMessage(message)
  }

  private rpcResponseHandler(ev: MessageEvent) {
    // response received so we don't need to listen anymore
    //navigator.serviceWorker.removeEventListener("message", handler)

    const response = ev.data as RecordingRPCResponse

    // TODO: This is not sufficient when in a multi-threaded env
    // We also need to handle the case when more than one RPC request
    // can be in-flight and the responses are received in a different
    // order.

    // must match an id we sent out
    if (!("rpcId" in response)) {
      throw new RecordingSyncClientError(
        `Response message did not include an RPC ID`
      )
    }

    const responseRPCID = response.rpcId
    const pendingResponse = this.pendingResponses[responseRPCID]

    if (pendingResponse === undefined) {
      console.debug(
        isoTimestamp(),
        `RPC response ID, ${response.rpcId}, did not match a pending response ID`
      )

      return
    }

    delete this.pendingResponses[responseRPCID]

    const {
      responseKind,
      promiseResolve: resolve,
      promiseReject: reject,
    } = pendingResponse

    // must match the expected response kind
    if (!("kind" in response && response.kind === responseKind)) {
      reject(
        new RecordingSyncClientError(
          `RPC response kind did not match request ${responseKind} != ${response.kind}`
        )
      )
      return
    }

    // Response does not conform to the protocol
    if (!("status" in response)) {
      reject(
        new RecordingSyncClientError(
          `RPC response does not have a status property as expected`
        )
      )
      return
    }

    if (response.status === "error") {
      if (
        "error" in response &&
        response.error !== undefined &&
        "code" in response.error &&
        "message" in response.error
      ) {
        reject(
          new RecordingSyncClientError(
            `RPC failed, ${response.error.code}, with message: ${response.error.message}`
          )
        )
        return
      } else {
        reject(
          new RecordingSyncClientError(
            `RPC failed, but error was not following the expected protocol: ${JSON.stringify(response.error)}`
          )
        )
        return
      }
    }

    resolve(ev.data)
  }

  async call<
    Req extends RecordingSyncRequests,
    Resp extends RecordingSyncResponses,
  >(request: Req): Promise<Resp> {
    const sw = await this.getServiceWorker()

    const result = await new Promise((resolve, reject) => {
      const rpcId = `${isoTimestamp()}-${Math.round(Math.random() * 10000)}`
      const responseKind = request.kind.replace("request", "response")

      this.pendingResponses[rpcId] = {
        rpcId,
        responseKind,
        promiseResolve: resolve,
        promiseReject: reject,
      }

      const rpcRequest = {
        ...request,
        rpcId,
      }

      // send out the RPC request including the ID and wait for the response
      sw.postMessage(rpcRequest)
    })

    return result as unknown as Resp
  }
}

export function getSyncClient(): RecordingSyncClient {
  return RecordingSyncClient.getSyncClient()
}
