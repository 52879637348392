import { useState } from "react"
import { cleanupActiveSubscriptions } from "~/hooks/firestore/storage"
import { signout } from "~/lib/auth"
import { queryClient } from "~/lib/react-query"
import { LocalRecordings } from "~/utils/recordings/local"
import { getSyncClient } from "~/utils/recordings/syncClient"

const useSafeLogout = () => {
  const [localRecordingsFound, setLocalRecordingsFound] = useState(false)

  const checkAllRecordingsSynced = async (): Promise<boolean> => {
    const local = new LocalRecordings()
    return local.isAllSynced()
  }

  const forceLogout = async () => {
    // Reset local recordings found
    setLocalRecordingsFound(false)

    try {
      // clear react-query cache
      cleanupActiveSubscriptions(queryClient)
      queryClient.clear()

      // this is best effort and shouldnt stop the db to be deleted
      await safeStopServiceWorker()

      // remove any local state
      await new Promise<void>((resolve, reject) => {
        const req = indexedDB.deleteDatabase("joy-recordings")

        req.onerror = (err) => reject(err)
        req.onsuccess = () => resolve()
      })
    } finally {
      // signout from firebase
      await signout()
    }
  }

  const safeStopServiceWorker = async () => {
    // Halt and then unregister the service worker
    try {
      await getSyncClient().stop()
    } catch (err: unknown) {
      console.debug("Failed to halt service worker, de-register anyway", err)
    }

    try {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const registration of registrations) {
        await registration.unregister()
      }
    } catch (err: unknown) {
      console.debug("Failed to unregister service worker", err)
    }
  }

  const tryLogout = async () => {
    // Reset local recordings found
    setLocalRecordingsFound(false)

    try {
      // Check that local recordings are synced
      const allSynced = await checkAllRecordingsSynced()

      // If not synced, return false
      if (!allSynced) {
        setLocalRecordingsFound(true)
        return false
      }

      // If synced, logout
      await forceLogout()
      return true
    } catch (err: unknown) {
      // If there is an error, signout anyway
      await signout()
      // Throw the error again
      throw err
    }
  }

  return { localRecordingsFound, tryLogout, forceLogout }
}

export default useSafeLogout
