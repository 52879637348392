import React from "react"
import { Button } from "~/components/ui"
import { cn } from "~/components/ui/utils"
import useMicrophonePermission from "~/hooks/useMicrophonePermission"

interface MicrophonePermissionCheckProps {
  isIntroduction?: boolean
  onTalkToSupport: () => void
  onPermissionGranted: () => void
  onPermissionChange?: (permission: PermissionState | "unknown") => void
  onPermissionError?: (error: string) => void
}

const MicrophonePermission: React.FC<MicrophonePermissionCheckProps> = ({
  isIntroduction,
  onTalkToSupport,
  onPermissionGranted,
  onPermissionChange,
  onPermissionError,
}) => {
  const {
    permissionStatus,
    error,
    isChecking,
    isRequesting,
    requestMicPermission,
  } = useMicrophonePermission({
    onPermissionGranted,
    onPermissionChange,
    onPermissionError,
  })

  if (permissionStatus === "granted") {
    return null // Permission already granted, render nothing
  }

  if (isChecking) {
    return <p>Checking microphone permission...</p>
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4">
      <div className="w-[300px] md:w-[480px] min-h-[150px] bg-white rounded-lg flex flex-col items-center justify-center p-4 md:px-6 shadow-box-1 gap-2">
        <h2 className="text-center text-xl font-platypi font-bold">
          {isIntroduction ? "Welcome!" : "Let's Get Your Microphone Enabled!"}
        </h2>
        {permissionStatus === "prompt" && (
          <>
            <p className="text-md text-foreground my-2">
              {isIntroduction
                ? "Let's Get Your Microphone Enabled!"
                : "Give access to your microphone to record your note."}
            </p>
            <Button
              variant={"secondary"}
              onClick={requestMicPermission}
            >
              Enable microphone
            </Button>
            <p
              className={cn(
                "text-sm text-foreground",
                isRequesting ? "visible" : "invisible"
              )}
            >
              Accept the browser prompt to use your microphone.
            </p>
          </>
        )}
        {permissionStatus === "denied" && (
          <div className="flex flex-col space-y-4">
            <p className="text-md text-foreground my-2">
              Microphone access is denied.
              <br />
              Please enable it in your browser&apos;s settings. For step-by-step
              instructions, visit our support page{" "}
              <a
                className="underline font-bold"
                href="https://gettinghelpwithjoy.crisp.help/en/category/microphone-troubleshooting-ayvajt/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              or{" "}
              <button
                className="underline font-bold"
                onClick={onTalkToSupport}
              >
                talk to live support
              </button>
            </p>
          </div>
        )}
        {permissionStatus === "unknown" && (
          <>
            <p className="text-md text-foreground my-2 balance">
              Unable to determine microphone permission status.
              <br />
              Please ensure you have a microphone connected and that your
              browser allows microphone use.
            </p>
            <p className="text-md text-foreground my-2">
              Need help? Talk to our live support{" "}
              <button
                className="underline"
                onClick={onTalkToSupport}
              >
                here
              </button>
              .
            </p>
          </>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  )
}

export default MicrophonePermission
