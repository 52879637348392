import * as React from "react"
import { DateTime, type WeekdayNumbers } from "luxon"
import { FormProvider, useForm } from "react-hook-form"
import { ScrollArea } from "~/components/ui"
import { cn } from "~/components/ui/utils"
import {
  CalendarProvider,
  WeeklyHeader,
  WeeklyNavigation,
  WeeklyRoot,
  WeeklySlots,
} from "~/components/weekly-calendar"
import { toWeekString, useSessions } from "~/hooks/firestore/useSessions"
import { useInactivityRedirect } from "~/hooks/useInactivityRedirect"
import { zodErrorMap, zodResolver } from "~/lib/zod"
import { ProposalDetail } from "~/pages/Calendar/ProposalDetail"
import { BookingDetail } from "./BookingDetail"
import { NewBooking } from "./NewBooking"
import { NewProposal } from "./NewProposal"
import { SlotItem } from "./Slots"
import {
  formSchema,
  FromSchemaValues,
  INITIAL_VALUES,
  toCalendarEvents,
} from "./utils"

// Create an array of abbreviated weekday names in the user's locale
const WEEKDAY_ABBR = Array.from({ length: 7 }, (_, i) => {
  // Create a date with the weekday index (Luxon uses 1-7 for weekdays)
  const date = DateTime.local().set({ weekday: (i + 1) as WeekdayNumbers })

  // Get short weekday name and format to ["MO", "TU", "WE", "TH", "FR", "SA", "SU"]
  return date.toLocaleString({ weekday: "short" }).substring(0, 2).toUpperCase()
})

export default function Calendar() {
  const [week, setWeek] = React.useState(() => DateTime.local().startOf("week"))
  const sessionsQuery = useSessions({ weekString: toWeekString(week) })

  useInactivityRedirect({ redirectPath: "/home", source: "Calendar" })

  // Scroll to the 8:00 AM position
  const scrollRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (scrollRef.current) {
      const slotHeight = parseInt(
        getComputedStyle(scrollRef.current).getPropertyValue(
          "--wlc-slot-height"
        ),
        10
      )

      scrollRef.current.scrollTo({
        top: 8 * slotHeight,
      })
    }
  }, [])

  const form = useForm<FromSchemaValues>({
    resolver: zodResolver(formSchema, { errorMap: zodErrorMap }),
    defaultValues: INITIAL_VALUES,
  })

  return (
    <FormProvider {...form}>
      <CalendarProvider
        week={week}
        onWeekChange={setWeek}
        events={toCalendarEvents(sessionsQuery?.data ?? [])}
      >
        <WeeklyRoot className="relative overflow-hidden bg-primary-cream-300">
          <WeeklyNavigation />

          <WeeklyHeader className="bg-background">
            {({ day, isToday, className }) => (
              <div
                className={cn(
                  className,
                  "flex flex-col items-center justify-center data-[hovered]:bg-neutral-50 border-b gap-1"
                )}
              >
                <span
                  className={cn(
                    "flex items-center justify-center text-sm h-4 w-8 rounded-[6px] font-medium leading-[1]",
                    isToday && "text-white bg-[var(--wlc-highlight-color)]"
                  )}
                >
                  {WEEKDAY_ABBR[day.weekday - 1]}
                </span>

                <span
                  className={cn(
                    "text-sm leading-[1] text-muted-foreground",
                    isToday && "text-[var(--wlc-highlight-color)]"
                  )}
                >
                  {day.toFormat("dd")}
                </span>
              </div>
            )}
          </WeeklyHeader>

          <ScrollArea viewportRef={scrollRef}>
            <WeeklySlots className="bg-background">
              {(props) => (
                <div
                  className={cn(
                    props.className,
                    "flex items-start justify-start border-border border-b border-l data-[hovered]:bg-neutral-50/50",
                    !props.isWorkingHour && "bg-neutral-50"
                  )}
                >
                  <SlotItem {...props} />
                </div>
              )}
            </WeeklySlots>
          </ScrollArea>

          <NewBooking />
          <BookingDetail />
          <NewProposal />
          <ProposalDetail />
        </WeeklyRoot>
      </CalendarProvider>
    </FormProvider>
  )
}
