import {
  ref,
  uploadString,
  type FirebaseStorage,
  type StorageReference,
} from "firebase/storage"
import withResolvers from "promise.withresolvers"
import { LocalRecordingChunk, LocalRecordings } from "~/utils/recordings/local"
import {
  PersistentRecording,
  PersistentRecordingError,
  RecordingStatusResponse,
  RecordingSyncStatus,
  RecordingWriteOptions,
} from "~/utils/recordings/protocol"
import { RemoteChunk, RemoteRecordingChunked } from "~/utils/recordings/remote"
import {
  getSyncClient,
  RecordingSyncClient,
} from "~/utils/recordings/syncClient"
import { isoTimestamp } from "~/utils/timestamps"

/**
 * Remote recording sync using service worker. Fallback to blocking remote recording
 * when the service worker or indexeddb is not available.
 */
export class SyncLocalRemoteRecording implements PersistentRecording {
  recordingId: string
  usesBackgroundOperations: boolean = false
  accessPath: string

  // Fallback to remote upload in case there are errors with the
  // service worker or when trying to use the local IndexedDB.
  // We determine this on the first write.
  private remoteFallback: PersistentRecording
  private isFallback: boolean | undefined

  private isClosed: boolean

  // The service worker might not be available at the time of flush or write
  // This can be for several reasons, e.g. its not available in the browser or was not
  // registered at sign in. The fallback is to use blocking remote calls.
  private syncClient: RecordingSyncClient

  // Each recording has a status and each chunk has its own status
  private recordingStatus: RecordingSyncStatus
  private chunks: LocalRecordingChunk[]
  private writePromises: Promise<void>[]
  private currentChunkId: number

  private localRecordings: LocalRecordings

  constructor(
    storage: FirebaseStorage,
    userId: string,
    recordingId: string,
    contentType: string
  ) {
    this.remoteFallback = new RemoteRecordingChunked(
      storage,
      userId,
      recordingId,
      contentType
    )

    this.chunks = []
    this.writePromises = []
    this.currentChunkId = 0

    // use the local recording ID (they should be the same)
    this.recordingId = recordingId

    // define once we have checked that the service worker is available
    this.isFallback = undefined

    this.isClosed = false

    this.syncClient = getSyncClient()
    this.localRecordings = new LocalRecordings()

    const createdAt = new Date()

    this.accessPath = this.remoteFallback.accessPath

    this.recordingStatus = {
      kind: "recording-chunked",
      chunkId: -1,
      totalChunks: undefined,
      recordingId,
      bytesWritten: 0,
      bytesSynced: 0,
      totalBytes: undefined,
      remotePath: this.accessPath,
      state: "init",
      createdAt,
      updatedAt: createdAt,
      contentType,
    }
  }

  async write(data: Blob, options?: RecordingWriteOptions): Promise<void> {
    // Wraps the write function to save the promise
    // TODO: use withResolvers when browser support has matured
    // 2025-03: All browsers implemented in 2024-03 (safari was last)
    const { promise, resolve, reject } = withResolvers()

    // Note: Always insert the promise before executing _write since it has
    // internal async calls resulting in more jobs on the event loop and possible
    // interleaving with close()
    this.writePromises.push(promise)

    try {
      await this._write(data, options)
      resolve()
    } catch (err) {
      if (err instanceof Error) {
        reject(err)
      }
    }

    return promise
  }

  async _write(data: Blob, options?: RecordingWriteOptions): Promise<void> {
    if (this.isClosed) {
      return Promise.reject(
        new PersistentRecordingError("Write failed, recording already closed")
      )
    }

    if (this.isFallback === undefined) {
      this.isFallback = !(await this.syncClient.isActive())
    }

    try {
      // Write the chunk to local state (indexeddb)
      // We always do this, treating the local data as a write-through cache in case
      // something goes wrong with the sync to remote storage

      // Add a chunk to the active chunks being written and increment the chunkId
      const chunkId = this.currentChunkId
      this.currentChunkId += 1

      const chunk = new LocalRecordingChunk(this.recordingId, chunkId)

      await chunk.write(data)
      // We flush the chunk to ensure the write has been persisted locally and then
      // close it to indicate that there wont be more writes
      await chunk.close()

      this.chunks.push(chunk)

      // Update the recording metadata
      this.recordingStatus = await this.localRecordings.writeStatus(
        this.recordingId,
        (status) => {
          if (status === undefined) {
            status = this.recordingStatus
          }

          return {
            ...status,
            bytesWritten: status.bytesWritten + data.size,
            state: "streaming",
            updatedAt: new Date(),
            lastWriteAt: new Date(),
            writeOptions: options,
          }
        }
      )
    } catch {
      console.debug(
        isoTimestamp(),
        "[SyncLocalRemoteRecording] Failed while writing the recording data locally"
      )

      if (this.currentChunkId == 1) {
        // indicate that we should use the fallback since
        // there was a failure on the first write
        this.isFallback = true
      }
    }

    // Trigger a sync request at the service worker to make sure it wakes up when we are writing
    if (!this.isFallback) {
      try {
        void this.syncClient.call({
          kind: "recording-sync-init-request",
          payload: { recordingId: this.recordingId },
        })
      } catch {
        console.debug(
          isoTimestamp(),
          "[SyncLocalRemoteRecording] Failed to init sync while writing"
        )

        // make sure we use fallback instead since the sync client fails
        this.isFallback = true
      }
    }

    // Make sure we indicate that from now on this persistent recording
    // can use background operations (via the service worker)
    // When we use fallback, we want the writes to be in the foreground
    this.usesBackgroundOperations = !this.isFallback

    if (this.isFallback) {
      // Failed to get a service worker or failed indexeddb write, so falling back to blocking upload
      console.debug(
        isoTimestamp(),
        "[SyncLocalRemoteRecording] Falling back to blocking remote sync for recording",
        this.recordingId,
        "chunk",
        this.currentChunkId
      )

      await this.remoteFallback.write(data)

      const remoteStatus = await this.remoteFallback.status()
      this.recordingStatus = {
        ...this.recordingStatus,
        bytesSynced: remoteStatus.bytesSynced,
      }
    }
  }

  async status(): Promise<RecordingSyncStatus> {
    if (this.isFallback) {
      const remoteStatus = await this.remoteFallback.status()

      this.recordingStatus = {
        ...this.recordingStatus,
        bytesSynced: remoteStatus.bytesSynced,
        state: remoteStatus.state,
      }

      return this.recordingStatus
    }

    const response: RecordingStatusResponse = await this.syncClient.call({
      kind: "recording-status-request",
      payload: { recordingId: this.recordingId },
    })

    if (response.payload === undefined) {
      throw new PersistentRecordingError(
        `Could not retrieve status for recording ${this.recordingId}`
      )
    }

    return response.payload
  }

  private async updateCloseMetadata(): Promise<void> {
    const totalChunks = this.chunks.length
    const totalBytes = await this.chunks.reduce(async (accP, c) => {
      const status = await c.status()
      const acc = await accP
      // NOTE: The chunk totalbytes must be defined since this is called when all chunks are written
      return acc + (status.totalBytes || 0)
    }, Promise.resolve(0))

    this.recordingStatus = await this.localRecordings.writeStatus(
      this.recordingId,
      (status) => {
        return {
          ...status,
          state: "stored",
          totalChunks,
          totalBytes,
          updatedAt: new Date(),
        }
      }
    )
  }

  async close(): Promise<void> {
    // make sure all writes are done before we start closing to avoid any concurrency issues
    // since we are using async/await calls inside the write function when calling indexeddb,
    // it can happen that parts of this function is scheduled ahead of code in write
    await Promise.all(this.writePromises)

    this.isClosed = true

    await this.updateCloseMetadata()

    if (this.isFallback) {
      return this.remoteFallback.close()
    }

    try {
      // When close is called, we trigger a sync on the service worker
      // If this fails, the recording data should be in local storage
      // and will be picked up the next time the service worker runs the
      // periodic sync process.
      await this.syncClient.call({
        kind: "recording-sync-init-request",
        payload: { recordingId: this.recordingId },
      })
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.warn(
          isoTimestamp(),
          "Unknown error when calling sync client init in close",
          err.message
        )

        throw new PersistentRecordingError(
          `Unknown error when calling sync client in close, ${err.message}`
        )
      }
    }
  }

  async delete(): Promise<void> {
    // Make sure to propagate isClosed to the local and remote before deleting
    if (!this.isClosed) {
      return Promise.reject(
        new PersistentRecordingError(
          "Recording is not closed, cannot be deleted while writing"
        )
      )
    }

    // Delete the local recordings in case we have been using a fallback
    // otherwise this is taken care of by the service worker when the data
    // has been synced.
    if (this.isFallback) {
      await this.localRecordings.delete(this.recordingId)
    }
  }
}

// This class is used to take a local chunk and sync it to the remote storage
// ensuring that the chunkId and other status metadata are correct.
export class SyncLocalRemoteChunks {
  private recordingId: string
  private storageRef: StorageReference
  private localRecordings: LocalRecordings

  constructor(
    userId: string,
    localStatus: RecordingSyncStatus,
    localRecordings: LocalRecordings,
    storage: FirebaseStorage
  ) {
    this.recordingId = localStatus.recordingId

    this.storageRef = ref(storage, localStatus.remotePath)

    this.localRecordings = localRecordings
  }

  async transfer(
    chunkStatus: RecordingSyncStatus
  ): Promise<RecordingSyncStatus> {
    if (chunkStatus.chunkId === undefined) {
      throw new PersistentRecordingError(
        `Could not create a sync remote chunk for recording ${this.recordingId}, missing chunk id`
      )
    }
    const chunkId = chunkStatus.chunkId

    const chunk = new RemoteChunk(this.storageRef, this.recordingId, chunkId)

    const data = await this.localRecordings.getChunk(this.recordingId, chunkId)

    if (data === undefined) {
      throw new PersistentRecordingError(
        `Could not find chunk data for ${this.recordingId}/${chunkId}`
      )
    }
    await chunk.write(data)
    await chunk.close()

    return chunk.status()
  }

  async finalize(status: RecordingSyncStatus): Promise<void> {
    // update the remote metadata entry
    const metadataRef = ref(this.storageRef, "metadata.json")
    await uploadString(metadataRef, JSON.stringify(status), "raw", {
      contentType: "application/json",
    })
  }
}
