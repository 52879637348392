import { z } from "zod"
import { Button } from "~/components/ui"
import { ChatInputForm, ChatInputFormSchema } from "./ChatInputForm"

export interface NoMessagesProps {
  onSubmit: (data: z.infer<typeof ChatInputFormSchema>) => Promise<void>
}

export default function NoMessages({ onSubmit }: NoMessagesProps) {
  const handleDefaultMessage = (message: string) => {
    void onSubmit({ message })
  }

  return (
    <div className="flex-2 flex flex-col space-y-4 h-full w-full justify-center items-center">
      <p className="text-primary-black text-[1.5rem] font-bold">
        Hi! What can I help you with?
      </p>
      <p className="text-primary-black text-[1.25rem]">
        Try one of below, or ask me anything!
      </p>
      <div className="flex flex-row gap-2">
        <Button
          onClick={() => {
            handleDefaultMessage(
              "How can I grow my Instagram as a therapist without spending hours on it?"
            )
          }}
          className="text-s text-white bg-slate-400 w-36 h-36 text-balance"
        >
          How can I grow my Instagram as a therapist without spending hours on
          it?
        </Button>
        <Button
          onClick={() => {
            handleDefaultMessage(
              "Can you write a social media post about mental health benefits of exercise?"
            )
          }}
          className="text-s text-white bg-slate-400 w-36 h-36 text-balance"
        >
          Can you write a social media post about mental health benefits of
          exercise?
        </Button>
        <Button
          onClick={() => {
            handleDefaultMessage(
              "What free tools can I use to create image and video content for social media?"
            )
          }}
          className="text-s text-white bg-slate-400 w-36 h-36 text-balance hidden sm:block"
        >
          What free tools can I use to create image and video content for social
          media?
        </Button>
      </div>
      <div className="flex flex-row w-full w-[600px] items-center justify-center">
        <ChatInputForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}
