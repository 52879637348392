import React, { useContext, useEffect } from "react"
import * as Sentry from "@sentry/react"
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router"
import "@fontsource/archivo/500.css"
import "@fontsource/platypi/500.css"
import "@fontsource/source-serif-pro"
import ErrorBoundary from "~/components/ErrorBoundary"
import PrivateRoute from "~/components/guards/PrivateRoute"
import { AuthProvider } from "~/context/AuthContext"
import { UserContext, UserProvider } from "~/context/UserContext"
import AuthPageLayout from "~/layouts/AuthPageLayout"
import Main from "~/layouts/Main"
import Account from "~/pages/Account/Account"
import SetupMFA from "~/pages/Account/SetupMFA"
import ActionHandler from "~/pages/Auth/ActionHandler"
import Login from "~/pages/Auth/Login"
import ResetPassword from "~/pages/Auth/ResetPassword"
import SignUp from "~/pages/Auth/SignUp"
import VerifyEmail from "~/pages/Auth/VerifyEmail"
import { Home } from "~/pages/Home"
import NoteDetails from "~/pages/Notes/NoteDetails"
import Notes from "~/pages/Notes/Notes"
import NoteTransformPreview from "~/pages/Notes/NoteTransformPreview"
import NoteTransformSelection from "~/pages/Notes/NoteTransformSelection"
import NotFound from "~/pages/NotFound"
import Recorder from "~/pages/Recorder/Recorder"
import Subscription from "~/pages/Subscription"
import { isSentryEnabled } from "./config/instrument"
import { Providers } from "./providers"
import "./pages/Account/account.css"
import "./pages/Notes/note.css"
import CrispChat from "./components/CrispChat"
import { BlockUI } from "./components/ui"
import Calendar from "./pages/Calendar"

const BrowserRoutes = isSentryEnabled()
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

const NUMBER_OF_NOTES_TO_REDIRECT_TO_HOME = 8

function App() {
  return (
    <ErrorBoundary>
      <CrispChat />
      <Router>
        <AuthProvider>
          <UserProvider>
            <Providers>
              <BrowserRoutes>
                <Route
                  path="/"
                  element={<PrivateRoute />}
                >
                  <Route
                    index
                    element={<RedirectUser />}
                  />
                  <Route
                    path="/home"
                    element={<Home />}
                  />
                  <Route
                    path="/recorder"
                    element={<Recorder />}
                  />
                  <Route element={<Main />}>
                    <Route
                      path="/account"
                      element={<Account />}
                    />
                    <Route
                      path="/setup-mfa"
                      element={<SetupMFA />}
                    />
                    <Route
                      path="/notes"
                      element={<Notes />}
                    />
                    <Route
                      path="/notes/:noteId"
                      element={<NoteDetails />}
                    />
                    <Route
                      path="/notes/:noteId/template-selection"
                      element={<NoteTransformSelection />}
                    />
                    <Route
                      path="notes/:noteId/template-preview"
                      element={<NoteTransformPreview />}
                    />
                    <Route
                      path="/calendar"
                      element={<Calendar />}
                    />
                  </Route>
                  <Route
                    path="*"
                    element={<NotFound />}
                  />
                </Route>
                <Route element={<AuthPageLayout />}>
                  <Route
                    path="/auth/action"
                    element={<ActionHandler />}
                  />
                  <Route
                    path="/login"
                    element={<Login />}
                  />
                  <Route
                    path="/sign-up"
                    element={<SignUp />}
                  />
                  <Route
                    path="/*"
                    element={<Navigate to="/sign-up" />}
                  />
                  <Route
                    path="/verify-email"
                    element={<VerifyEmail />}
                  />
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  />
                </Route>
                <Route
                  path="/subscription"
                  element={<Subscription />}
                />
                <Route
                  path="*"
                  element={<NotFound />}
                />
              </BrowserRoutes>
            </Providers>
          </UserProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  )
}

function RedirectUser() {
  const userContext = useContext(UserContext)
  const [state, setState] = React.useState("loading")

  useEffect(() => {
    if (!userContext) {
      return
    }

    const { user, loading } = userContext

    if (loading || !user) {
      return
    }

    const savedNotes = user.statistics?.totalSavedNotes

    if (savedNotes === undefined) {
      // Statistics are not available; show loading
      return
    }

    const hasRecordedNotes = savedNotes > 0
    if (savedNotes > NUMBER_OF_NOTES_TO_REDIRECT_TO_HOME) {
      setState("/home")
    } else {
      setState(hasRecordedNotes ? "/notes" : "/recorder")
    }
  }, [userContext])

  if (state === "loading") {
    return (
      <div className="bg-primary-cream-300 w-full h-full ">
        <div className="flex justify-center items-center h-full">
          <BlockUI isLoading />
          <p className="ml-[24px]">waiting for joy ...</p>
        </div>
      </div>
    )
  }

  return <Navigate to={state} />
}

export default App
