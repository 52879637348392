import * as React from "react"
import { DateTime } from "luxon"

export interface CalendarEvent<T = any> {
  id: number | string
  start: DateTime
  end: DateTime
  title: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  color?: string
  editable?: boolean
  deletable?: boolean
  draggable?: boolean
  allDay?: boolean
  raw?: T
}

export type ProcessedEvent<T = any> = CalendarEvent<T> & {
  [key: string]: any
}

export const parseDate = (date: Date | DateTime | string): DateTime => {
  if (date instanceof DateTime) return date
  if (date instanceof Date) DateTime.fromJSDate(date)
  return DateTime.fromISO(date as string)
}

export function indexEventsByDate(
  events: CalendarEvent[]
): Map<string, CalendarEvent[]> {
  const eventMap = new Map<string, CalendarEvent[]>()

  for (const event of events) {
    let currentDate = parseDate(event.start).startOf("day")
    const endDate = parseDate(event.end).endOf("day")

    while (currentDate <= endDate) {
      const eventDate = currentDate.toISODate()!

      if (!eventMap.has(eventDate)) {
        eventMap.set(eventDate, [])
      }

      eventMap.get(eventDate)!.push(event)

      // Move to the next day
      currentDate = currentDate.plus({ days: 1 })
    }
  }

  return eventMap
}

export function filterEventsForHour(
  events: ProcessedEvent[],
  hour: DateTime
): ProcessedEvent[] {
  const _events = events.filter((event) => {
    const start = parseDate(event.start)

    // Events from the previous day
    if (hour.hour === 0 && !start.hasSame(hour, "day")) {
      return true
    }

    return start.hasSame(hour, "hour")
  })

  return sortEventsByLengthest(_events)
}

export function slotHasEvents(
  hour: DateTime,
  events: ProcessedEvent[]
): boolean {
  const date = hour.toJSDate()!

  for (const event of events) {
    if (
      parseDate(event.start).toJSDate() <= date &&
      parseDate(event.end).toJSDate() > date
    ) {
      return true
    }
  }

  return false
}

export const sortEventsByLengthest = (events: ProcessedEvent[]) => {
  return events.sort((a, b) => {
    const aDiff =
      parseDate(a.end).toJSDate().getTime() -
      parseDate(a.start).toJSDate().getTime()
    const bDiff =
      parseDate(b.end).toJSDate().getTime() -
      parseDate(b.start).toJSDate().getTime()
    return bDiff - aDiff
  })
}
