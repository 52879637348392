import React from "react"
import { motion } from "framer-motion"
import { cn } from "./ui/utils"

interface LoadingDotsProps {
  className?: string
}

export const LoadingDots: React.FC<LoadingDotsProps> = ({ className }) => {
  const dotVariants = {
    animate: {
      opacity: [0.3, 1, 0.3], // Fade in and out
      transition: {
        repeat: Infinity, // Loop indefinitely
        duration: 1.5, // Total cycle time
        ease: "easeInOut",
      },
    },
  }

  return (
    <div
      className={cn("flex space-x-1 items-center justify-center", className)}
    >
      <motion.span
        className="inline-block w-1 h-1 bg-gray-600 rounded-full"
        variants={dotVariants}
        animate="animate"
      />
      <motion.span
        className="inline-block w-1 h-1 bg-gray-600 rounded-full"
        variants={dotVariants}
        animate="animate"
        transition={{ delay: 0.2 }} // Staggered delay for second dot
      />
      <motion.span
        className="inline-block w-1 h-1 bg-gray-600 rounded-full"
        variants={dotVariants}
        animate="animate"
        transition={{ delay: 0.4 }} // Staggered delay for third dot
      />
    </div>
  )
}
