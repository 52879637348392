import React from "react"
import { isMobile } from "react-device-detect"
import { useAnalytics } from "use-analytics"
import {
  Button,
  Drawer as Dialog,
  DrawerContent as DialogContent,
  DialogHeader,
  DialogTitle,
  DrawerTrigger as DialogTrigger,
} from "~/components/ui"

interface LLMIntroDialogProps {
  open?: boolean
  handleCloseDialog: () => void
  onOpenChange?: (isOpen: boolean) => void
}

export const LLMIntroDialog = ({
  open,
  handleCloseDialog,
  onOpenChange,
  children,
}: React.PropsWithChildren<LLMIntroDialogProps>) => {
  const { track } = useAnalytics()

  const handleIntroShown = async () => {
    void track("LLM Intro_shown")
    handleCloseDialog()
  }

  return (
    <Dialog
      shouldScaleBackground={false}
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open)
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        indicator
        aria-describedby=""
        className={`${isMobile ? "h-[80%]" : "h-[70%]"} after:!content-none md:inset-x-auto p-0 w-full`}
      >
        <DialogHeader className="sr-only">
          <DialogTitle>Intro to social media assistant</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col space-y-4 p-4 md:w-[960px] justify-center items-center space-y-2 h-full mb-4 mx-auto">
          <div
            className={`${isMobile ? "w-36 h-36" : "flex-1 w-48 h-48 md:w-64 md:h-64"}  bg-[url(/img/milo.jpg)] bg-cover bg-center`}
          ></div>
          <div className="flex flex-col space-y-4 items-center justify-center w-full">
            <p className="text-primary-black font-platypi text-[1.5rem] text-center">
              ✨ Meet Your Social Media Assistant, Milo!
            </p>
            <p className="text-primary-black text-[1rem] text-balance text-center">
              Milo is here to make social media effortless for your practice.
              From ready-to-post content and engagement tips to growth
              strategies, just ask, and Milo will do her best to help! 💙🚀
            </p>
            <div className="flex flex-row space-x-2">
              <Button onClick={handleIntroShown}>
                Let&apos;s give it a try!{" "}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LLMIntroDialog
