import * as React from "react"
import { Loader2, ZoomIn, ZoomOut } from "lucide-react"
import { Document, Page } from "react-pdf"
import Lightbox from "yet-another-react-lightbox"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  toast,
} from "~/components/ui"
import { useAttachmentDownloadURL } from "~/hooks/firestore/useClientAttachments"
// Styles for the PDF viewer
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "yet-another-react-lightbox/styles.css"

const MIN_SCALE = 0.75
const MAX_SCALE = 2.5

export function AttachmentPreviewModal({
  open,
  onOpenChange,
  attachment,
  originalFileName,
}) {
  const [scale, setScale] = React.useState(1.33)
  const [pageNumber, setPageNumber] = React.useState(1)

  const { data, isLoading, error } = useAttachmentDownloadURL({
    attachment,
  })

  React.useEffect(() => {
    if (error) {
      toast.error(error?.toString())
    }
  }, [error])

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <DialogContent
        aria-describedby=""
        className="w-full md:max-w-[920px] p-4 bg-gray-50"
      >
        <DialogHeader className="min-w-[1%] flex flex-row items-center justify-between gap-4 px-4 space-y-0">
          <DialogTitle className="grow text-base truncate">
            {originalFileName ?? attachment.name}
          </DialogTitle>

          <div className="shrink-0 flex items-center gap-1">
            <Button
              size="icon"
              variant="ghost"
              className="size-7 text-muted-foreground"
              disabled={scale === MIN_SCALE}
              onClick={() =>
                setScale((prev) => Math.max(MIN_SCALE, prev - 0.25))
              }
            >
              <ZoomOut className="size-4" />
            </Button>

            <Button
              size="icon"
              variant="ghost"
              className="size-7 text-muted-foreground"
              disabled={scale === MAX_SCALE}
              onClick={() =>
                setScale((prev) => Math.min(MAX_SCALE, prev + 0.25))
              }
            >
              <ZoomIn className="size-4" />
            </Button>
          </div>
        </DialogHeader>

        <div className="h-[85vh] overflow-auto">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader2 className="h-4 w-4 animate-spin mr-2 text-sm" />
              <span>Loading...</span>
            </div>
          ) : null}

          {!isLoading && data ? (
            <Document
              file={data.downloadURL}
              onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
            >
              {Array.from(new Array(pageNumber), (_el, index) => (
                <Page
                  key={`pdf_page_${index + 1}`}
                  scale={scale}
                  pageNumber={index + 1}
                  className="mx-auto w-fit shadow-md text-base"
                />
              ))}
            </Document>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export function ImagePreviewLightbox({ attachment, onClose }) {
  const { data, error } = useAttachmentDownloadURL({
    attachment,
  })

  React.useEffect(() => {
    if (error) {
      toast.error(error?.toString())
    }
  }, [error])

  if (!attachment) {
    return null
  }

  return (
    <Lightbox
      index={0}
      open={true}
      close={onClose}
      slides={[{ src: data?.downloadURL ?? "/mstile-150x150.png" }]}
      plugins={[Zoom]}
      carousel={{
        finite: true,
      }}
      controller={{
        closeOnPullDown: true,
        closeOnBackdropClick: true,
      }}
      render={{
        buttonPrev: () => null,
        buttonNext: () => null,
      }}
      styles={{
        root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" },
      }}
    />
  )
}
