import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { captureException } from "@sentry/react"
import { Timestamp } from "firebase/firestore"
import { DateTime } from "luxon"
import { useAnalytics } from "use-analytics"
import { z } from "zod"
import {
  Button,
  Drawer as Dialog,
  DrawerContent as DialogContent,
  DrawerHeader as DialogHeader,
  DrawerTitle as DialogTitle,
  DrawerTrigger as DialogTrigger,
  ScrollArea,
  SidebarProvider,
  toast,
  useMediaQuery,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import {
  useAssistantById,
  type Assistant,
} from "~/hooks/firestore/useAssistants"
import {
  useAddConversation,
  useAddMessage,
  useConversationById,
  useConversations,
  useDeleteConversation,
  useMessages,
  useUpdateConversation,
  type Conversation,
} from "~/hooks/firestore/useConversations"
import { useUserJourney } from "~/hooks/useUserJourney"
import { llm } from "~/services/firebase"
import { LoadingDots } from "../LoadingDots"
import { ChatInputForm, ChatInputFormSchema } from "./ChatInputForm"
import { ChatSidebar } from "./ChatSidebar"
import { convertMessagesToHistory, createTitleAsync } from "./chatUtils"
import LLMIntroDialog from "./LLMIntroDialog"
import NoMessages from "./NoMessages"
import { SafeMarkdown } from "./SafeMarkdown"

type TalkToJoyModalProps = {
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

const ASSISTANT_ID = "un6w4Bix2n7MU5KOnDXU" // TODO: Replace with dynamic data

export function LLMChatModal({
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<TalkToJoyModalProps>) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()
  const [userJourney, updateUserJourney, isPending] = useUserJourney()
  const [showIntro, setShowIntro] = useState<boolean>(true)
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const { data: assistant, isLoading: isLoadingAssistant } = useAssistantById({
    assistantId: ASSISTANT_ID,
    reactQuery: {
      enabled: isOpen,
    },
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isWaitingForReply, setIsWaitingForReply] = useState(false)

  const isSmallScreen = useMediaQuery("(max-width: 768px)")
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen)

  const chatRef = useRef<ReturnType<typeof llm.startChat>>()

  // Used to scroll the last user message to top of the viewport/Scroll area on a new message
  const lastUserMessageRef = useRef<HTMLDivElement>(null)

  const { mutateAsync: addConversationAsync } = useAddConversation({
    onSettled: (data, error) => {
      if (error) {
        captureException(error)
        toast.error("Could not create new conversation. Please try again.")
      }
    },
  })

  const fetchMessages = useMessages()
  const fetchConversation = useConversationById()

  const updateConversation = useUpdateConversation({
    onSettled: (_, error) => {
      if (error) {
        captureException(error)
        toast.error("Unable to update the conversation")
      }
    },
  })

  const deleteConversation = useDeleteConversation({
    onSettled: (_, error) => {
      if (error) {
        captureException(error)
        toast.error("Unable to delete conversation")
      }
    },
  })

  const addMessage = useAddMessage({
    onSettled: (_, error) => {
      if (error) {
        captureException(error)
        toast.error("Unable to add message")
      }
    },
  })

  const conversationsQuery = useConversations()
  const conversations = useMemo(
    () => conversationsQuery.data ?? [],
    [conversationsQuery.data]
  )

  type LLMMessage = {
    text: string
    sender: string
    id: string
  }

  const [messages, setMessages] = useState<LLMMessage[]>([])

  useEffect(() => {
    if (userJourney?.assistantsIntroduced) {
      setShowIntro(false)
    }
  }, [userJourney?.assistantsIntroduced, isPending])

  async function handleSendMessage(
    userText: string,
    conversation: Conversation
  ) {
    if (!chatRef.current) {
      return
    }

    try {
      // Save the user’s message in the conversation
      const messageId = await addMessage.mutateAsync({
        conversationId: conversation.id,
        message: {
          role: "user",
          parts: [{ type: "text", text: userText }],
        },
      })

      // Update the conversation’s updatedAt field
      await updateConversation.mutateAsync({
        conversationId: conversation.id,
        conversation: {
          ...conversation,
          updatedAt: Timestamp.fromDate(new Date()),
        },
      })

      const newMessage: LLMMessage = {
        text: userText,
        sender: "user",
        id: messageId,
      }
      setMessages((prev) => [...prev, newMessage])

      setIsWaitingForReply(true)

      // Call sendMessageStream on the existing chat instance
      const result = await chatRef.current.sendMessageStream(userText)

      setIsWaitingForReply(false)

      let text = ""

      // Save the model’s response in state
      const newResponse: LLMMessage = {
        text,
        sender: "model",
        id: "",
      }
      setMessages((prev) => [...prev, newResponse])

      // Iterate over the stream of responses from the model
      for await (const chunk of result.stream) {
        // Append chunk and replace line breaks with zero-width space
        text += chunk
          .text()
          .replace(/\r\n/g, "\n")
          .replace(/\n\n/g, "\n\u200B\n")
        // Update the last message in the Messages array
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1]
          return [
            ...prev.slice(0, prev.length - 1),
            {
              ...lastMessage,
              text,
            },
          ]
        })
      }

      // Save the model’s response in the conversation
      let textToStore = text.replace(/\n\u200B\n/g, "\n\n")
      if (textToStore.length === 0) {
        textToStore = "No response..."
      }
      const modelMessageId = await addMessage.mutateAsync({
        conversationId: conversation.id,
        message: {
          role: "model",
          parts: [{ type: "text", text: textToStore }],
        },
      })

      // Update last message in the Messages array with the allocated id
      setMessages((prev) => {
        const lastMessage = prev[prev.length - 1]
        return [
          ...prev.slice(0, prev.length - 1),
          {
            ...lastMessage,
            id: modelMessageId,
          },
        ]
      })

      // Set the full response text
      newResponse.text = text

      // If conversation doesn't have a title, then create it
      if (conversation && !conversation?.title) {
        const title = await createTitleAsync(
          [newMessage, newResponse].map((message) => {
            return {
              role: message.sender,
              parts: [{ type: "text", text: message.text }],
            }
          })
        )
        conversation.title = title
        await updateConversation.mutateAsync({
          conversationId: conversation.id,
          conversation: {
            title,
            ...conversation,
          },
        })
      }

      setSidebarOpen(false)
    } catch (error) {
      // TODO: Add validation of the conversation messages here.
      captureException(error)
      toast.error("An error occurred. Please try again.")
    } finally {
      setIsWaitingForReply(false)
      void track("LLM Message_sent", { assistantId: assistant?.id })
    }
  }

  const initiateLLM = useCallback(
    async (assistant: Assistant, conversationId: string) => {
      if (!assistant || !conversationId || !currentUser?.uid) {
        return
      }

      const messages = await fetchMessages(conversationId)

      const history = convertMessagesToHistory(messages)

      chatRef.current = llm.startChat({
        systemInstruction: {
          role: "system",
          parts: [
            {
              text: assistant.systemPrompt,
            },
            {
              text: `Today's date is ${DateTime.fromMillis(
                Date.now()
              ).toLocaleString(DateTime.DATE_FULL)}.`,
            },
          ],
        },
        history: history,
        generationConfig: {
          maxOutputTokens: 8192, // Maximum number of tokens to generate
        },
      })

      // Replace the existing messages with the conversation history
      setMessages(
        history.map((message) => ({
          text: message.parts.map((part) => part.text).join(" "),
          sender: message.role,
          id: "",
        }))
      )
    },
    [currentUser?.uid, fetchMessages]
  )

  useEffect(() => {
    if (chatRef.current || !assistant || !conversation) {
      return
    }
    const init = async (assistant, conversation) => {
      try {
        await initiateLLM(assistant, conversation)
      } catch (error) {
        captureException(error)
        toast.error("Could not initiate assistant. Please try again.")
      }
    }
    void init(assistant, conversation.id)
  }, [assistant, conversation, chatRef, initiateLLM])

  const lastUserMessageIndex = messages.reduce(
    (last, msg, i) => (msg.sender === "user" ? i : last),
    -1
  )

  useEffect(() => {
    if (lastUserMessageRef.current) {
      lastUserMessageRef.current.scrollIntoView({ behavior: "auto" })
    }
  }, [messages])

  function handleSubmitMessage(data: z.infer<typeof ChatInputFormSchema>) {
    if (!conversation) {
      return Promise.resolve()
    }

    return handleSendMessage(data.message, conversation)
  }

  async function createNewConversation(): Promise<Conversation> {
    if (!assistant) {
      throw new Error("No assistant found")
    }

    const newConversation = await addConversationAsync({
      assistantId: assistant.id,
    })

    await initiateLLM(assistant, newConversation.id)

    void track("LLM New_conversation", { assistantId: assistant.id })
    return newConversation
  }

  async function handleSubmitNewConversationMessage(
    data: z.infer<typeof ChatInputFormSchema>
  ) {
    try {
      const newConversation = await createNewConversation()
      setConversation(newConversation)

      await handleSendMessage(data.message, newConversation)
    } catch (error) {
      captureException(error)
      toast.error("An error occurred. Please try again.")
    }
  }

  function clearCurrentConversation() {
    setMessages([])
    setConversation(null)
  }

  function handleNewConversation() {
    void track("LLM New_conversation_clicked")
    clearCurrentConversation()
  }

  function handleCloseIntro() {
    setShowIntro(false)
    void updateUserJourney({ assistantsIntroduced: true })
  }

  const handleChangeConversation = async (conversationId: string) => {
    if (!assistant) {
      return
    }
    try {
      void track("LLM Change_conversation")
      setIsLoadingData(true)
      const conversation = await fetchConversation(conversationId)
      setConversation(conversation)

      await initiateLLM(assistant, conversationId)
    } catch (error) {
      captureException(error)
      toast.error("An error occurred. Please try again.")
    } finally {
      setIsLoadingData(false)
    }
  }

  const handleDeleteConversation = async (conversationId: string) => {
    try {
      void track("LLM Delete_conversation")
      await deleteConversation.mutateAsync({ conversationId })
      clearCurrentConversation()
    } catch (error) {
      captureException(error)
      toast.error("An error occurred. Please try again.")
    }
  }

  if (showIntro && isOpen) {
    return (
      <LLMIntroDialog
        open={isOpen}
        onOpenChange={onOpenChange}
        handleCloseDialog={handleCloseIntro}
      />
    )
  }

  return (
    <Dialog
      shouldScaleBackground={false}
      open={isOpen}
      onOpenChange={(open) => {
        onOpenChange?.(open)
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        indicator
        aria-describedby="chat-with-assistant"
        className={"h-[90%] inset-x-0 md:inset-x-auto p-0 w-full"}
      >
        <DialogHeader className="sr-only">
          <DialogTitle>Chat with assistant</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col w-full md:max-w-[960px] mx-auto h-full">
          <div className="flex-1 flex flex-col w-full min-h-0 pointer-events-auto select-text h-full">
            <div className="flex-1 flex flex-col w-full h-full items-center">
              <SidebarProvider
                className="relative items-start h-full p-0 m-0"
                open={sidebarOpen}
                onOpenChange={setSidebarOpen}
              >
                <ChatSidebar
                  conversations={conversations}
                  activeConversationId={conversation?.id ?? ""}
                  onNewConversation={handleNewConversation}
                  onSelectConversation={handleChangeConversation}
                  onDeleteConversation={handleDeleteConversation}
                  collapsible="icon"
                  variant="sidebar"
                  className="p-0 m-0"
                />
                <div className="flex-1 flex items-center flex-col w-full h-full">
                  <div className="flex flex-col w-full max-w-[768px] h-full p-0 m-0">
                    <Button
                      variant={"outline"}
                      onClick={() => {
                        setSidebarOpen(!sidebarOpen)
                      }}
                      className="justify-start md:hidden hover:bg-transparent bg-[url(/img/milo-thumb.png)] bg-cover bg-center transition-all duration-150 ease-in-out w-[42px] h-[42px] min-w-[42px] min-h-[42px] mx-2 my-1"
                    />
                    {isLoadingData || isLoadingAssistant ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full h-full p-0 m-0 justify-between">
                        {messages.length === 0 && (
                          <NoMessages
                            onSubmit={handleSubmitNewConversationMessage}
                          />
                        )}

                        <ScrollArea className="flex-1 w-full min-h-0 p-2 border-r">
                          <div className="flex flex-col w-full gap-2 sm:gap-4 px-2 sm:px-4 mx-2 mb-32">
                            {messages.map((message, index) =>
                              message.sender === "user" ? (
                                <div
                                  key={index}
                                  ref={
                                    index === lastUserMessageIndex
                                      ? lastUserMessageRef
                                      : null
                                  }
                                  className="flex flex-row w-full justify-end mb-4 pointer-events-auto select-text"
                                >
                                  <p
                                    className={`text-primary-black font-sourceSerifPro text-[1rem] bg-primary-cream-300 rounded-3xl py-4 px-4`}
                                  >
                                    {message.text}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="w-full mb-4 pointer-events-auto select-text"
                                >
                                  <SafeMarkdown md={message.text} />
                                </div>
                              )
                            )}
                            {isWaitingForReply && (
                              <div className="flex justify-start w-full mx-4">
                                <LoadingDots />
                              </div>
                            )}
                          </div>
                        </ScrollArea>
                        {messages.length > 0 && (
                          <div className="sticky mb-2 py-2 px-1 z-10 bottom-0">
                            <ChatInputForm
                              onSubmit={handleSubmitMessage}
                              placeholder="Message Milo"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </SidebarProvider>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
