import * as React from "react"
import { useMutation } from "@tanstack/react-query"
import { Copy, Share, X } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { isMobile } from "react-device-detect"
import { useFormContext } from "react-hook-form"
import { Link } from "react-router"
import { useAnalytics } from "use-analytics"
import ClientModal from "~/components/ClientModal"
import { Button, FormField, FormItem, toast } from "~/components/ui"
import { WeeklyFooter } from "~/components/weekly-calendar"
import { useClientById } from "~/hooks/firestore/useClients"
import { useCreateProposal } from "~/hooks/firestore/useProposals"
import { omit } from "~/utils/helperFunctions"
import { useCalendarStore } from "./store"
import {
  INITIAL_VALUES,
  MAX_PROPOSAL_SLOTS,
  shareUrlFromProposalId,
  useCalendarFormSlotsValue,
  type FromSchemaValues,
} from "./utils"

async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (error) {
    console.warn("Copy failed", error)
    return false
  }
}

const ProposalClose = ({ onClose }) => {
  return (
    <button
      type="button"
      className="absolute top-1 right-0 rounded-sm ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
      onClick={() => onClose()}
    >
      <X className="size-6" />
      <span className="sr-only">Close</span>
    </button>
  )
}

interface ClientData {
  name?: string
  phone?: string
  email?: string

  [key: string]: string | undefined
}

export function NewProposal() {
  const { track } = useAnalytics()

  const { reset, control, getValues, setValue, handleSubmit } =
    useFormContext<FromSchemaValues>()

  const clientName = getValues("clientName")
  const clientId = getValues("clientId") ?? ""

  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: Boolean(clientId),
    },
  })

  const [shareUrl, setShareUrl] = React.useState("")
  const [proposeId, setProposeId] = React.useState("")
  const [showClientModal, setShowClientModal] = React.useState(false)

  // Use native share if available and on mobile
  const isShareAvailable =
    isMobile &&
    navigator.canShare &&
    navigator.canShare({
      title: "title",
      text: "text",
      url: "https://joy.day",
    })

  const { selectMode, setSelectMode, selectedSlot, setSelectedSlot } =
    useCalendarStore((state) => ({
      selectMode: state.selectMode,
      setSelectMode: state.setSelectMode,
      selectedSlot: state.selectedSlot,
      setSelectedSlot: state.setSelectedSlot,
    }))

  const slots = useCalendarFormSlotsValue()

  const createProposal = useCreateProposal({
    onError: (error) => {
      console.error(error)
      toast.error("Failed to create proposal")
    },
    onSuccess: (data) => {
      setShareUrl(shareUrlFromProposalId(data?.id ?? ""))
      setProposeId(data?.id ?? "")
    },
  })

  const existProposalMode = () => {
    reset({ ...INITIAL_VALUES })
    setShareUrl("")
    setProposeId("")
    setSelectMode("event")
  }

  const shareMutate = useMutation({
    mutationFn: async ({
      title,
      text,
      url,
    }: {
      title: string
      text: string
      url: string
    }) => {
      if (isShareAvailable) {
        window?.navigator
          ?.share?.({
            title,
            text,
            url,
          })
          .catch((e) => console.warn(e))
      }

      return { title, text, url }
    },
    onError: (e) => {
      toast.error(e?.message || "Failed to share proposal")
    },
    onSuccess: () => {
      // close the modal
      existProposalMode()
      setSelectedSlot(null)

      void track("Proposal Shared")
    },
  })

  // Propose mode only
  if (!selectedSlot || selectMode !== "propose") {
    return null
  }

  const isMaxSlots = slots?.length >= MAX_PROPOSAL_SLOTS

  const onSubmit = async (data: FromSchemaValues) => {
    if (!selectedSlot || !data?.clientId || !data?.slots?.length) {
      return
    }

    const { clientId, slots, duration } = data
    const expiresAt = selectedSlot.plus({ hours: 48 }).toUTC()

    await createProposal.mutateAsync({
      clientId,
      isoStartDateTimes: slots.map(
        (slot) => DateTime.fromISO(slot).toUTC().toISO()!
      ),
      isoDuration: Duration.fromObject({ minutes: duration }).toISO() ?? "",
      isoExpiresAt: expiresAt.toISO()!,
    })
  }

  const clientData = omit((client.data ?? {}) as Record<string, unknown>, [
    "id",
  ]) as ClientData

  return (
    <>
      {!proposeId ? (
        <>
          <header className="absolute z-[100] top-0 inset-0 px-4 py-2 h-[48px] bg-primary text-primary-foreground shadow-md">
            <div className="relative flex items-center justify-center h-full w-full">
              <h3 className="font-[Platypi] text-xl md:text-2xl font-medium">
                {isMaxSlots ? "Max slots selected" : "Pick times to propose"}
              </h3>

              <ProposalClose onClose={existProposalMode} />
            </div>
          </header>

          <div className="absolute z-[100] bottom-4 left-1/2 -translate-x-1/2">
            <FormField
              name="clientId"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <ClientModal
                    sessionId={"__sessionId__"}
                    isOpen={showClientModal}
                    onOpenChange={setShowClientModal}
                    onClientSet={async (clientId, client) => {
                      field.onChange(clientId)
                      setValue("clientName", client?.name ?? "")

                      // Submit the form after the client is set
                      setTimeout(() => handleSubmit(onSubmit)(), 1)
                    }}
                  />

                  <Button
                    variant={"secondary"}
                    className="rounded-3xl w-[280px] truncate"
                    isLoading={createProposal.isPending}
                    disabled={slots?.length === 0 || createProposal.isPending}
                    onClick={(event) => {
                      event.stopPropagation()
                      setShowClientModal(true)
                    }}
                  >
                    {!field.value
                      ? "Choose client"
                      : (getValues("clientName") ?? "Change client")}
                  </Button>
                </FormItem>
              )}
            />
          </div>
        </>
      ) : null}

      <WeeklyFooter
        title={clientName ? `Send proposal to ${clientName}` : "Send proposal"}
        open={!!proposeId}
        onOpenChange={(open) => {
          if (!open) {
            existProposalMode()
            setSelectedSlot(null)
          }
        }}
      >
        {proposeId ? (
          <div className="space-y-4">
            <div>
              <p className="text-sm font-medium mb-2">Proposal text</p>

              <div className="p-4 border rounded-lg shadow-md">
                <p>
                  Hi, <br />
                  Click on the link to select a time for your next session.
                </p>

                <Link
                  to={shareUrl}
                  target="_blank"
                  className="hover:underline"
                >
                  {shareUrl}
                </Link>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {!isShareAvailable && !clientData.email && !clientData.phone && (
                <Button
                  variant="secondary"
                  className="rounded-3xl min-w-[150px] md:min-w-[200px]"
                  onClick={(e) => {
                    e.preventDefault()
                    shareMutate.mutate({
                      title: "Select a time for your next session",
                      text: `Hi, Click on the link to select a time for your next session.`,
                      url: shareUrl,
                    })
                  }}
                >
                  <Share />
                  Share
                </Button>
              )}

              {clientData.email && (
                // Send email with link if the clientData has an email
                <Button
                  variant="secondary"
                  className="rounded-3xl min-w-[150px] md:min-w-[200px]"
                  onClick={() => {
                    window.location.href = `mailto:${clientData.email}?subject=Proposal Link&body=Hi,%0D%0AClick on the link to select a time for your next session: ${shareUrl}`
                  }}
                >
                  Email proposal to {clientData.email}
                </Button>
              )}

              {
                // Send text message with link if the clientData has a phone number
                clientData.phone && (
                  <Button
                    variant="secondary"
                    className="rounded-3xl min-w-[150px] md:min-w-[200px] select-auto"
                    onClick={() => {
                      window.location.href = `sms:${clientData.phone}?body=Hi, Click on the link to select a time for your next session: ${shareUrl}`
                    }}
                  >
                    Text proposal to {clientData.phone}
                  </Button>
                )
              }

              <Button
                variant="secondary"
                className="rounded-3xl min-w-[150px] md:min-w-[200px]"
                onClick={() => {
                  void copyToClipboard(
                    `Hi,\nClick on the link to select a time for your next session: \n${shareUrl}`
                  ).then(() => {
                    toast.success("Proposal copied to clipboard")

                    existProposalMode()
                    setSelectedSlot(null)
                  })
                }}
              >
                <Copy />
                Copy to clipboard
              </Button>
            </div>
          </div>
        ) : null}
      </WeeklyFooter>
    </>
  )
}
