import { useEffect } from "react"
import { Trash2Icon } from "lucide-react"
import type { Conversation } from "~/hooks/firestore/useConversations"
import { DialogTitle } from "../ui/dialog"
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  useSidebar,
} from "../ui/sidebar"
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip"
import { cn } from "../ui/utils"
import { groupConversationsByAssistant } from "./groupConversations"

interface ChatSidebarProps extends React.ComponentProps<typeof Sidebar> {
  conversations: Conversation[]
  activeConversationId: string | null
  onNewConversation: () => void
  onSelectConversation: (id: string) => void
  onDeleteConversation: (id: string) => void
}

export function ChatSidebar({
  conversations,
  activeConversationId,
  onNewConversation,
  onSelectConversation,
  onDeleteConversation,
  ...sidebarProps
}: ChatSidebarProps) {
  const { isMobile, isSmallScreen, open, setOpen, setOpenMobile } = useSidebar()
  const groupedConversations = groupConversationsByAssistant(
    conversations,
    activeConversationId
  )

  const handleClick = (id: string) => {
    if (isMobile || isSmallScreen) {
      setOpenMobile(false)
      setOpen(false)
    }
    onSelectConversation(id)
  }

  const handleToggleSidebar = () => {
    if (isMobile || isSmallScreen) {
      setOpenMobile(!open)
      setOpen(!open)
    } else {
      setOpen(!open)
    }
  }

  const handleNewConversation = () => {
    if (isMobile || isSmallScreen) {
      setOpenMobile(false)
      setOpen(false)
    } else {
      setOpen(false)
    }
    onNewConversation()
  }

  useEffect(() => {
    if (groupedConversations.length === 0) {
      if (isMobile || isSmallScreen) {
        setOpen(false)
        setOpenMobile(false)
      } else {
        setOpen(false)
      }
    }
  }, [
    groupedConversations.length,
    isMobile,
    isSmallScreen,
    setOpen,
    setOpenMobile,
  ])

  return (
    <Sidebar {...sidebarProps}>
      <Tooltip>
        <TooltipTrigger asChild>
          <SidebarHeader
            onClick={handleToggleSidebar}
            className={cn(
              "z-30 cursor-pointer bg-[url(/img/milo-thumb.png)] bg-cover bg-center transition-all duration-150 ease-in-out",
              open ? "w-[48px] h-[48px]" : "w-[60px] h-[60px]"
            )}
          />
        </TooltipTrigger>
        <TooltipContent side="right">
          {open ? "Hide" : "Show history"}
        </TooltipContent>
      </Tooltip>
      <DialogTitle>
        <span className="sr-only">Conversations</span>
      </DialogTitle>
      <SidebarContent aria-describedby="conversations">
        {groupedConversations.map((group) => (
          <SidebarGroup
            key={group.title}
            className="group-data-[collapsible=icon]:hidden"
          >
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem key={"new"}>
                  <SidebarMenuButton
                    asChild
                    onClick={handleNewConversation}
                    variant={"outline"}
                    className="cursor-pointer"
                  >
                    <span>New topic</span>
                  </SidebarMenuButton>
                </SidebarMenuItem>
                {group.items.map((subItem) => (
                  <SidebarMenuItem key={subItem.id}>
                    <SidebarMenuButton
                      asChild
                      isActive={subItem.isActive}
                      onClick={() => {
                        handleClick(subItem.id)
                      }}
                      className={`cursor-pointer w-[calc(100%-2rem)]`}
                    >
                      <p
                        className={`truncate ${subItem.isActive ? "font-bold" : ""}`}
                      >
                        {subItem.isActive ? "> " : ""}
                        {subItem.title}
                      </p>
                    </SidebarMenuButton>
                    <SidebarMenuAction
                      showOnHover
                      onClick={() => {
                        onDeleteConversation(subItem.id)
                      }}
                    >
                      <Trash2Icon className="text-red-500" />
                      <span className="sr-only">Delete</span>
                    </SidebarMenuAction>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        ))}
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  )
}
/*  <SidebarRail /> */
