import { useEffect, useState } from "react"
import { captureMessage } from "@sentry/browser"
import { sentryDefaultTags } from "~/config/instrument"

type PermissionStatus = PermissionState | "unknown"

/**
 * useMicrophonePermission
 *
 * A React hook that manages microphone permissions in the browser.
 *
 * This hook handles:
 * - Checking the current microphone permission status
 * - Requesting microphone access from the user
 * - Tracking permission state changes
 * - Handling errors related to microphone access
 * - Notifying the parent component of permission changes and errors
 *
 * @param {Object} options - Configuration options
 * @param {Function} options.onPermissionGranted - Callback triggered when microphone permission is granted
 * @param {Function} [options.onPermissionChange] - Optional callback triggered when permission state changes
 * @param {Function} [options.onPermissionError] - Optional callback triggered when an error occurs
 *
 * @returns {Object} Permission state and controls
 * @returns {PermissionStatus} .permissionStatus - Current permission state ('granted', 'denied', 'prompt', or 'unknown')
 * @returns {string|null} .error - Error message if something went wrong, null otherwise
 * @returns {boolean} .isChecking - Whether the hook is currently checking permissions
 * @returns {boolean} .isRequesting - Whether the hook is currently requesting permissions
 * @returns {Function} .requestMicPermission - Function to request microphone access
 */

interface UseMicrophonePermissionProps {
  onPermissionGranted: () => void
  onPermissionChange?: (permission: PermissionStatus) => void
  onPermissionError?: (error: string) => void
}

const useMicrophonePermission = ({
  onPermissionGranted,
  onPermissionChange,
  onPermissionError,
}: UseMicrophonePermissionProps) => {
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionStatus>("unknown")
  const [error, setError] = useState<string | null>(null)
  const [isChecking, setIsChecking] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)

  // Notify parent component of permission changes
  useEffect(() => {
    onPermissionChange?.(permissionStatus)
  }, [permissionStatus, onPermissionChange])

  // Notify parent component of errors
  useEffect(() => {
    if (error && onPermissionError) {
      onPermissionError(error)
    }
  }, [error, onPermissionError])

  // Check current microphone permission status
  const checkMicPermission = async () => {
    if (!navigator.permissions || !navigator.permissions.query) {
      setPermissionStatus("unknown")
      setIsChecking(false)
      return
    }

    try {
      const permission = await navigator.permissions.query({
        name: "microphone" as PermissionName,
      })
      setPermissionStatus(permission.state)

      if (permission.state === "granted") {
        onPermissionGranted()
      }

      // Listen for permission changes
      permission.onchange = () => {
        setPermissionStatus(permission.state)
        if (permission.state === "granted") {
          onPermissionGranted()
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        captureMessage(`Failed to check microphone permission`, {
          level: "warning",
          tags: {
            ...sentryDefaultTags,
            error: err.message,
          },
        })
      }
      setPermissionStatus("unknown")
    } finally {
      setIsChecking(false)
    }
  }

  // Request microphone permission
  const requestMicPermission = async () => {
    setError(null)
    setIsRequesting(true)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })
      setPermissionStatus("granted")
      stream.getTracks().forEach((track) => track.stop())
      onPermissionGranted()
    } catch (err: unknown) {
      if (err instanceof Error) {
        if (err.name === "NotAllowedError") {
          setPermissionStatus("denied")
        } else if (err.name === "NotFoundError") {
          setError("No microphone found. Please connect a microphone.")
        } else {
          setError(`Error requesting microphone access: ${err.message}`)
          captureMessage(`Error requesting microphone access`, {
            level: "error",
            tags: {
              ...sentryDefaultTags,
              error: err.message,
            },
          })
        }
      }
    } finally {
      setIsRequesting(false)
    }
  }

  // Initial permission check on mount
  useEffect(() => {
    void checkMicPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    permissionStatus,
    error,
    isChecking,
    isRequesting,
    requestMicPermission,
  }
}

export default useMicrophonePermission
