import type { StorageReference } from "@firebase/storage"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage"
import sanitizeFilename from "sanitize-filename"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { storage } from "~/services/firebase"

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const SUPPORTED_MIME_TYPES = new Set([
  "application/pdf",
  "image/jpeg",
  "image/png",
])

const isNameStartWithUUID = (str: string) => {
  if (str.length < 36) return false
  return UUID_REGEX.test(str.split(".")?.[0] ?? "")
}

interface UploadAttachmentParams {
  file: File
  userId: string
  clientId: string
  attachmentId?: string
}

const validatePdf = (file: File) => {
  if (!SUPPORTED_MIME_TYPES.has(file.type)) {
    throw new Error("Invalid file type, only PDF, JPEG, and PNG are supported.")
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error("File is too large. Max size is 10MB.")
  }
}

export const uploadAttachment = ({
  file,
  userId,
  clientId,
  attachmentId,
}: UploadAttachmentParams): Promise<{
  name: string
  attachmentId: string
}> => {
  return new Promise((resolve, reject) => {
    const fileName = sanitizeFilename(file.name)

    if (!attachmentId) {
      attachmentId = `${crypto.randomUUID()}.${fileName}`
    }

    const storageRef = ref(
      storage,
      `users/${userId}/clients/${clientId}/attachments/${attachmentId}`
    )

    // @todo: Calculate the MD5 hash of the file
    const md5Hash = undefined

    const uploadTask = uploadBytesResumable(storageRef, file, {
      md5Hash,
      contentType: file.type || "application/octet-stream",
      customMetadata: {
        originFileName: fileName,
      },
    })

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
      },
      (error) => reject(error),
      async () => {
        try {
          resolve({
            name: file.name,
            attachmentId: attachmentId!,
          })
        } catch (error) {
          reject(error)
        }
      }
    )
  })
}

export async function deleteClientAttachments({ clientId, userId }) {
  const attachmentsRef = ref(
    storage,
    `users/${userId}/clients/${clientId}/attachments`
  )

  const listResult = await listAll(attachmentsRef)
  const deletePromises = listResult.items.map((itemRef) =>
    deleteObject(itemRef)
  )

  await Promise.all(deletePromises)
}

export function useUploadAttachment(
  options?: MutationOptions<Omit<UploadAttachmentParams, "userId">>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()

  return useMutation({
    ...options,
    mutationFn: async (data) => {
      validatePdf(data.file)

      return await uploadAttachment({
        ...data,
        userId: currentUser?.uid ?? "",
      })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)
      void queryClient.invalidateQueries({
        queryKey: ["CLIENTS", "ATTACHMENTS"],
      })
    },
  })
}

export function useDeleteAttachment(
  options?: MutationOptions<{
    clientId: string
    attachmentId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()

  return useMutation({
    ...options,
    mutationFn: async ({ clientId, attachmentId }) => {
      const userId = currentUser?.uid
      const fileRef = ref(
        storage,
        `users/${userId}/clients/${clientId}/attachments/${attachmentId}`
      )

      await deleteObject(fileRef)
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)
      void queryClient.invalidateQueries({
        queryKey: ["CLIENTS", "ATTACHMENTS"],
      })
    },
  })
}

export function useClientAttachments({
  clientId,
  reactQuery,
}: QueryParameter<
  Array<{
    id: string
    originalName: string
    ref: StorageReference
  }>
> & {
  clientId: string
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["CLIENTS", "ATTACHMENTS", clientId],
    queryFn: async () => {
      const userId = currentUser?.uid

      const attachmentsRef = ref(
        storage,
        `users/${userId}/clients/${clientId}/attachments`
      )

      const listResult = await listAll(attachmentsRef)

      const attachments = await Promise.all(
        listResult.items.map(async (itemRef) => {
          // @todo: should we fetch meta here?

          let originalName = itemRef?.name ?? ""
          if (isNameStartWithUUID(originalName)) {
            // remove the uuid from the file name
            originalName = originalName.split(".").slice(1).join(".") ?? ""
          }

          return {
            id: itemRef.fullPath,
            originalName,
            ref: itemRef,
          }
        })
      )

      return attachments ?? []
    },
  })
}

export function useAttachmentDownloadURL({
  attachment,
  reactQuery,
}: QueryParameter<{ downloadURL: string }> & {
  attachment: StorageReference
}) {
  return useQuery({
    ...reactQuery,
    queryKey: ["CLIENTS", "ATTACHMENTS", "URL", attachment.fullPath],
    enabled: !!attachment,
    queryFn: async () => {
      return {
        downloadURL: await getDownloadURL(attachment),
      }
    },
  })
}
