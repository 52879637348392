import React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { HomeButton } from "~/components/HomeButton"
import { Button } from "~/components/ui"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip"
import { useWeeklyCalendar } from "./context"

export function WeeklyNavigation() {
  const { nextWeek, prevWeek, currentWeek, resetWeek } = useWeeklyCalendar()

  return (
    <nav className="flex justify-between items-center py-1.5 px-2">
      <HomeButton />

      <h2 className="text-base sm:text-xl font-bold capitalize">
        {currentWeek.plus({ days: 6 }).toLocaleString({
          month: "long",
          year: "numeric",
        })}
      </h2>

      <div className="flex items-center gap-1 md:gap-2 mx-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="sm"
              variant="outline"
              className="rounded-3xl border-black border-2 hover:border-transparent bg-cream-300 text-black"
              onClick={() => resetWeek()}
            >
              Today
            </Button>
          </TooltipTrigger>
          <TooltipContent>Current week</TooltipContent>
        </Tooltip>

        <div className="flex items-center">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => prevWeek()}
              >
                <ChevronLeft
                  className="h-6 w-6"
                  strokeWidth={2.5}
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Previous week</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => nextWeek()}
              >
                <ChevronRight
                  className="h-6 w-6"
                  strokeWidth={2.5}
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Next week</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </nav>
  )
}
