import React from "react"
import { Widget } from "@typeform/embed-react"
import { Dialog, DialogContent } from "~/components/ui/dialog"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserProfile } from "~/hooks/useUserProfile"

interface SurveyDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  widgetId: string
}

export function SurveyDialog({
  open,
  onOpenChange,
  widgetId,
}: SurveyDialogProps) {
  const [userProfile] = useUserProfile()
  const [, updateUserJourney] = useUserJourney()

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open)
        if (!open) {
          void updateUserJourney({
            onboardingCompleted: true,
          })
        }
      }}
    >
      <DialogContent className="p-0 w-full h-full max-h-[600px]">
        <Widget
          id={widgetId}
          hidden={{
            email: userProfile?.email ? userProfile.email : "anonymous",
          }}
          inlineOnMobile
          style={{ width: "100%", height: "100%" }}
          onSubmit={() => {
            void updateUserJourney({
              surveyAnswered: true,
              onboardingCompleted: true,
            })
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
