import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Button, toast } from "~/components/ui"
import { useStore } from "~/hooks/useStore"
import parseSemver from "~/utils/semver"

type VersionType = {
  build: string
  version: string
}

const checkForNewVersion = async (): Promise<VersionType> => {
  const baseUrl = window.location.origin

  return await fetch(
    `${baseUrl}/manifest.webmanifest${import.meta.env.PROD ? `?t=${Date.now()}` : ""}`,
    { cache: "no-cache" }
  )
    .then((response) => response.json())
    .then((data) => {
      return {
        build: data?.build || "1.0dev",
        version: data?.version || "0.0.0",
      }
    })
}

const shouldForceReload = (currentVersion: string, newVersion: string) => {
  const current = parseSemver(currentVersion)
  const latest = parseSemver(newVersion)

  if (!current || !latest) return false

  return (
    latest.major > current.major ||
    (latest.major === current.major && latest.minor > current.minor)
  )
}

export function UpdateNotifier() {
  const [currentVersion, setCurrentVersion] = useState<VersionType | null>(null)

  const { updateAvailable, setUpdateAvailable } = useStore((state) => ({
    updateAvailable: state.updateAvailable,
    setUpdateAvailable: state.setUpdateAvailable,
  }))

  const { data: newVersion } = useQuery({
    queryKey: ["versionCheck"],
    queryFn: checkForNewVersion,
    refetchInterval: 30_000, // Refetch every 30 seconds
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    // Set the current version if it's not set yet,
    if (!currentVersion && newVersion?.build) {
      setCurrentVersion(newVersion)
    }

    // Notify the user if a new version is available
    if (
      newVersion &&
      currentVersion &&
      currentVersion.build !== newVersion.build
    ) {
      // Force reload if the major or minor version has changed
      if (shouldForceReload(currentVersion.version, newVersion.version)) {
        window.location.reload()
        return
      }

      setUpdateAvailable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion, newVersion])

  useEffect(() => {
    if (updateAvailable) {
      toast("Update available", {
        id: "new-version",
        description: "Get the best experience by updating now.",
        position: "bottom-left",
        dismissible: false,
        action: (
          <Button
            size="sm"
            className="px-2 py-1 h-auto text-xs font-normal rounded"
            variant="secondary"
            onClick={() => {
              window.location.reload()
            }}
          >
            Update
          </Button>
        ),
        cancel: (
          <Button
            size="sm"
            className="px-2 py-1 h-auto text-xs font-normal rounded"
            variant="outline"
            onClick={() => {
              toast.dismiss("new-version")
            }}
          >
            Later
          </Button>
        ),
        duration: Infinity,
      })
    }
  }, [updateAvailable])

  return null
}
