import * as React from "react"
import { PlusIcon, Repeat2, ReplaceIcon } from "lucide-react"
import { DateTime } from "luxon"
import { useFormContext } from "react-hook-form"
import { Button } from "~/components/ui"
import { cn } from "~/components/ui/utils"
import {
  EventSlot as BaseEventSlot,
  SlotRenderProps,
  useWeeklyRoot,
} from "~/components/weekly-calendar"
import { parseDate } from "~/components/weekly-calendar/events"
import { useCalendarStore } from "./store"
import {
  INITIAL_VALUES,
  MAX_PROPOSAL_SLOTS,
  useCalendarFormSlotsValue,
  useCalendarFormValues,
} from "./utils"

const EventPlaceholder = ({ slotDate }) => {
  const { reset, getValues } = useFormContext()

  const { selectedSlot, setSelectedSlot, selectedEvent, selectedEventMode } =
    useCalendarStore((state) => ({
      selectedSlot: state.selectedSlot,
      setSelectedSlot: state.setSelectedSlot,
      selectedEvent: state.selectedEvent,
      selectedEventMode: state.selectedEventMode,
    }))

  const onSelectSlot = () => {
    const isJumping = selectedSlot && !selectedSlot.hasSame(slotDate, "hour")

    // When jumping to another slot, reset the form but still keep the values
    // of client-related fields.
    if (isJumping) {
      reset({
        ...getValues(),
        start: 0,
        duration: 60,
      })
    } else {
      reset({ ...INITIAL_VALUES })
    }

    setSelectedSlot(slotDate)
  }

  // Placeholder for editing an existing event
  if (selectedEvent !== null && selectedEventMode == "edit") {
    return (
      <EditEventPlaceholder
        slotDate={slotDate}
        selectedEvent={selectedEvent}
      />
    )
  }

  return (
    <div className="group flex items-center justify-center size-full p-[2px]">
      <Button
        size="sm"
        variant="outline"
        className="bg-transparent hover:bg-blue-100/50 border-dashed invisible group-hover:visible rounded-sm size-full"
        onClick={() => onSelectSlot()}
      >
        <PlusIcon className="size-4 fill-current stroke-current" />
        <span className="sr-only">New Event</span>
      </Button>
    </div>
  )
}

const EditEventPlaceholder = ({ slotDate, selectedEvent }) => {
  const { setValue } = useFormContext()

  const { date, minutes, duration } = useCalendarFormValues({
    slotDate: slotDate!,
  })

  const startDate = date.set({ minute: minutes })
  const endDate = startDate.plus({ minute: duration })

  const onUpdateEventDate = () => {
    setValue("date", parseDate(slotDate).toISO())
  }

  return (
    <BaseEventSlot
      className={cn("group flex items-center justify-center size-full p-[2px]")}
      event={{
        id: "__PLACEHOLDER__",
        title: "",
        start: startDate,
        end: endDate,
      }}
    >
      <Button
        size="sm"
        variant="outline"
        className={cn(
          "bg-transparent border-dashed invisible group-hover:visible rounded-sm size-full bg-opacity-20 hover:bg-opacity-20!",
          selectedEvent?.raw?.personal
            ? "bg-[#ADDEBA] border-[#ADDEBA]"
            : "bg-[#E6ADFF] border-[#E6ADFF]"
        )}
        onClick={() => onUpdateEventDate()}
      >
        <ReplaceIcon className="size-4 fill-current stroke-current" />
        <span className="sr-only">New Event</span>
      </Button>
    </BaseEventSlot>
  )
}

const PendingEventSlot = ({ selectedSlot }) => {
  const { date, title, minutes, duration } = useCalendarFormValues({
    slotDate: selectedSlot!,
  })

  const startDate = date.set({ minute: minutes })
  const endDate = startDate.plus({ minute: duration })

  return (
    <BaseEventSlot
      className={cn(
        "flex items-center justify-center text-xs whitespace-normal overflow-hidden sm:rounded-sm rounded-[2px] transition-colors bg-opacity-60 hover:bg-opacity-100",
        "bg-primary text-primary-foreground"
      )}
      event={{
        id: "__NEW__",
        title,
        start: startDate,
        end: endDate,
      }}
    >
      <span>{title || "New Event"}</span>
    </BaseEventSlot>
  )
}

const EditingEventSlot = ({ selectedEvent, slotDate }) => {
  const { date, title, minutes, duration } = useCalendarFormValues({
    slotDate,
  })

  const startDate = date.set({ minute: minutes })
  const endDate = startDate.plus({ minute: duration })

  const showEditing =
    selectedEvent !== null && startDate.hasSame(slotDate, "hour")

  if (!showEditing) return null

  return (
    <EventSlot
      i={0}
      event={{
        id: selectedEvent?.id,
        start: startDate,
        end: endDate,
        raw: {
          ...selectedEvent.raw,
          start: startDate,
          end: endDate,
          title,
        },
      }}
    />
  )
}

const EventSlot = ({ event, i }) => {
  const session = event.raw

  const {
    selectMode,
    selectedEvent,
    selectedEventMode,
    selectedProposal,
    setSelectedEvent,
    setSelectedProposal,
  } = useCalendarStore((state) => ({
    selectMode: state.selectMode,
    selectedEvent: state.selectedEvent,
    selectedEventMode: state.selectedEventMode,
    setSelectedEvent: state.setSelectedEvent,
    selectedProposal: state.selectedProposal,
    setSelectedProposal: state.setSelectedProposal,
  }))

  const isSelected =
    session.id === selectedEvent?.id || session.id === selectedProposal?.id
  const isProposal = session.proposalId && !session.acceptedAt

  const baseClasses =
    "py-[1px] hover:!z-[40] text-left cursor-pointer text-sm whitespace-normal overflow-hidden sm:rounded-sm rounded-[2px] transition-colors bg-opacity-60 hover:bg-opacity-100"
  const personalClasses = "text-[#306239] bg-[#ADDEBA] hover:bg-[#ADDEBA]"
  const proposedClasses =
    "text-[#4D0066] bg-[repeating-linear-gradient(-45deg,#fecaca99_0_4px,transparent_4px_8px)] hover:bg-[repeating-linear-gradient(-45deg,#fecacaff_0_4px,transparent_4px_8px)]"
  const defaultClasses = "text-[#4D0066] bg-[#E6ADFF] hover:bg-[#E6ADFF]"
  const selectedClasses = `ring-2 ring-offset-2 shadow-short-flat ${
    session.personal
      ? "ring-[#ADDEBA]"
      : isProposal
        ? "ring-[#fecacaff]"
        : "ring-[#E6ADFF]"
  }`

  const className = cn(
    baseClasses,
    session.personal
      ? personalClasses
      : isProposal
        ? proposedClasses
        : defaultClasses,
    isSelected && selectedClasses
  )

  return (
    <BaseEventSlot
      event={event}
      className={className}
      style={{
        zIndex: 10 + i,
      }}
      onClick={() => {
        if (selectMode !== "event" || selectedEventMode === "edit") return

        if (event?.raw?.proposalId) {
          setSelectedProposal(event)
        } else {
          setSelectedEvent(event)
        }
      }}
    >
      {({ height }) => (
        <div className="flex flex-col justify-start w-full h-full">
          {session.repeatId && (
            <Repeat2 className="absolute right-0 bottom-0 w-2 h-2 sm:w-4 sm:h-4 sm:mr-[2px] opacity-50" />
          )}
          {!session.personal ? (
            <>
              {session.title ? (
                <div
                  className={cn(
                    "flex flex-row justify-left",
                    session.proposalId && !session.acceptedAt
                      ? "bg-white w-fit"
                      : ""
                  )}
                >
                  <p
                    className={cn(
                      height < 20
                        ? "mt-[-6px] text-[10px] sm:text-[10px] line-clamp-1"
                        : "text-[10px]/3 sm:text-sm/3 line-clamp-2",
                      height > 19 && height < 30 && "line-clamp-1"
                    )}
                  >
                    {session.title}
                  </p>
                  {height < 20 && (
                    <p className="mt-[-6px] text-[10px] sm:text-[10px] w-full">
                      {session.start.toLocaleString(DateTime.TIME_SIMPLE)}
                    </p>
                  )}
                </div>
              ) : (
                <p
                  className={cn(
                    height < 20
                      ? "mt-[-5px] text-[10px] sm:text-[12px]"
                      : "text-[10px]/3 sm:text-sm"
                  )}
                >
                  {session.clientName || "No title"}
                  {height <= 20 && (
                    <span>
                      {session.start.toLocaleString(DateTime.TIME_SIMPLE)}
                    </span>
                  )}
                </p>
              )}
            </>
          ) : (
            <p
              className={cn(
                height < 20
                  ? "mt-[-5px] text-[10px] sm:text-[11px]"
                  : "text-[10px]/3 sm:text-sm/4"
              )}
            >
              {session.title || "(No title)"}
              {height < 20 && (
                <span>
                  {" "}
                  {session.start.toLocaleString(DateTime.TIME_SIMPLE)}
                </span>
              )}
            </p>
          )}

          {height > 10 && height < 40 && (
            <p
              className={cn(
                "text-[8px] sm:text-[10px] leading-tight sm:truncate line-clamp-1",
                session.proposalId && !session.acceptedAt
                  ? "bg-white w-fit"
                  : ""
              )}
            >
              {session.start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
              {session.end?.toLocaleString(DateTime.TIME_SIMPLE)}
            </p>
          )}

          {height >= 40 && (
            <p
              className={cn(
                "text-[10px] w-full leading-tight line-clamp-2",
                session.proposalId && !session.acceptedAt
                  ? "bg-white w-fit"
                  : ""
              )}
            >
              {session.start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
              {session.end?.toLocaleString(DateTime.TIME_SIMPLE)}
            </p>
          )}
        </div>
      )}
    </BaseEventSlot>
  )
}

const SelectSlotButton = ({ slotDate }) => {
  const { setValue } = useFormContext()
  const slots = useCalendarFormSlotsValue()

  const slotIndex = slots.indexOf(parseDate(slotDate).toISO()!)

  const isPlaceholder = slotIndex === -1
  const isFullSlots = slots.length >= MAX_PROPOSAL_SLOTS && slotIndex === -1

  const onSelectSlot = () => {
    if (slotIndex !== -1) {
      setValue(
        "slots",
        slots.filter((s) => s !== slotDate.toISO())
      )
      return
    }

    if (slots.length >= MAX_PROPOSAL_SLOTS) return

    setValue("slots", [...slots, slotDate.toISO()])
  }

  return (
    <div className="group flex items-center justify-center size-full p-[2px]">
      <Button
        size="sm"
        className={cn(
          "rounded-sm size-full",
          isPlaceholder &&
            "invisible group-hover:visible text-primary-foreground bg-primary/50 hover:bg-primary/50",
          isFullSlots && "hidden"
        )}
        onClick={() => onSelectSlot()}
      >
        <span className="text-2xl font-bold">
          {isPlaceholder ? (slots?.length ?? 0) + 1 : slotIndex + 1}
        </span>
      </Button>
    </div>
  )
}

export const SlotItem = ({
  slotDate,
  events,
  hasEvents,
  isSameHour,
}: SlotRenderProps) => {
  const { slotHeight } = useWeeklyRoot()

  const { selectMode, selectedSlot, selectedEvent, selectedEventMode } =
    useCalendarStore((state) => ({
      selectMode: state.selectMode,
      selectedSlot: state.selectedSlot,
      selectedEvent: state.selectedEvent,
      selectedEventMode: state.selectedEventMode,
    }))

  const now = DateTime.local()
  const minutesOffset = Math.floor((now.minute / 60) * slotHeight)

  const showSelectSlots = selectMode === "propose" && !hasEvents
  const showPendingEvent = React.useMemo(
    () =>
      selectMode === "event" &&
      selectedSlot !== null &&
      selectedSlot.hasSame(slotDate, "hour"),
    [selectMode, selectedSlot, slotDate]
  )

  return (
    <>
      {events.map((event, i) => {
        if (
          selectedEvent &&
          selectedEventMode === "edit" &&
          event.id === selectedEvent.id
        ) {
          return null
        }

        return (
          <EventSlot
            i={i}
            key={event.id}
            event={event}
          />
        )
      })}

      {selectMode === "event" && selectedEventMode === "edit" ? (
        <EditingEventSlot
          selectedEvent={selectedEvent}
          slotDate={slotDate}
        />
      ) : null}

      {selectMode === "event" ? <EventPlaceholder slotDate={slotDate} /> : null}

      {showPendingEvent ? (
        <PendingEventSlot selectedSlot={selectedSlot} />
      ) : null}

      {/* Slots */}
      {showSelectSlots ? <SelectSlotButton slotDate={slotDate} /> : null}

      {/* Hour-indicator */}
      {isSameHour ? (
        <div
          className="absolute z-45 top-0 inset-x-0 z-[5] h-[2px] flex items-center justify-start bg-primary before:size-2 before:rounded-full before:bg-primary before:ml-[-4px]"
          style={{
            transform: `translateY(${minutesOffset}px)`,
          }}
        />
      ) : null}
    </>
  )
}
