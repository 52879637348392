import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cn } from "~/components/ui/utils"
import { useScrollbarWidth } from "~/hooks/useScrollbarWidth"

export type WeeklyRootContextType = {
  slotHeight: number
  hoverDateString: string | null
  setHoverDateString: React.Dispatch<React.SetStateAction<string | null>>
  scrollContainerRef: React.RefObject<HTMLDivElement>
}

export const WeeklyRootContext = React.createContext<WeeklyRootContextType>(
  {} as WeeklyRootContextType
)

export function useWeeklyRoot() {
  return React.useContext(WeeklyRootContext)
}

export type WeeklyRootProps = React.HTMLAttributes<HTMLDivElement> & {
  asChild?: boolean
}

const calcSlotHeight = (ref: Element) =>
  parseInt(getComputedStyle(ref).getPropertyValue("--wlc-slot-height"), 10)

export const WeeklyRoot = React.forwardRef<HTMLDivElement, WeeklyRootProps>(
  ({ children, asChild, ...props }, ref) => {
    const scrollContainerRef = React.useRef<HTMLDivElement>(null)
    const [hoverDateString, setHoverDateString] = React.useState<string | null>(
      null
    )

    const [slotHeight, setSlotHeight] = React.useState<number>(() =>
      scrollContainerRef.current
        ? calcSlotHeight(scrollContainerRef.current)
        : 60
    )

    // Recalculate slot height when the scroll container ref changes and resize
    React.useEffect(() => {
      if (!scrollContainerRef.current) return

      const ref = scrollContainerRef.current!
      const updateSlotHeight = () => setSlotHeight(calcSlotHeight(ref))

      const resizeObserver = new ResizeObserver(updateSlotHeight)
      resizeObserver.observe(ref)

      return () => {
        if (ref) resizeObserver.unobserve(ref)
        resizeObserver.disconnect()
      }
    }, [scrollContainerRef])

    const sbw = useScrollbarWidth(scrollContainerRef)
    const Comp = asChild ? Slot : "div"

    const value = React.useMemo(
      () => ({
        slotHeight,
        hoverDateString,
        setHoverDateString,
        scrollContainerRef,
      }),
      [slotHeight, hoverDateString]
    )

    return (
      <WeeklyRootContext.Provider value={value}>
        <Comp
          ref={ref}
          {...props}
          className={cn("wlc-root h-full flex flex-col", props.className)}
          data-hover-date={hoverDateString || undefined}
          style={
            {
              ...props?.style,
              "--wlc-scrollbar-width": sbw ? `${sbw}px` : undefined,
            } as React.CSSProperties
          }
        >
          {children}
        </Comp>
      </WeeklyRootContext.Provider>
    )
  }
)
WeeklyRoot.displayName = "WeeklyRoot"
