import { useEffect, useMemo, useRef, useState } from "react"
import CatInHammock from "~/assets/images/cat_in_hammock.jpg"
import CatTyping from "~/assets/images/cat_typing.gif"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserStatistics } from "~/hooks/useUserStatistics"
import { SurveyDialog } from "../../components/SurveyDialog"
import { Note, NoteStatus } from "./types"

interface NotesOnboardingProps {
  notes: Note[]
}

const NotesOnboarding: React.FC<NotesOnboardingProps> = ({ notes }) => {
  const [userStatistics, , isPendingStatistics] = useUserStatistics()
  const [userJourney, updateUserJourney, isPendingJourney] = useUserJourney()
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false)

  const userJourneyRef = useRef(userJourney)
  const updateUserJourneyRef = useRef(updateUserJourney)

  // Used to handle debouncing of cleanup
  const isMountedRef = useRef(false)
  const cleanupTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    userJourneyRef.current = userJourney
    updateUserJourneyRef.current = updateUserJourney
  }, [userJourney, updateUserJourney])

  // Cleanup with debouncing for unmount
  useEffect(() => {
    isMountedRef.current = true
    // Return a single cleanup function
    return () => {
      isMountedRef.current = false

      // Clear any existing timeout to avoid duplicates
      if (cleanupTimeoutRef.current) {
        clearTimeout(cleanupTimeoutRef.current)
      }
      // Set up the timeout on mount
      cleanupTimeoutRef.current = setTimeout(() => {
        if (!isMountedRef.current) {
          const handleCleanup = async () => {
            const currentUserJourney = userJourneyRef.current
            const currentUpdateUserJourney = updateUserJourneyRef.current

            const updatePromises: Promise<void>[] = []

            if (
              currentUserJourney?.noteDetailsViewed &&
              !currentUserJourney?.firstNoteCompleted
            ) {
              updatePromises.push(
                currentUpdateUserJourney({ firstNoteCompleted: true })
              )
            }

            if (
              currentUserJourney?.secondNoteRecorded &&
              !currentUserJourney?.transformsIntroduced
            ) {
              updatePromises.push(
                currentUpdateUserJourney({ transformsIntroduced: true })
              )
            }

            if (updatePromises.length > 0) {
              try {
                await Promise.all(updatePromises)
              } catch (error) {
                console.error("Error during cleanup updates:", error)
              }
            }
          }
          void handleCleanup()
        } else {
          console.debug("Skipped cleanup - likely Strict Mode remount")
        }
      }, 300)
    }
  }, [])

  const shouldShowSurvey = useMemo(() => {
    return !!(
      !userJourney?.onboardingCompleted &&
      userJourney?.noteDetailsViewed &&
      (userStatistics?.totalSavedNotes ?? 0) > 3
    )
  }, [
    userJourney?.onboardingCompleted,
    userJourney?.noteDetailsViewed,
    userStatistics?.totalSavedNotes,
  ])

  useEffect(() => {
    setSurveyDialogOpen(shouldShowSurvey)
  }, [shouldShowSurvey])

  const firstNoteStatus = useMemo(() => {
    return (
      notes.length > 0 &&
      [
        NoteStatus.Recording,
        NoteStatus.Uploading,
        NoteStatus.Processing,
      ].includes(notes[0]?.status)
    )
  }, [notes])

  useEffect(() => {
    const handlePossibleStateChange = async () => {
      const { state } = userJourney ?? {}
      if (state === "Registered") {
        try {
          await updateUserJourney({ state: "Onboarding" })
        } catch (error) {
          console.error("Error updating user journey state:", error)
        }
      }
    }
    void handlePossibleStateChange()
  }, [userJourney, updateUserJourney])

  // Early return if user statistics or user journey is not available
  if (
    isPendingStatistics ||
    isPendingJourney ||
    !userStatistics ||
    !userJourney ||
    (userStatistics?.totalSavedNotes ?? 0) > 5
  ) {
    return null
  }

  return (
    <>
      <SurveyDialog
        open={isSurveyDialogOpen}
        onOpenChange={(open) => setSurveyDialogOpen(open)}
        widgetId="bQMFOI6Q"
      />

      {
        /* First note recorded */
        userStatistics?.totalSavedNotes < 2 &&
          !userJourney?.noteDetailsViewed && (
            <FirstNoteRecordedCard isProcessing={firstNoteStatus} />
          )
      }

      {
        /* First note viewed and completed */
        userStatistics?.totalSavedNotes === 1 &&
          userJourney?.noteDetailsViewed &&
          userJourney?.firstNoteCompleted && <FirstNoteCompletedCard />
      }

      {
        /* Second note recorded */
        userJourney &&
          userJourney.secondNoteRecorded &&
          !userJourney.transformsIntroduced && <SecondNoteRecordedCard />
      }
    </>
  )
}

const OnboardingCard = ({ children, className = "" }) => (
  <div
    className={`p-5 flex flex-col items-center rounded-xl py-4 my-4 gap-2 bg-[#F5DFFF] shadow ${className}`}
  >
    {children}
  </div>
)

interface FirstNoteRecordedProps {
  isProcessing: boolean
}

const FirstNoteRecordedCard = ({ isProcessing }: FirstNoteRecordedProps) => {
  return (
    <OnboardingCard className="py-8">
      {isProcessing ? (
        <>
          <p>Working on your note!</p>
          <div className="flex items-center justify-center">
            <img
              width="250px"
              src={CatTyping}
              alt="cat typing..."
            />
          </div>
        </>
      ) : (
        <>
          <p>Looks like your note is ready!</p>
          <p> Click on it above to open.</p>
          <div className="flex items-center justify-center">
            <img
              width="250px"
              src={CatInHammock}
              alt="cat relaxing..."
            />
          </div>
        </>
      )}
    </OnboardingCard>
  )
}

const FirstNoteCompletedCard = () => {
  return (
    <OnboardingCard>
      <h4 className="font-platipy text-lg">
        Yay, you&apos;ve completed your first note!
      </h4>
      <p className="text-center">
        Now click on the <strong className="text-[#FF665C]">New note</strong>{" "}
        button above to record your next one.
      </p>
    </OnboardingCard>
  )
}

const SecondNoteRecordedCard = () => {
  return (
    <OnboardingCard>
      <h4 className="font-platipy text-lg">Nice!</h4>
      <div className="flex flex-col">
        <p className="md:text-center"></p>
        <p className="md:text-center text-lg">
          Use <strong>Set client</strong> to organize your notes.
        </p>
        <p className="md:text-center text-lg">
          You can also apply <strong>smart formats</strong> to them.
        </p>
        <p className="md:text-center text-lg mt-2">
          Click on the note you just recorded to try it out!
        </p>
      </div>
    </OnboardingCard>
  )
}

export default NotesOnboarding
