import React from "react"
import * as Sentry from "@sentry/browser"
import {
  CircleUserRound,
  House,
  LogOutIcon,
  MegaphoneIcon,
  MessagesSquare,
} from "lucide-react"
import { useLocation, useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import { ReferModal } from "~/components/modals/ReferModal"
import {
  Button,
  ConfirmationModal,
  Drawer,
  DrawerContent,
  DrawerTitle,
  DrawerTrigger,
  Separator,
} from "~/components/ui"
import { sentryDefaultTags } from "~/config/instrument"
import { useAuth } from "~/context/AuthContext"
import useSafeLogout from "~/hooks/useSafeLogout"
import { useStore } from "~/hooks/useStore"
import JoyCommunityModal from "./JoyCommunityModal"

export default function MainDrawer({
  children,
}: {
  children?: React.ReactNode
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { track } = useAnalytics()
  const { currentUser } = useAuth()

  const userId = currentUser?.uid

  const [showCommunityRedirect, setShowCommunityRedirect] =
    React.useState(false)
  const [showReferModal, setShowReferModal] = React.useState(false)

  const [navIsOpened, setNavIsOpened] = useStore((state) => [
    state.navIsOpened,
    state.setNavIsOpened,
  ])

  const {
    tryLogout,
    forceLogout,
    localRecordingsFound: showLocalRecordingsAlert,
  } = useSafeLogout()

  const handleLogout = async () => {
    void track("Main Logout")
    await tryLogout()

    // Note this can be triggered multiple times for the same user
    // if there are recordings and they decide to not log out.
    Sentry.captureMessage(`User signed out`, {
      level: "info",
      user: { id: userId },
      tags: { ...sentryDefaultTags },
    })
  }

  const confirmLogout = async () => {
    await forceLogout()
  }

  const isMainPages = ["/", "/home", "/calendar", "/notes"].some(
    (path) => path === pathname || path?.startsWith(pathname)
  )

  return (
    <>
      <JoyCommunityModal
        open={showCommunityRedirect}
        onClose={() => setShowCommunityRedirect(false)}
      />

      <ReferModal
        isOpen={showReferModal}
        onOpenChange={setShowReferModal}
      />

      {showLocalRecordingsAlert ? (
        <ConfirmationModal
          isOpen={true}
          title="Local recordings available"
          description="You have recordings that have not yet been uploaded. They will be deleted if you logout before upload is finished. Are you sure you want to logout?"
          confirmButton="Confirm"
          closeButton="Cancel"
          onConfirm={confirmLogout}
        />
      ) : null}

      <Drawer
        direction={"left"}
        open={navIsOpened}
        onOpenChange={setNavIsOpened}
      >
        {children ? <DrawerTrigger asChild>{children}</DrawerTrigger> : null}

        <DrawerContent
          indicator={false}
          className="flex flex-col justify-between gap-4 border-0 rounded-2xl top-0 bottom-0 left-0 my-4 ml-3 w-64 md:w-96 after:!content-none"
          aria-describedby=""
        >
          <DrawerTitle className="sr-only">Navigation</DrawerTitle>

          <ul className="p-4 flex flex-col space-y-2">
            {!isMainPages ? (
              <>
                <Button
                  variant="ghost"
                  className="flex w-full justify-start $"
                  onClick={() => {
                    void navigate("/home")
                    setNavIsOpened(false)
                    void track("MainDrawer Navigate_to_home")
                  }}
                >
                  <House className="size-5" />
                  <span>Home</span>
                </Button>

                <Separator />
              </>
            ) : null}

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                void navigate("/account")
                setNavIsOpened(false)
                void track("MainDrawer Navigate_to_account")
              }}
            >
              <CircleUserRound className="size-5" />
              <span>My account</span>
            </Button>

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                setNavIsOpened(false)
                setShowReferModal(true)
                void track("Referral Open_modal")
                void track("MainDrawer Navigate_to_referral")
              }}
            >
              <MegaphoneIcon className="size-5" />
              <span>Refer a friend</span>
            </Button>

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                setShowCommunityRedirect(true)
                setNavIsOpened(false)
              }}
            >
              <MessagesSquare
                fill="currentColor"
                className="size-5"
              />
              <span>Joy Community</span>
            </Button>
          </ul>

          <footer className="p-4 flex flex-col space-y-2">
            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                setNavIsOpened(false)
                void handleLogout()
              }}
            >
              <LogOutIcon className="size-5" />
              <span>Logout</span>
            </Button>
          </footer>
        </DrawerContent>
      </Drawer>
    </>
  )
}
