import * as React from "react"

export function useScrollbarWidth(
  ref: React.MutableRefObject<HTMLElement | null>
) {
  const [scrollbarWidth, setScrollbarWidth] = React.useState<number | null>(
    null
  )

  React.useEffect(() => {
    if (!ref.current) return

    const updateScrollbarWidth = () => {
      if (!ref.current) return
      const newWidth = ref.current.offsetWidth - ref.current.clientWidth
      setScrollbarWidth((prev) => (prev !== newWidth ? newWidth : prev))
    }

    updateScrollbarWidth()

    const resizeObserver = new ResizeObserver(updateScrollbarWidth)
    resizeObserver.observe(ref.current)

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current)
      }
      resizeObserver.disconnect()
    }
  }, [ref])

  return scrollbarWidth
}
