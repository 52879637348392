import { useMemo } from "react"
import DOMPurify from "dompurify"
import { marked } from "marked"
import "./markdown.css"

interface SafeMarkdownProps {
  md: string
}

export function SafeMarkdown({ md }: SafeMarkdownProps) {
  const sanitizedHTML = useMemo(() => {
    return DOMPurify.sanitize(
      marked.parse(md, {
        async: false,
        breaks: true,
      })
    )
  }, [md])

  return (
    <div
      className="prose font-sourceSerifPro w-full max-w-none px-2 py-2 overflow-x-auto break-words"
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  )
}
