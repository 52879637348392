import { FirebaseError } from "@firebase/util"
import { captureMessage } from "@sentry/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  FieldValue,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import { DateTime, Duration } from "luxon"
import { Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { sentryDefaultTags } from "~/config/instrument"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { db } from "~/services/firebase"
import { useSubscription } from "./useSubscription"
import { fromRef } from "./utils"

export type Session = {
  createdAt?: Timestamp
  id: string
  start: DateTime
  end?: DateTime
  duration: Duration
  title: string
  description: string
  personal: boolean
  clientId?: string
  clientName?: string
  preRead?: string
  noteIds?: string[] // TOOD: Remove this field
  recordingDismissed?: boolean
  proposalId?: string
  acceptedAt?: Timestamp
  repeatId?: string
  repeatFrequency?: string
  repeatInterval?: number
  repeatCount?: number
}

type StoredSession = {
  createdAt: FieldValue | Timestamp
  start: Timestamp
  end: Timestamp
  duration: string
  title: string
  description: string
  personal: boolean
  clientId?: string | FieldValue
  clientName?: string | FieldValue
  preRead?: string
  recordingDismissed?: boolean
  proposalId?: string
  acceptedAt?: Timestamp
  repeatId?: string
  repeatFrequency?: string
  repeatInterval?: number
  repeatCount?: number
}

function toStoredSession(
  session: Session,
  deleteFields: boolean
): StoredSession {
  const end = session.start.plus(session.duration)

  // If we are deleting fields, we need to check if the field is empty and delete it
  const clientIdField = deleteFields
    ? session.clientId
      ? session.clientId
      : deleteField()
    : session.clientId
  const clientNameField = deleteFields
    ? session.clientName
      ? session.clientName
      : deleteField()
    : session.clientName

  return {
    ...(session.createdAt
      ? { createdAt: session.createdAt }
      : { createdAt: serverTimestamp() }),
    start: Timestamp.fromDate(session.start.toJSDate()),
    end: Timestamp.fromDate(end.toJSDate()),
    duration: session.duration.toISO() ?? "",
    title: session.title,
    description: session.description,
    personal: session.personal,
    ...(clientIdField && { clientId: clientIdField }),
    ...(clientNameField && { clientName: clientNameField }),
    ...(session.preRead && { preRead: session.preRead }),
    ...(session.recordingDismissed && {
      recordingDismissed: session.recordingDismissed,
    }),
    ...(session.proposalId && { proposalId: session.proposalId }),
    ...(session.acceptedAt && { acceptedAt: session.acceptedAt }),
    ...(session.repeatId && { repeatId: session.repeatId }),
    ...(session.repeatFrequency && {
      repeatFrequency: session.repeatFrequency,
    }),
    ...(session.repeatInterval && {
      repeatInterval: session.repeatInterval,
    }),
    ...(session.repeatCount && { repeatCount: session.repeatCount }),
  }
}

function toSession(sessionId: string, storedSession: StoredSession): Session {
  const isFieldValue = (value: unknown): value is FieldValue => {
    return value instanceof FieldValue
  }

  return {
    createdAt: isFieldValue(storedSession.createdAt)
      ? undefined
      : (storedSession.createdAt as Timestamp),
    id: sessionId,
    start: DateTime.fromJSDate(storedSession.start.toDate()),
    end: storedSession.end
      ? DateTime.fromJSDate(storedSession.end.toDate())
      : undefined,
    duration: Duration.fromISO(storedSession.duration),
    title: storedSession.title,
    description: storedSession.description,
    personal: storedSession.personal,
    clientId: isFieldValue(storedSession.clientId)
      ? undefined
      : storedSession.clientId,
    clientName: isFieldValue(storedSession.clientName)
      ? undefined
      : storedSession.clientName,
    preRead: storedSession.preRead,
    recordingDismissed: storedSession.recordingDismissed,
    proposalId: storedSession.proposalId,
    acceptedAt: storedSession.acceptedAt,
    repeatId: storedSession.repeatId,
    repeatFrequency: storedSession.repeatFrequency,
    repeatInterval: storedSession.repeatInterval,
    repeatCount: storedSession.repeatCount,
  }
}

type WeekString = `${number}W${number}`

export function toWeekString(date: Date | DateTime): WeekString {
  const dt = date instanceof DateTime ? date : DateTime.fromJSDate(date)
  return `${dt.weekYear}W${dt.weekNumber}`
}

export function fromWeekString(weekString: WeekString): DateTime {
  const [year, week] = weekString.split("W")

  return DateTime.fromObject({
    weekYear: Number(year),
    weekNumber: Number(week),
  }).startOf("week")
}

/* Queries */
export function useSessions({
  weekString,
  clientId,
}: {
  weekString: WeekString
  clientId?: string
}) {
  const { currentUser } = useAuth()

  const date = fromWeekString(weekString)

  const getAdjustedDateRange = (date: DateTime) => {
    const startOfMonth = date.startOf("month")
    const endOfMonth = date.endOf("month")

    // Adjust the start and end date to start and end of the week
    const adjustedStart = startOfMonth.startOf("week")
    const adjustedEnd = endOfMonth.endOf("week")

    return {
      start: adjustedStart.toJSDate(),
      end: adjustedEnd.toJSDate(),
    }
  }

  return useSubscription<QuerySnapshot, FirebaseError, Session[]>({
    subscriptionKey: ["SESSIONS", { date: date.toFormat("yyyy-MM"), clientId }],
    subscriptionFn: () => {
      const { start, end } = getAdjustedDateRange(date)

      return fromRef(
        query(
          collection(db, `users/${currentUser?.uid}/sessions`),
          where("start", ">=", start),
          where("start", "<=", end),
          ...(clientId ? [where("clientId", "==", clientId)] : [])
        )
      ) as Observable<QuerySnapshot>
    },
    options: {
      enabled:
        !!currentUser && (typeof clientId === "string" ? !!clientId : true),
      select: (snapshot) => {
        return snapshot?.docs?.map((doc) => {
          const storedSession = doc.data() as StoredSession
          return toSession(doc.id, storedSession)
        })
      },
    },
  })
}

export function useUpcomingSessions({
  clientId,
  limit: _limit = 1,
}: {
  limit?: number
  clientId?: string
}) {
  const { currentUser } = useAuth()

  return useSubscription<QuerySnapshot, FirebaseError, Session[]>({
    subscriptionKey: ["SESSIONS", "UPCOMING", { clientId }],
    subscriptionFn: () => {
      const now = DateTime.now().toJSDate()

      return fromRef(
        query(
          collection(db, `users/${currentUser?.uid}/sessions`),
          where("clientId", "==", clientId),
          where("start", ">", now),
          limit(_limit),
          orderBy("start")
        )
      ) as Observable<QuerySnapshot>
    },
    options: {
      enabled:
        !!currentUser && (typeof clientId === "string" ? !!clientId : true),
      select: (snapshot) => {
        return snapshot?.docs?.map((doc) => {
          const storedSession = doc.data() as StoredSession
          return toSession(doc.id, storedSession)
        })
      },
    },
  })
}

export function useSessionById({
  sessionId,
  reactQuery,
}: QueryParameter<unknown> & {
  sessionId: string
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["SESSIONS", sessionId],
    queryFn: async () => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      const sessionSnapshot = await getDoc(sessionRef)

      if (!sessionSnapshot.exists()) {
        throw new Error("SESSION_NOT_FOUND")
      }

      const storedSession = sessionSnapshot.data() as StoredSession
      const session = toSession(sessionSnapshot.id, storedSession)

      return session
    },
  })
}

export function useSessionsByRepeatId({
  repeatId,
  reactQuery,
}: QueryParameter<unknown> & {
  repeatId: string
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["SESSIONS", repeatId],
    queryFn: async () => {
      const sessionsRef = collection(db, `users/${currentUser?.uid}/sessions`)
      const sessionsSnapshot = await getDocs(
        query(sessionsRef, where("repeatId", "==", repeatId), orderBy("start"))
      )
      if (sessionsSnapshot.empty) {
        return null
      }
      return sessionsSnapshot.docs.map((doc) =>
        toSession(doc.id, doc.data() as StoredSession)
      )
    },
  })
}

/* Mutations */
export function useAddSession(
  options?: MutationOptions<{
    session: Session
  }>
) {
  const queryClient = useQueryClient()

  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["SESSIONS"],
    mutationFn: async ({ session }) => {
      const sessionsRef = collection(db, `users/${currentUser!.uid}/sessions`)

      const sessionsDoc = toStoredSession(session, false)

      const sessionDocRef = await addDoc(sessionsRef, sessionsDoc)

      return sessionDocRef.id
    },
    onSettled: async (_, error, variables, context) => {
      options?.onSettled?.(_, error, variables, context)

      if (!error) {
        void queryClient.invalidateQueries({ queryKey: ["SESSIONS"] })
        void track("Session Created", { personal: variables.session.personal })
        if (variables.session.repeatId) {
          void track("Session Created_repeating", {
            personal: variables.session.personal,
            repeatCount: variables.session.repeatCount,
            repeatFrequency: variables.session.repeatFrequency,
            repeatInterval: variables.session.repeatInterval,
          })
        }
      } else {
        captureMessage(`An error occurred while adding session`, {
          level: "error",
          tags: {
            ...sentryDefaultTags,
            useSessions_function: "useAddSession",
            error: error.message,
          },
        })
      }
    },
  })
}

export function useUpdateSession(
  options?: MutationOptions<{
    sessionId: string
    session: Session
    deleteEmptyClientFields?: boolean // If true, empty client fields will be deleted from the stored session
  }>
) {
  const queryClient = useQueryClient()

  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_SESSION"],
    mutationFn: async ({
      sessionId,
      session,
      deleteEmptyClientFields = false,
    }) => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      await updateDoc(sessionRef, {
        ...toStoredSession(session, deleteEmptyClientFields),
      })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)

      if (!error) {
        void queryClient.invalidateQueries({ queryKey: ["SESSIONS"] })
        void track("Session Updated")
      } else {
        captureMessage(`An error occurred while updating session`, {
          level: "error",
          tags: {
            ...sentryDefaultTags,
            useSessions_function: "useUpdateSession",
            error: error.message,
          },
        })
      }
    },
  })
}

export function useDeleteSession(
  options?: MutationOptions<{
    sessionId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ sessionId }) => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      await deleteDoc(sessionRef)
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Session Deleted")
        void queryClient.invalidateQueries({ queryKey: ["SESSIONS"] })
      } else {
        captureMessage(`An error occurred while deleting session`, {
          level: "error",
          tags: {
            ...sentryDefaultTags,
            useSessions_function: "useDeleteSession",
            error: error.message,
          },
        })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
