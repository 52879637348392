import * as React from "react"
import { DateTime } from "luxon"

const intlHour12Formatter = new Intl.DateTimeFormat(undefined, {
  hour: "numeric",
  hour12: true,
})

export function renderHour12Style(hour: number) {
  const date = new Date()
  date.setHours(hour)

  return intlHour12Formatter.format(date)
}

export function renderMinute(date: DateTime, { uses12Hour = false }) {
  return (
    <span>
      {date.toFormat(uses12Hour ? "hh" : "HH")}
      <sup className="top-[-0.35em] text-[11px]">
        {date.toFormat("mm")}
      </sup>{" "}
      {uses12Hour ? date.toFormat("a") : ""}
    </span>
  )
}
