import { FirebaseError } from "@firebase/util"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore"
import { Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions } from "~/lib/react-query"
import { db } from "~/services/firebase"
import { useSubscription } from "./useSubscription"
import { fromRef } from "./utils"

export interface MessagePartBase {
  type: "text" | "functionCall" | "functionResponse" | "inlineData" | "fileData"
}

export interface TextPart extends MessagePartBase {
  type: "text"
  text: string
}

export interface FunctionCallPart extends MessagePartBase {
  type: "functionCall"
  functionName: string
  parameters: object
}

export interface FunctionResponsePart extends MessagePartBase {
  type: "functionResponse"
  name: string
  response: object
}

export interface InlineDataPart extends MessagePartBase {
  type: "inlineData"
  mimeType: string
  data: string // base64 encoded
}

export interface FileDataPart extends MessagePartBase {
  type: "fileData"
  mimeType: string
  fileUri: string
}

export declare type MessagePart =
  | TextPart
  | InlineDataPart
  | FunctionCallPart
  | FunctionResponsePart
  | FileDataPart

export type Message = {
  id?: string
  role: string
  parts: MessagePart[]
  createdAt?: Timestamp
}

export type Conversation = {
  id: string
  assistantId: string
  createdAt?: Timestamp
  updatedAt?: Timestamp
  title?: string
  deletedAt?: Timestamp
}

/* Queries */
export function useConversations() {
  const { currentUser } = useAuth()

  return useSubscription<QuerySnapshot, FirebaseError, Conversation[]>({
    subscriptionKey: ["CONVERSATIONS"],
    subscriptionFn: () => {
      return fromRef(
        query(
          collection(db, `users/${currentUser?.uid}/conversations`),
          orderBy("updatedAt", "desc")
        )
      ) as Observable<QuerySnapshot>
    },
    options: {
      select: (snapshot) => {
        return snapshot?.docs
          ?.map((doc) => {
            const { assistantId, createdAt, updatedAt, title, deletedAt } =
              doc.data()

            return {
              id: doc.id,
              assistantId,
              createdAt,
              updatedAt,
              title,
              deletedAt,
            } as Conversation
          })
          .filter((conversation) => !conversation.deletedAt)
      },
    },
  })
}

export function useMessages() {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  return async (conversationId: string): Promise<Message[]> => {
    if (!currentUser) {
      throw new Error("User not authenticated")
    }

    return queryClient.fetchQuery({
      queryKey: ["MESSAGES", conversationId],
      queryFn: async () => {
        const messagesRef = collection(
          db,
          `users/${currentUser.uid}/conversations/${conversationId}/messages`
        )
        const messagesSnapshot = await getDocs(
          query(messagesRef, orderBy("createdAt", "asc"))
        )
        if (messagesSnapshot.empty) return []
        return messagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Message[]
      },
    })
  }
}

export function useConversationById() {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  return async (conversationId: string): Promise<Conversation> => {
    if (!currentUser) {
      throw new Error("User not authenticated")
    }

    return queryClient.fetchQuery({
      queryKey: ["CONVERSATIONS", conversationId],
      queryFn: async () => {
        const conversationRef = doc(
          db,
          `users/${currentUser.uid}/conversations/${conversationId}`
        )
        const conversationSnapshot = await getDoc(conversationRef)
        if (!conversationSnapshot.exists()) {
          throw new Error("CONVERSATION_NOT_FOUND")
        }
        return conversationSnapshot.data() as Conversation
      },
    })
  }
}

/* Mutations */
export function useAddConversation(
  options?: MutationOptions<{
    assistantId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["CONVERSATIONS"],
    mutationFn: async ({ assistantId }) => {
      const conversationsRef = collection(
        db,
        `users/${currentUser!.uid}/conversations`
      )

      const conversationData = {
        assistantId,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      }

      const docReference = await addDoc(conversationsRef, conversationData)

      const conversation = {
        id: docReference.id,
        ...conversationData,
      } as Conversation

      return conversation
    },
    onSettled: async (_, error, ..._props) => {
      options?.onSettled?.(_, error, ..._props)
      if (!error) {
        void track("Conversation Created")
        void queryClient.invalidateQueries({ queryKey: ["CONVERSATIONS"] })
      }
    },
  })
}

export function useAddMessage(
  options?: MutationOptions<{
    conversationId: string
    message: Message
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["MESSAGES"],
    mutationFn: async ({ conversationId, message }) => {
      const messagesRef = collection(
        db,
        `users/${currentUser!.uid}/conversations/${conversationId}/messages`
      )

      const messageDocRef = await addDoc(messagesRef, {
        ...message,
        id: messagesRef.id,
        createdAt: Timestamp.fromDate(new Date()),
      })

      return messageDocRef.id
    },
    onSettled: async (_, error, ..._props) => {
      options?.onSettled?.(_, error, ..._props)
      if (!error) {
        void track("Message Added")
        void queryClient.invalidateQueries({ queryKey: ["MESSAGES"] })
      }
    },
  })
}

export function useDeleteMessage(
  options?: MutationOptions<{
    conversationId: string
    messageId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["DELETE_MESSAGE"],
    mutationFn: async ({ conversationId, messageId }) => {
      const messageRef = doc(
        db,
        `users/${currentUser?.uid}/conversations/${conversationId}/messages/${messageId}`
      )
      await deleteDoc(messageRef)
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Message Deleted")
        void queryClient.invalidateQueries({ queryKey: ["MESSAGES"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}

export function useUpdateConversation(
  options?: MutationOptions<{
    conversationId: string
    conversation: Conversation
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_CONVERSATION"],
    mutationFn: async ({ conversationId, conversation }) => {
      const conversationRef = doc(
        db,
        `users/${currentUser?.uid}/conversations/${conversationId}`
      )
      await updateDoc(conversationRef, {
        ...conversation,
      })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)
      void track("Conversation Updated")
    },
  })
}

export function useDeleteConversation(
  options?: MutationOptions<{
    conversationId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["DELETE_CONVERSATION"],
    mutationFn: async ({ conversationId }) => {
      const userId = currentUser!.uid
      const deleteRequest = {
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        payload: {
          kind: "conversation-delete",
          conversationId,
        },
      }

      const batch = writeBatch(db)

      const inboxMsgRef = doc(collection(db, "tasks/messages/inbox"))
      batch.set(inboxMsgRef, deleteRequest)

      const conversationRef = doc(
        db,
        `users/${currentUser?.uid}/conversations/${conversationId}`
      )

      batch.update(conversationRef, {
        deletedAt: serverTimestamp(),
      })

      await batch.commit()
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Conversation Deleted")
        void queryClient.invalidateQueries({ queryKey: ["CONVERSATIONS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
