import React from "react"
import { Slot } from "@radix-ui/react-slot"
import { DateTime } from "luxon"
import { cn } from "~/components/ui/utils"
import { useWeeklyCalendar } from "./context"

export type HeaderRenderProps = {
  day: DateTime
  isToday: boolean
  isWeekend: boolean
  className?: string
}

export type WeeklyHeaderProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  children?: (props: HeaderRenderProps) => React.ReactNode
}

export const WeeklyHeader = React.forwardRef<
  HTMLHeadElement,
  WeeklyHeaderProps
>(({ children, ...props }, ref) => {
  const { daysInWeek, currentWeek } = useWeeklyCalendar()

  return (
    <>
      <header
        ref={ref}
        {...props}
        className={cn("wlc-header grid w-full", props.className)}
        style={{
          gridTemplateColumns: `40px repeat(5, minmax(var(--wlc-slot-width), 1fr)) minmax(var(--wlc-slot-weekend_width), 0.6fr) minmax(var(--wlc-slot-weekend_width), 0.6fr)`,
          paddingRight: "var(--wlc-scrollbar-width, 0)",
        }}
      >
        <div className="relative h-12 flex items-center justify-center pl-1 pt-1">
          <span className="text-xs uppercase font-medium bg-blue-100 text-blue-800 rounded-sm p-1">
            W{currentWeek.toFormat("W")}
          </span>
        </div>

        {daysInWeek.map(function Comp(day) {
          const isToday = day.hasSame(DateTime.local(), "day")
          const isWeekend = day.weekday > 5

          const Comp = children ? Slot : "div"

          return (
            <Comp
              key={day.toISO()}
              data-date={day.toISODate()}
              data-is-today={isToday || undefined}
              data-is-weekend={isWeekend || undefined}
            >
              {children
                ? children({
                    day,
                    isToday,
                    isWeekend,
                    className: "h-12 flex shrink-0",
                  })
                : day.toFormat("ccc")}
            </Comp>
          )
        })}
      </header>
    </>
  )
})
WeeklyHeader.displayName = "WeeklyHeader"
