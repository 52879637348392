import handIcon from "~/assets/icons/hand-pointerfinger.png"

export default function RecorderOnboarding() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4">
      <div className="w-[300px] md:w-[480px] min-h-[150px] bg-white rounded-lg flex flex-col items-center justify-center p-4 md:px-6 shadow-box-1 gap-2">
        <h3 className="text-center text-xl font-platypi font-bold">Great!</h3>
        <p className="text-center text-lg text-foreground">
          Microphone access ✅
        </p>
        <p className="text-center text-lg text-foreground">
          Now let&apos;s record a test note!
        </p>
      </div>
      <div className="transform rotate-180">
        <img
          src={handIcon}
          alt="Hand icon"
          className="w-16 h-16"
        />
      </div>
    </div>
  )
}
