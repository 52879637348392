import { DateTime } from "luxon"


// Returns true if the user's locale uses a 24-hour clock, false if it uses a 12-hour clock.
export const is24HourClock = (() => {
  const testDate = new Date(Date.UTC(2020, 0, 1, 13))
  const formatter = new Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    hour12: undefined,
  })
  const parts = formatter.formatToParts(testDate)
  const hasDayPeriod = parts.some((part) => part.type === "dayPeriod")
  return !hasDayPeriod
})()

export function toDatetimeString(date: Date | DateTime) {
  const _date = date instanceof Date ? DateTime.fromJSDate(date) : date
  return _date.toFormat("LLL dd EEEE h:mm")
}
